import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const SNACKBAR_TOP_MARGIN = 8;

const MUI_LABEL_ROOT = {
  color: 'rgba(0, 0, 0, 0.6)',
  '&.Mui-focused:not(.Mui-error)': {
    color: 'rgba(0, 0, 0, 0.6)',
  },
};

// .storybook/preview.js でも使います。
const AppTheme: FC<PropsWithChildren> = ({ children }) => {
  const theme = createTheme({
    palette: {
      // yello
      primary: {
        main: '#FDE793',
        light: '#FFFFC4',
        dark: '#C8B564',
      },
      // black
      secondary: {
        main: '#3C4847',
        light: '#999999',
        dark: '#000000',
      },
    },
    components: {
      // TextField や Select の focus 時の label color 。
      // primary.main が適用されると薄いので上書きします。
      // See also: https://mui.com/material-ui/react-text-field/#customized-inputs
      MuiInputLabel: {
        styleOverrides: {
          root: {
            ...MUI_LABEL_ROOT,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            ...MUI_LABEL_ROOT,
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: 'rgba(0, 0, 0, 0.6)',
            '&.Mui-checked:not(.Mui-error)': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            top: SNACKBAR_TOP_MARGIN,
            '@media (min-width: 600px)': {
              top: SNACKBAR_TOP_MARGIN,
            },
          },
        },
      },
    },
  });

  return (
    <>
      <CssBaseline />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
};

export default AppTheme;

import { useNavigate } from 'react-router-dom';

import env from '../config/env';
import routes from '../config/routes';
import {
  AuthSignInParams,
  useAuthCtx,
  useAuthSetterCtx,
} from '../contexts/AuthCtx';
import AuthUser from '../types/AuthUser';
import { useCallbackAsync } from './utilHooks';

export const useIsAuthenticated = (): boolean => {
  const { isAuthenticated } = useAuthCtx();
  return !!isAuthenticated;
};

// throws error if not authenticated.
export const useAuthUser = (): AuthUser => {
  const user = useAuthCtx().user;
  if (!user) throw new Error('AuthCtx is not ready or is not authenticated.');
  return user;
};

export const useLoginCallback = (redirectPath: string | undefined) => {
  const { signIn } = useAuthSetterCtx();
  const navigate = useNavigate();
  return useCallbackAsync(
    async (params: AuthSignInParams) => {
      await signIn(params);
      navigate(redirectPath ?? routes.redirects.afterSignInTo);
    },
    [redirectPath]
  );
};

export const useLogoutCallback = () => {
  const { signOut } = useAuthSetterCtx();
  const navigate = useNavigate();

  return useCallbackAsync(async () => {
    await signOut();
    navigate(routes.redirects.afterSignOutTo);
  }, []);
};

export const useRefreshAuthUser = () => useAuthSetterCtx().refresh;

/**
 * test/development 検証用の Firebase の idToken を生成します。
 * `devDummyIdToken::${uuid}::${email}`
 *
 * See also: wanpass-backend の src/service/account/firebase.ts
 */
export const getDevDummyFirebaseIdToken = (
  providerEmail: string,
  providerUuid: string = 'devDummyBusiness_' + providerEmail
): string => {
  if (!env.ENABLE_DEBUG_LOGIN) throw new Error();
  const DEV_DUMMY_ID_TOKEN_SUFFIX = 'devDummyIdToken::';
  return DEV_DUMMY_ID_TOKEN_SUFFIX + providerUuid + '::' + providerEmail;
};

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useCallback, useState } from 'react';

import { SectionRow } from '../../components/shared/Boxes';
import { ActionButton } from '../../components/shared/Buttons';
import LoadingOverlay from '../../components/shared/LoadingOverlay';
import { Caption } from '../../components/shared/Typographies';
import routes from '../../config/routes';
import { useLoginCallback } from '../../hooks/authHooks';
import { useEffectOnce } from '../../hooks/utilHooks';
import {
  EmailVerifyCheckResult,
  useFirebaseAuthEmailVerifyCheck,
} from '../../services/firebaseAuth';

const LoginVerifyPage = () => {
  const [result, setResult] = useState<EmailVerifyCheckResult | undefined>(
    undefined
  );
  const onComplete = useCallback((result: EmailVerifyCheckResult) => {
    setResult(result);
  }, []);
  useFirebaseAuthEmailVerifyCheck(onComplete);

  if (result === undefined)
    return <LoadingOverlay label="本人確認を照合中です" />;

  if (result === 'notVerified') return <NotVerifiedView />;

  return <VerifiedView result={result} />;
};

export default LoginVerifyPage;

const NotVerifiedView = () => {
  const onClickConfirm = useCallback(() => {
    window.location.href = routes.login; // reload needed.
  }, []);
  return (
    <Dialog open>
      <DialogTitle>本人確認ができていません</DialogTitle>
      <DialogContent>
        <SectionRow>
          メールでのご本人確認ができませんでした。
          ログインページに戻り、再度ログインして、本人確認メールを受け取ってください。
        </SectionRow>
        <SectionRow last>
          <Caption>
            ※お使いのメールプロバイダーによってはメールの到着に５分程度の遅延が発生する場合がございます。
            <br />
            ※メールは noreply@wanpass.me
            から送信されます。メールが届かない場合、迷惑メールフォルダをご確認いただくか、
            noreply@wanpass.me を受信許可リストにご設定ください。
          </Caption>
        </SectionRow>
      </DialogContent>
      <DialogActions>
        <ActionButton onClick={onClickConfirm}>ログインし直す</ActionButton>
      </DialogActions>
    </Dialog>
  );
};

export const VerifiedView = ({
  result,
}: {
  result: EmailVerifyCheckResult;
}) => {
  if (result === 'notVerified') throw new Error();
  const { firebaseIdToken } = result;
  const doLogin = useLoginCallback(undefined);
  useEffectOnce(() => {
    doLogin({ firebaseIdToken });
  });

  return <>照合できました</>;
};

import { Chip } from '@mui/material';
import { FC } from 'react';

import { TrainingGenre } from '../../types/TrainingGenre';

type Props = {
  genres: TrainingGenre[];
};

const TrainingGenreTags: FC<Props> = ({ genres }) => {
  return (
    <>
      {genres.map((genre, index) => (
        <GenreTag
          key={genre}
          genre={genre}
          last={genres.length - 1 === index}
        />
      ))}
    </>
  );
};
export default TrainingGenreTags;

const ITEM_MARGIN = '3px';
type GenreTagProps = { genre: TrainingGenre; last?: boolean };
const GenreTag: FC<GenreTagProps> = ({ genre, last }) => {
  const marginRight = last ? undefined : ITEM_MARGIN;
  return (
    <Chip key={genre} label={genre} size="small" sx={{ mr: marginRight }} />
  );
};

import {
  DependencyList,
  EffectCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Strictly called once useEffect.
 * To avoid default useEffect which is called twice on mounted.
 */
export const useEffectOnce = (
  callback: (() => void) | (() => Promise<void>)
) => {
  const called = useRef(false);
  useEffect(() => {
    if (called.current) return;
    called.current = true;
    void callback();
  }, []);
};

const useUrlGetParams = () => {
  const [searchParams] = useSearchParams();
  return searchParams;
};

export const useUrlPathFromGetParams = (key: string): string | undefined => {
  const path = useUrlGetParams().get(key);
  return useMemo(() => {
    if (!path) return undefined;
    if (path.indexOf('/') !== 0) return undefined;
    return path;
  }, [path]);
};

export const useCallbackAsync = <T extends unknown[]>(
  callback: (...args: T) => Promise<void>,
  deps: DependencyList
) => {
  return useCallback<(...args: T) => void>((...args) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, n/no-callback-literal
    callback(...args);
  }, deps);
};

export const useEffectAsync = (
  callback: () => Promise<void>,
  deps: DependencyList,
  destructor?: ReturnType<EffectCallback>
) =>
  useEffect(() => {
    void callback();
    return destructor;
  }, deps);

export const useResetScrollEffect = (deps: DependencyList = []) =>
  useEffect(() => {
    // logger.log('resetScroll');
    window.scrollTo({ top: 0 });
  }, deps);

import { Avatar } from '@mui/material';

import { PET_NO_IMAGE } from '../../config/assets';
import Pet from '../../types/Pet';

const PetIcon = ({ pet, size }: { pet: Pet; size: number }) => {
  const imageUrl = pet.image?.url ?? PET_NO_IMAGE;

  return (
    <Avatar
      alt={pet.name}
      src={imageUrl}
      sx={{ width: size, height: size, display: 'inline-block' }}
    />
  );
};

export default PetIcon;

import UserFormField, { UserFormFieldValues } from './UserFormFields';

export type RegisterFormFieldValues = UserFormFieldValues & {};

const RegisterFormFields = () => {
  return (
    <>
      <UserFormField />
    </>
  );
};

export default RegisterFormFields;

import AssetImage from '../../types/AssetImage';
import FormImage from '../shared/forms/FormImage';
import { FormTextRow } from '../shared/forms/FormText';
import validations from '../shared/forms/validations';

export type UserFormFieldValues = {
  fullname: string;
  schoolName: string;
  schoolUrl: string;
  image: AssetImage | File;
};

const UserFormField = () => {
  return (
    <>
      <FormTextRow
        name="fullname"
        label="本名"
        validations={{ ...validations.required }}
        placeholder="例：鈴木たろう"
        fullWidth={true}
      />

      <FormTextRow
        name="schoolName"
        label="所属（任意）"
        placeholder="例：ペッツオーライしつけ教室"
        helperText="所属する組織名を入力してください。"
        fullWidth={true}
      />

      <FormTextRow
        name="schoolUrl"
        label="所属URL（任意）"
        validations={{ ...validations.url }}
        placeholder="例：https://about.petsallright.net"
        fullWidth={true}
        isUrl={true}
      />

      <FormImage
        name="image"
        label="プロフィール画像"
        width={200}
        height={200}
        round
        useCover
        validations={{ ...validations.required }}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      />
    </>
  );
};

export default UserFormField;

import { useState } from 'react';
import { useParams } from 'react-router-dom';

import trainerApi from '../api/trainerApi';
import { CertificateDetail } from '../components/domain/CertificateDetail';
import { useEffectApiLoading } from '../hooks/apiHooks';
import { useSetupLayout } from '../hooks/layoutHooks';
import TrainerHistoryItem from '../types/TrainerHistoryItem';

const HistorySinglePage = () => {
  useSetupLayout({ title: '認定履歴詳細' });
  const { certificateId } = useParams<{ certificateId: string }>();

  const [history, setHistory] = useState<TrainerHistoryItem>();

  useEffectApiLoading(
    async ({ apiCaller }) => {
      if (certificateId) {
        const trainerHistory = await apiCaller.call(
          trainerApi.getTrainerHistory(certificateId)
        );
        setHistory(trainerHistory);
      }
    },
    [certificateId]
  );
  if (!history) return <></>;

  const { info, certificate, pet } = history;
  return (
    <>
      <CertificateDetail {...{ info, certificate, pet }} />
    </>
  );
};

export default HistorySinglePage;

/**
 * Resource Data type.
 * Especially for image or video file or blob.
 */
type DataResource = string | File | Blob | { url: string };
export default DataResource;

export const dataResourceToUrl = (data: DataResource): string => {
  if (typeof data === 'string') return data;
  if (data instanceof File || data instanceof Blob)
    return URL.createObjectURL(data);
  return data.url;
};

export const downloadBlobResource = (
  dataResource: DataResource,
  { basename, ext }: { basename: string; ext: string }
) => {
  const url = dataResourceToUrl(dataResource);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  const filename = basename + '.' + ext;
  a.download = filename;
  a.click();
  return {
    filename,
  };
};

export const getFileExtensionFromUri = (uri: string): string | undefined => {
  const basename = uri.replace(/.+\//g, '').replace(/[\\?#].*/g, '');
  if (basename.includes('.')) return basename.split('.').pop();
  return undefined;
};

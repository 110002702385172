import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';

import DataResource from '../../../types/shared/DataResource';
import { DisabledBody, Label } from '../Typographies';
import VideoPreviewer from '../VideoPreviewer';
import {
  FormField,
  FormFieldExtendProps,
  useFormContainerCtx,
} from './FormContainer';
import FormVideoEditMode from './FormVideoEditMode';

type Props = FormFieldExtendProps &
  BoxProps & {
    maxFileSizeMb?: number | undefined;
  };

const FormVideo: FC<Props> = ({ name, label, validations, ...rest }) => {
  const { getState } = useFormContainerCtx();
  const { value } = getState<DataResource>(name);

  const viewMode = (
    <Box {...rest}>
      <Label>{label}</Label>
      {value ? (
        <VideoPreviewer src={value} />
      ) : (
        <DisabledBody>（なし）</DisabledBody>
      )}
    </Box>
  );

  const inputMode = (
    <FormVideoEditMode
      name={name}
      label={label}
      validations={validations}
      {...rest}
    />
  );

  return <FormField viewMode={viewMode} inputMode={inputMode} />;
};

export default FormVideo;

import { TrainingGenre } from './TrainingGenre';
import { TrainingType } from './TrainingType';

// 認定項目
type TrainingInfo = {
  type: TrainingType;
  title: string;
  digest: string; // content のショートバージョン
  content: string; // 認定内容
  preparation: string; // 用意するもの
  caution: string; // 注意点
  youtubeId: string; // youtube video id
  genres: TrainingGenre[];
};

export default TrainingInfo;

/**
 * Get all genres from TrainingInfo list (unique).
 */
export const collectGenresFromTrainingInfos = (
  trainingInfos: Array<Pick<TrainingInfo, 'genres'>>
): TrainingGenre[] => {
  const allGenres = trainingInfos.map((info) => info.genres).flat();
  const uniqueGenres = Array.from(new Set(allGenres));
  return uniqueGenres;
};

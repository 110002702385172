import AssetApiAccessInfo from './AssetApiAccessInfo';
import Trainer from './Trainer';
import TrainerCapability from './TrainerCapability';

type AuthUser = {
  bearer: string;
  isRegisterNeeded: boolean;
  assetAccessInfo: AssetApiAccessInfo;
  trainer: Trainer; // null when isRegisterNeeded = true
  capability: TrainerCapability;
};

export default AuthUser;

export class AuthUserImpl implements AuthUser {
  constructor(
    public readonly bearer: string,
    public readonly assetAccessInfo: AssetApiAccessInfo,
    public readonly isRegisterNeeded: boolean,
    private readonly _trainer: Trainer | null,
    public readonly capability: TrainerCapability
  ) {}

  get trainer(): Trainer {
    if (this.isRegisterNeeded)
      throw new Error(
        'Trainer is not accessible because isRegisterNeeded is true.'
      );
    if (!this._trainer)
      throw new Error(
        'Trainer is undefined. Maybe constructor usage is incorrect.'
      );
    return this._trainer;
  }
}

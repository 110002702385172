import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

import {
  FormField,
  FormFieldExtendProps,
  useFormContainerCtx,
} from './FormContainer';
import { wrapByFormFieldRow } from './FormFieldRow';
import SimpleViewMode from './SimpleViewMode';

export type FormSelectOption<T = string | number> = {
  value: T;
  label: string;
};
type Props = FormFieldExtendProps &
  TextFieldProps & { options: FormSelectOption[] };

/**
 *
 * @params: name, label are required.
 */
const FormSelect: FC<Props> = ({
  name,
  label,
  options,
  validations,
  helperText,
  ...rest
}) => {
  const { register, getState } = useFormContainerCtx();

  const { value, error } = getState<FormSelectOption['value']>(name);
  const viewMode = <SimpleViewMode label={label}>{value}</SimpleViewMode>;

  // The reason why use TextField instead of Select:
  //  - MUI's Select doesn't show label correctly.
  //  - See also: https://stackoverflow.com/questions/67064682/mui-outlined-select-label-is-not-rendering-properly
  const inputMode = (
    <TextField
      select
      label={label}
      defaultValue={value ?? ''} // This is needed to avoid `Warning: A component is changing an uncontrolled input to be controlled.`
      {...register(name, validations)}
      error={!!error}
      helperText={error ?? helperText}
      {...rest}
    >
      {options.map((item) => {
        return (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        );
      })}
    </TextField>
  );

  return <FormField viewMode={viewMode} inputMode={inputMode} />;
};

export const FormSelectRow: FC<Props> = wrapByFormFieldRow(FormSelect);

export default FormSelect;

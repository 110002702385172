import { FC, PropsWithChildren, useMemo, useState } from 'react';

import env from '../../config/env';
import { useEffectOnce } from '../../hooks/utilHooks';

const STAGING_PASSWORDS: string[] | undefined = env.STAGING_PASSWORDS;

const StagingFirewall: FC<PropsWithChildren> = ({ children }) => {
  const { inquirePassword, isDefaultOk } = useMemo(() => makeService(), []);

  const [ok, setOk] = useState(isDefaultOk);
  useEffectOnce(() => {
    if (!ok) {
      const result = inquirePassword();
      setOk(result);
    }
  });
  if (!ok) return <>ステージングアクセストークンが必要です</>;
  return <>{children}</>;
};

export default StagingFirewall;

const makeService = () => {
  if (!STAGING_PASSWORDS) throw new Error('Invalid call');
  const CACHE_KEY = 'staging_password';
  const isValidPassword = (value: string | undefined): boolean =>
    value ? STAGING_PASSWORDS.includes(value) : false;
  const getCache = () => localStorage.getItem(CACHE_KEY) ?? undefined;
  const setCache = (value: string) => localStorage.setItem(CACHE_KEY, value);
  const inquirePassword = (depth = 1): boolean => {
    if (depth > 3) {
      alert('アクセスできませんでした。');
      return false;
    }
    const value = prompt('ステージングアクセストークンを入力してください', '');
    if (value === null) return false;
    if (value && isValidPassword(value)) {
      setCache(value);
      return true;
    }

    alert('ステージングアクセストークンが正しくありません。');
    return inquirePassword(++depth);
  };
  const isDefaultOk = isValidPassword(getCache());
  return { inquirePassword, isDefaultOk };
};

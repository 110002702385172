import * as sdk from '../libs/AssetServiceFrontendClient.sdk.v1';
import AssetImage from '../types/AssetImage';
import AssetMovie from '../types/AssetMovie';

type ImageScale = sdk.AssetImageScale;

export default class AssetServer {
  private readonly _imageClient: sdk.IAssetImageClient;
  private readonly _movieClient: sdk.IAssetMovieClient;
  constructor(accessInfo: sdk.AssetApiAccessInfo) {
    const { imageClient, movieClient } =
      sdk.createAssetServiceFrontendClients(accessInfo);
    this._imageClient = imageClient;
    this._movieClient = movieClient;
  }

  uploadImage = async (data: File, scale?: ImageScale): Promise<AssetImage> => {
    const { id, url, thumbnailUrl } = await this._imageClient.uploadImage(
      data,
      {
        scale: scale ?? 'medium',
      }
    );
    return { id, url, thumbnailUrl };
  };

  uploadMovie = async (data: Blob | File): Promise<AssetMovie> => {
    const file = _blobToFile(data);
    const { id, url } = await this._movieClient.uploadMovie(file, {});
    return { id, url };
  };
}

const _blobToFile = (data: File | Blob): File => {
  if (data instanceof File) return data;
  const blob = data;
  const file = new File([blob], `blob.${blob.type}`, {
    type: blob.type,
  });
  return file;
};

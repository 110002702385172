import { FC, useCallback, useState } from 'react';

import VideoAuthorityHelp from '../../components/domain/VideoAuthorityHelp';
import VideoRecorderWithSoundPanel from '../../components/domain/VideoRecorderWithSoundPanel';
import { Section } from '../../components/shared/Boxes';
import { ActionButton } from '../../components/shared/Buttons';
import Separator from '../../components/shared/Separator';
import { CautionBody, Title } from '../../components/shared/Typographies';
import VideoPreviewer from '../../components/shared/VideoPreviewer';
import { useSetupLayout } from '../../hooks/layoutHooks';

type Mode = 'init' | 'record';

const RecordCheckPage: FC = () => {
  useSetupLayout({});
  const [mode, setMode] = useState<Mode>('init');
  const [movie, setMovie] = useState<Blob | undefined>(undefined);
  const onStartClick = useCallback(() => setMode('record'), []);
  const onCancel = useCallback(() => {
    setMode('init');
    setMovie(undefined);
  }, []);
  const onCompleted = useCallback((data: Blob) => {
    setMovie(data);
    setMode('init');
  }, []);

  if (mode === 'init')
    return (
      <>
        {movie && (
          <>
            <Section>
              <Title field>撮影結果</Title>
              <VideoPreviewer src={movie} />
              <CautionBody>
                撮影が完了しました。再生することができたら正常です。
              </CautionBody>
            </Section>
            <Separator />
          </>
        )}
        <Section>
          <Title field>動画撮影のチェック</Title>
          このページではブラウザでの動画撮影ができるかどうかの確認をすることができます。認定の前にあらかじめ、撮影可能であることをチェックしておくようお願いします。
        </Section>
        <Section>
          <ActionButton onClick={onStartClick}>
            動画撮影の確認をする
          </ActionButton>
        </Section>
        <Separator />
        <Section>
          <VideoAuthorityHelp />
        </Section>
      </>
    );

  return (
    <VideoRecorderWithSoundPanel
      checkSoundVolume
      {...{ onCancel, onCompleted }}
    />
  );
};

export default RecordCheckPage;

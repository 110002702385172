import { Helmet, HelmetProps } from 'react-helmet';

const DEFAULT_TIELE = 'Wan!Pass認定 for トレーナー';
const DEFAULT_METAS: HelmetProps['meta'] = [
  {
    name: 'description',
    content: 'Wan!Pass認定 for トレーナー',
  },
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1',
  },
];
const DEFAULT_LINKS: HelmetProps['link'] = [
  { rel: 'icon', type: 'image/png', href: '/favicon.ico' },
];

// ISSUE:
//  Warning is occuring by Helmet
//  `Using UNSAFE_componentWillMount in strict mode is not recommended and may indicate bugs in your code.`

const Head = () => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'ja',
      }}
      title={DEFAULT_TIELE}
      meta={DEFAULT_METAS}
      link={DEFAULT_LINKS}
    ></Helmet>
  );
};

export default Head;

import AssetMovie from './AssetMovie';
import { TrainingType } from './TrainingType';

export type ResultType = 'pass' | 'fail';

type Certificate = {
  id: string;
  type: TrainingType;

  resultType: ResultType;
  certifiedAt: Date;
  comment: string; // Comment from trainer to owner about result.
  movie: AssetMovie;
};

export default Certificate;

export const RESULT_TYPE_LABEL: Record<ResultType, '合格' | '不合格'> = {
  pass: '合格',
  fail: '不合格',
};

export const RESULT_TYPE_ICON: Record<ResultType, '👑' | '❌'> = {
  pass: '👑',
  fail: '❌',
};

import { Box, BoxProps } from '@mui/material';
import {
  useRef,
  FC,
  useState,
  ChangeEvent,
  useCallback,
  CSSProperties,
  SyntheticEvent,
} from 'react';

import { MOVIE_ATTACH_FILE_MAX_MB } from '../../../config/constants';
import DataResource from '../../../types/shared/DataResource';
import { Row, Section } from '../Boxes';
import VideoPreviewer from '../VideoPreviewer';
import { FormFieldExtendProps, useFormContainerCtx } from './FormContainer';

type Props = FormFieldExtendProps &
  BoxProps & {
    maxFileSizeMb?: number | undefined;
  };

const BOX_BASE_STYLE = {
  padding: '10px',
};

const FormVideoEditMode: FC<Props> = ({
  name,
  label,
  maxFileSizeMb = MOVIE_ATTACH_FILE_MAX_MB,
  validations,
  ...rest
}) => {
  const { getState, setValues, register } = useFormContainerCtx();
  const { value, error } = getState<DataResource>(name);
  const [fileSizeError, setFileSizeError] = useState<string | undefined>(
    undefined
  );

  const inputFileRef = useRef<HTMLInputElement>(null);
  const [boxStyle, setBoxStyle] = useState<CSSProperties | undefined>(
    undefined
  );

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;

      const item = e.target.files.item(0);
      if (!item || item.type.split('/')[0] !== 'video') return;

      const maxFileSize = maxFileSizeMb * 1024 * 1024;
      if (item.size < maxFileSize) {
        setValues({ [name]: item });
        setBoxStyle(BOX_BASE_STYLE);
      } else {
        setFileSizeError(getFileSizeErrorMsg(maxFileSizeMb, item.size));
      }

      if (e.target.value) {
        e.target.value = '';
      }
    },
    [name, maxFileSizeMb]
  );

  const openFileSelector = useCallback(() => {
    inputFileRef?.current?.click();
  }, []);

  const onRemoveClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setValues({ [name]: null });
      setBoxStyle(undefined);
      setFileSizeError(undefined);
    },
    [name]
  );

  return (
    <Box>
      {boxStyle && value ? (
        <Section border={1} {...rest}>
          <Box
            component="span"
            className="material-icons"
            sx={{ cursor: 'pointer' }}
            onClick={onRemoveClick}
          >
            clear
          </Box>
          <Box style={boxStyle}>
            <VideoPreviewer src={value} />
          </Box>
        </Section>
      ) : (
        <Section
          border={1}
          style={boxStyle}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ cursor: 'pointer' }}
          onClick={openFileSelector}
          {...rest}
        >
          <Box>
            <Row>ここをタップして{label ?? '動画'}を選択してください</Row>
            {(!!error || fileSizeError) && (
              <Row sx={{ color: 'error.main' }}>
                {error}
                {fileSizeError}
              </Row>
            )}
          </Box>
        </Section>
      )}
      <input
        type="file"
        hidden
        accept="video/*"
        {...register(name, validations)}
        ref={inputFileRef}
        onChange={onFileChange}
      />
    </Box>
  );
};

export default FormVideoEditMode;

const getFileSizeErrorMsg = (maxMb: number, actualSize: number) =>
  `ファイルサイズの上限を越えています。添付できる動画の最大ファイルサイズは${maxMb}MBです。添付されたファイルのサイズは${
    Math.ceil((actualSize / (1024 * 1024)) * 10) / 10
  }MBです。`;

import { Box, Card, CardContent } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { FlexCenter } from './Boxes';

type Props = PropsWithChildren & {
  minWidth?: number;
};

const StickyNote: FC<Props> = ({ children, minWidth = 275, ...props }) => {
  return (
    <FlexCenter>
      <Box>
        <Card
          sx={{
            minWidth,
          }}
          {...props}
        >
          <CardContent>{children}</CardContent>
        </Card>
      </Box>
    </FlexCenter>
  );
};

export default StickyNote;

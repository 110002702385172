const BEARER_KEY = 'wanpass-trainer-bearer';

class LocalStore {
  private _bearer: string | null;

  constructor() {
    this._bearer = localStorage.getItem(BEARER_KEY) ?? null;
  }

  public get bearer(): string | null {
    return this._bearer;
  }

  onSignIn(bearer: string) {
    this.onRefreshBearer(bearer);
  }

  onRefreshBearer(bearer: string) {
    localStorage.setItem(BEARER_KEY, bearer);
    this._bearer = bearer;
  }

  onSignOut() {
    localStorage.removeItem(BEARER_KEY);
    // localStorage.clear しちゃうと、 ステージングアクセストークンも消えちゃうので注意 See: StagingFirewall
    this._bearer = null;
  }
}

export default LocalStore;

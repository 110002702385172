import { FC, PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import CertifyProgressBar from '../../components/domain/CertifyProgressBar';
import { Row, Section, SectionRow } from '../../components/shared/Boxes';
import { Title } from '../../components/shared/Typographies';
import { useCertifyCtx } from '../../contexts/CertifyCtx';

type Props = PropsWithChildren & {
  title?: string;
  desc?: string;
};

const TITLE_ID = 'CertifyLayout__title';
const DESCRIPTION_ID = 'CertifyLayout__description';

const CertifyLayout: FC<Props> = ({ title, desc, children }) => {
  const {
    values: { pet, info },
  } = useCertifyCtx();

  return (
    <>
      <SectionRow>
        {pet && <CertifyProgressBar {...{ pet, info }} />}
      </SectionRow>
      <Section>
        <div id={TITLE_ID} />
        <div id={DESCRIPTION_ID} />
      </Section>
      {children}
      <br />
    </>
  );
};

export default CertifyLayout;

export const CertifyTitle: FC<PropsWithChildren> = ({ children }) => {
  return createPortal(
    <Title>{children}</Title>,
    document.getElementById(TITLE_ID) ?? document.createElement('div')
  );
};

export const CertifyDescription: FC<PropsWithChildren> = ({ children }) => {
  return createPortal(
    <Row>{children}</Row>,
    document.getElementById(DESCRIPTION_ID) ?? document.createElement('div')
  );
};

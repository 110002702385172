import { Box, Icon, IconButton, Paper } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useState } from 'react';

import { Flex } from '../shared/Boxes';
import { CautionBody } from '../shared/Typographies';

type Props = PropsWithChildren & {
  enabled: boolean;
};
const DebugView: FC<Props> = ({ enabled, children }) => {
  if (!enabled) return null;
  return (
    <Box sx={{ mt: 4 }}>
      <DebugViewContent>{children}</DebugViewContent>
    </Box>
  );
};

const DebugViewContent: FC<PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState(true);
  const onCloseClick = useCallback(() => setVisible(false), []);

  if (!visible) return null;

  return (
    <Paper sx={{ p: 1 }} elevation={2}>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <CautionBody>[DEBUG VIEW]</CautionBody>
        <IconButton onClick={onCloseClick} size="small">
          <Icon>close</Icon>
          Hide
        </IconButton>
      </Flex>

      {children}
    </Paper>
  );
};

export default DebugView;

import { Box } from '@mui/material';
import { FC } from 'react';

import OpenTrigger from './OpenTrriger';

type Props = {
  data: string | any;
};
const DebugMessage: FC<Props> = ({ data }) => {
  const value = typeof data === 'string' ? data : JSON.stringify(data, null, 2);
  return (
    <Box sx={{ width: 1 }}>
      <textarea
        rows={4}
        style={{ width: '100%', boxSizing: 'border-box', fontSize: 11 }}
        defaultValue={value}
      />
    </Box>
  );
};

export default DebugMessage;

type TriggerProps = Props & {
  label?: string;
};
export const DebugMessageTrigger: FC<TriggerProps> = ({
  label = 'デバッグメッセージ',
  ...props
}) => (
  <OpenTrigger label={label}>
    <DebugMessage {...props} />
  </OpenTrigger>
);

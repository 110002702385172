import { FormFieldValidations } from './FormContainer';

const required: FormFieldValidations = {
  required: {
    value: true,
    message: '入力必須です。',
  },
};

const url: FormFieldValidations = {
  pattern: {
    value: /^https?:\/\/.+/,
    message: '正しいURLを入力してください。',
  },
};

const length = (
  props:
    | {
        min?: number;
        max?: number;
        eq?: number;
      }
    | number
): FormFieldValidations => {
  const {
    min: min_ = undefined,
    max: max_ = undefined,
    eq = undefined,
  } = typeof props === 'number' ? { eq: props } : props;
  const min = eq ?? min_ ?? undefined;
  const max = eq ?? max_ ?? undefined;

  if (min === undefined && max === undefined) return {};
  const charFormat = ((): string => {
    if (min === max) return `${min ?? ''}文字`; // 同一の数値の場合
    let res = '';
    if (min !== undefined) res += `${min}文字以上`;
    if (max !== undefined) res += `${max}文字以内`;
    return res;
  })();
  const message = charFormat + 'で入力してください。';
  const minLengthProps: FormFieldValidations = min
    ? {
        minLength: {
          value: min,
          message,
        },
      }
    : {};
  const maxLengthProps: FormFieldValidations = max
    ? {
        maxLength: {
          value: max,
          message,
        },
      }
    : {};
  return {
    ...minLengthProps,
    ...maxLengthProps,
  };
};

const validations = {
  required,
  url,
  length,
} as const;

export default validations;

import { Box, BoxProps } from '@mui/material';
import { FC } from 'react';
import styled from 'styled-components';

import { Row } from '../Boxes';
import Colors from '../Colors';
import { Caption } from '../Typographies';
import {
  FormField,
  FormFieldExtendProps,
  useFormContainerCtx,
} from './FormContainer';
import FormImageEditMode from './FormImageEditMode';

type Props = FormFieldExtendProps &
  BoxProps & {
    width?: number | string;
    height?: number | string;
    round?: boolean;
    useCover?: boolean;
  };

const FormImage: FC<Props> = ({
  name,
  label,
  validations,
  width = '100%',
  height = 'auto',
  round = false,
  useCover = false,
  ...rest
}) => {
  const { getState } = useFormContainerCtx();
  const { value } = getState(name);
  const viewMode = (
    <>
      <Row>
        <Caption>{label}</Caption>
      </Row>
      <Box {...rest}>
        {/* TODO: convert (value: File | string) to string */}
        <Box {...rest}>
          <SetImageShape
            src={value?.url}
            width={width}
            height={height}
            round={round}
            useCover={useCover}
          ></SetImageShape>
        </Box>
      </Box>
    </>
  );

  const inputMode = (
    <FormImageEditMode
      name={name}
      label={label}
      validations={validations}
      width={width}
      height={height}
      round={round}
      useCover={useCover}
      {...rest}
    />
  );
  return <FormField viewMode={viewMode} inputMode={inputMode} />;
};

const SetImageShape = styled.img<{
  width: number | string;
  height: number | string;
  useCover: boolean;
  round: boolean;
}>`
  width: ${(p) => cssSize(p.width)};
  height: ${(p) => cssSize(p.height)};
  border-radius: ${(p) => (p.round ? '50%' : '0%')};
  object-fit: ${(p) => (p.useCover ? 'cover' : 'contain')};
  background-color: ${Colors.bgSubtle};
`;
export default FormImage;

const cssSize = (n: number | string) => (typeof n === 'number' ? `${n}px` : n);

import { useCallback, useState } from 'react';

import petApi from '../../api/petApi';
import DebugView from '../../components/domain/DebugView';
import { PetCard } from '../../components/domain/PetCard';
import BackButton from '../../components/shared/BackButton';
import { FlexCenter, Section, SectionRow } from '../../components/shared/Boxes';
import { ActionButton } from '../../components/shared/Buttons';
import ImageBox from '../../components/shared/ImageBox';
import Manuscript from '../../components/shared/Manuscript';
import { Label } from '../../components/shared/Typographies';
import FormContainer, {
  FormContainerCtxType,
  FormFieldValidations,
  useFormContainer,
} from '../../components/shared/forms/FormContainer';
import FormText from '../../components/shared/forms/FormText';
import validations from '../../components/shared/forms/validations';
import { ONE_TIME_CODE_SAMPLE_IMAGE } from '../../config/assets';
import env from '../../config/env';
import { useCertifyCtx } from '../../contexts/CertifyCtx';
import { useCallbackApiLoading } from '../../hooks/apiHooks';
import { useResetScrollEffect } from '../../hooks/utilHooks';
import Owner from '../../types/Owner';
import Pet, { PET_FRIENDLY_ID_VALIDATIONS } from '../../types/Pet';
import { CertifyDescription, CertifyTitle } from './CertifyLayout';

const PetStep = () => {
  const { form, pet, owner, onFindPetClick, onNextClick, clear } = useService();
  useResetScrollEffect([pet]);

  return (
    <>
      <CertifyTitle>認定試験を開始</CertifyTitle>
      {pet && owner ? (
        <ConfirmView
          pet={pet}
          owner={owner}
          clear={clear}
          onNextClick={onNextClick}
        />
      ) : (
        <CodeInputView form={form} onFindPetClick={onFindPetClick} />
      )}
    </>
  );
};
export default PetStep;

const CodeInputView = ({
  form,
  onFindPetClick,
}: {
  form: FormContainerCtxType<FormValues>;
  onFindPetClick: () => void;
}) => {
  return (
    <FormContainer value={form}>
      <CertifyDescription>
        認定するワンちゃんを設定します。ワンちゃんの<b>「認定コード」</b>
        （6桁の数字）を入力してください。
      </CertifyDescription>
      <Section>
        <SectionRow>
          <FormText
            label="認定コード"
            name="oneTimeCode"
            validations={oneTimeCodeValidations}
          />
        </SectionRow>
        <SectionRow>
          <ActionButton onClick={onFindPetClick}>次へ進む</ActionButton>
        </SectionRow>
      </Section>

      <Section>
        <Manuscript title="認定コードとは">
          飼い主様の Wan!Pass アプリから発行する6桁の数字です。
          「認定試験」→「現地試験」を開き、対象のワンちゃんの画像の下にある
          「認定コードを発行する」をタップすると表示されます。
          <ImageBox
            src={ONE_TIME_CODE_SAMPLE_IMAGE}
            sx={{ maxWidth: 200 }}
            center
            canOpenDialog
            dialogTitle="認定コードの発行場所"
          />
        </Manuscript>
      </Section>

      <DebugView enabled={env.NODE_ENV === 'development'}>
        You can input `111111` only in development
      </DebugView>
    </FormContainer>
  );
};

const ConfirmView = ({
  pet,
  owner,
  clear,
  onNextClick,
}: {
  pet: Pet;
  owner: Owner;
  clear: () => void;
  onNextClick: () => void;
}) => {
  if (!pet || !owner) throw new Error('Invalid state');

  return (
    <>
      <Section>
        <CertifyDescription>
          以下のワンちゃんで間違いないかご確認ください。
        </CertifyDescription>
        <SectionRow>
          <Label>ワンちゃんの情報</Label>
        </SectionRow>
        <SectionRow>
          <PetCard pet={pet} owner={owner} />
        </SectionRow>
      </Section>
      <FlexCenter>
        <Section>
          <SectionRow>
            <ActionButton onClick={onNextClick}>
              上記のワンちゃんを認定する
            </ActionButton>
          </SectionRow>
          <SectionRow>
            <FlexCenter>
              <BackButton onClick={clear} />
            </FlexCenter>
          </SectionRow>
        </Section>
      </FlexCenter>
    </>
  );
};

const oneTimeCodeValidations: FormFieldValidations = {
  ...validations.required,
  ...PET_FRIENDLY_ID_VALIDATIONS,
};

type FormValues = { oneTimeCode: string };

const useService = () => {
  const [pet, setPet] = useState<Pet | undefined>();
  const [owner, setOwner] = useState<Owner | undefined>();
  const [oneTimeCode, setOneTimeCode] = useState<string | undefined>();
  const form = useFormContainer<FormValues>({ defaultMode: 'input' });
  const { handleSubmit } = form;

  const loadAndSetPet = useCallbackApiLoading<FormValues>(
    async (data, { apiCaller }) => {
      const { pet, owner } = await apiCaller.call(
        petApi.getPet({ oneTimeCode: data.oneTimeCode })
      );
      setPet(pet);
      setOwner(owner);
      setOneTimeCode(data.oneTimeCode);
    },
    [],
    { successMsg: null }
  );
  const onFindPetClick = handleSubmit(
    async (data) => await loadAndSetPet(data)
  );

  const certify = useCertifyCtx('pet');
  const onNextClick = useCallback(() => {
    certify.updateValues({
      pet,
      oneTimeCode,
    });
  }, [pet, certify.updateValues]);

  const clear = () => {
    setPet(undefined);
    setOwner(undefined);
  };

  return {
    form,
    pet,
    owner,
    clear,
    onFindPetClick,
    onNextClick,
  };
};

import { FC, PropsWithChildren, useCallback } from 'react';

import DebugView from '../../components/domain/DebugView';
import BackButton from '../../components/shared/BackButton';
import { FlexColumn, Row, SectionRow } from '../../components/shared/Boxes';
import { useBranches } from '../../components/shared/Branches';
import { ActionButton, TextLink } from '../../components/shared/Buttons';
import Separator from '../../components/shared/Separator';
import { RECORD_APP_URL } from '../../config/constants';
import env from '../../config/env';
import {
  OnMovieCompleted,
  OnMovieCompletedProps,
  useCertifyCtx,
} from '../../contexts/CertifyCtx';
import { useResetScrollEffect } from '../../hooks/utilHooks';
import { __getDebugMockMovie } from '../../services/debugMocks';
import { CertifyDescription, CertifyTitle } from './CertifyLayout';
import InfoHint from './InfoHint';
import MovieStepAttach from './movieStep/MovieStepAttach';
import MovieStepRecord from './movieStep/MovieStepRecord';

const MovieStep = () => {
  const { onCompleted } = useService();
  const { Branch, BranchDefault, toggleBranch, resetBranch, branch } =
    useBranches<'record' | 'attach'>(['record', 'attach']);
  useResetScrollEffect([branch]);

  const onClickAppRecord = useCallback(() => {
    window.open(RECORD_APP_URL, 'trainer-record-app');
    toggleBranch.attach();
  }, [toggleBranch.attach]);

  return (
    <>
      <CertifyTitle>動画の撮影</CertifyTitle>
      <CertifyDescription>
        認定内容の動画を撮影してください。合否に関わらず動画撮影は必須です。
      </CertifyDescription>
      <BranchDefault>
        <FlexColumn>
          <SectionRow>
            {env.ENABLE_APP_RECORD && (
              <ActionButton fullWidth onClick={onClickAppRecord} sx={{ mb: 2 }}>
                <span>Wan!Pass アプリで撮影を開始する</span>
              </ActionButton>
            )}
            <ActionButton fullWidth onClick={toggleBranch.record}>
              ブラウザで撮影を開始する
            </ActionButton>
          </SectionRow>
          <SectionRow>
            <Row>
              ※
              撮影にはスマートフォンとブラウザでマイク・カメラの権限を許可する必要があります。
            </Row>
            <Row>
              ※
              どうしてもブラウザ撮影ができない場合はスマートフォンのカメラアプリで撮影後、ファイルを添付してください。
              <TextLink onClick={toggleBranch.attach}>
                ファイル添付はこちら
              </TextLink>
            </Row>
          </SectionRow>
          <DebugView enabled={env.ENABLE_DEBUG_MOVIE_SKIP}>
            <DebugSkipButton {...{ onCompleted }} />
          </DebugView>
        </FlexColumn>
      </BranchDefault>
      <Branch name="record">
        <ModeWrapper {...{ resetBranch }}>
          <MovieStepRecord {...{ onCompleted, onCancel: resetBranch }} />
        </ModeWrapper>
      </Branch>
      <Branch name="attach">
        <ModeWrapper {...{ resetBranch }}>
          <MovieStepAttach {...{ onCompleted }} />
        </ModeWrapper>
      </Branch>
      <Separator />
      <InfoHint />
    </>
  );
};
export default MovieStep;

const useService = () => {
  const { updateValues } = useCertifyCtx('movie');

  const onCompleted: OnMovieCompleted = useCallback(
    (movie) => {
      updateValues({ movie });
    },
    [updateValues]
  );

  return {
    onCompleted,
  };
};

const DebugSkipButton = ({ onCompleted }: OnMovieCompletedProps) => {
  const onClick = useCallback(() => {
    onCompleted(__getDebugMockMovie());
  }, [onCompleted]);
  return <ActionButton onClick={onClick}>Debug Next</ActionButton>;
};

const ModeWrapper: FC<PropsWithChildren & { resetBranch: () => void }> = ({
  children,
  resetBranch,
}) => {
  return (
    <>
      <Row>
        <BackButton onClick={resetBranch} />
      </Row>
      <Row>{children}</Row>
    </>
  );
};

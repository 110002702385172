import AssetImage from '../types/AssetImage';
import Pagination from '../types/Pagination';
import TrainerHistoryItem from '../types/TrainerHistoryItem';
import { AuthApiDefinition } from './ApiCaller';
import AssetServer from './AssetServer';

type UpdateTrainerRequest = {
  fullname: string;
  schoolName?: string;
  schoolUrl?: string;
  image?: AssetImage | File;
};

type HistoriesWithPage = {
  trainerHistories: TrainerHistoryItem[];
  paginate: Pagination;
};

type RegisterTrainerRequest = UpdateTrainerRequest;

const registerTrainer: AuthApiDefinition<RegisterTrainerRequest, void> =
  (data) =>
  async ({ backend, asset }) => {
    const { image, ...trainer } = data;
    const imageId = await uploadImage(asset, image);
    await backend.post('/trainers/register', { ...trainer, imageId });
  };

const updateTrainer: AuthApiDefinition<UpdateTrainerRequest, void> =
  (data) =>
  async ({ backend, asset }) => {
    const { image, ...trainer } = data;
    const imageId = await uploadImage(asset, image);
    await backend.post('/trainers/update', { ...trainer, imageId });
  };

const uploadImage = async (
  asset: AssetServer,
  file: UpdateTrainerRequest['image']
): Promise<string> => {
  if (file instanceof File) {
    const { id } = await asset.uploadImage(file);
    return id;
  }

  if (file?.id) {
    return file.id;
  }

  return '';
};

const getTrainerHistories: AuthApiDefinition<
  { page: number },
  HistoriesWithPage
> =
  ({ page }) =>
  async ({ backend }) => {
    const { data } = await backend.get<HistoriesWithPage>(
      `/trainers/certificates?page=${page}`
    );
    return data;
  };

const getTrainerHistory: AuthApiDefinition<string, TrainerHistoryItem> =
  (certificateId) =>
  async ({ backend }) => {
    const { data } = await backend.get<TrainerHistoryItem>(
      `/trainers/certificates/${certificateId}`
    );
    return data;
  };

const trainerApi = {
  registerTrainer,
  updateTrainer,
  getTrainerHistories,
  getTrainerHistory,
};
export default trainerApi;

import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { createApiCaller } from '../api/ApiCaller';
import sessionApi from '../api/sessionApi';
import LoadingOverlay from '../components/shared/LoadingOverlay';
import logger from '../config/logger';
import { firebaseAuthSignOut } from '../services/firebaseAuth';
import AuthUser, { AuthUserImpl } from '../types/AuthUser';
import { useAppSetterCtx } from './AppCtx';
import LocalStore from './LocalStore';

type StateCtxType = {
  isReady: boolean;
  isAuthenticated?: boolean;
  user?: AuthUser;
};

type SetterCtxType = {
  setup: () => Promise<void>;
  signIn: (params: AuthSignInParams) => Promise<void>;
  signOut: () => Promise<void>;
  refresh: () => Promise<void>;
};

export type AuthSignInParams = {
  firebaseIdToken: string;
};

const _StateCtx = createContext<StateCtxType>({
  isReady: false,
});

const _SetterCtx = createContext<SetterCtxType>({
  setup: async () => {},
  signIn: async () => {},
  signOut: async (params?: { message?: string }) => {},
  refresh: async () => {},
});

export const AuthCtxProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isReady, setReady] = useState(false);
  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const ctxValue = useMemo(
    () => createState({ isReady, user }),
    [isReady, user]
  );

  const { toastError, toastSuccess } = useAppSetterCtx();
  const ctxSetterValue = useMemo<SetterCtxType>(
    () =>
      createSetterState({
        setUser,
        setReady,
        toastError,
        toastSuccess,
      }),
    [toastError, toastSuccess]
  );

  return (
    <>
      <_StateCtx.Provider value={ctxValue}>
        <_SetterCtx.Provider value={ctxSetterValue}>
          {children}
        </_SetterCtx.Provider>
      </_StateCtx.Provider>
    </>
  );
};

export const AuthCtxSetupView = () => {
  useAuthSetup();
  return <></>;
};

export const AuthCtxReadyWaitView: FC<PropsWithChildren> = ({ children }) => {
  const { isReady } = useAuthCtx();
  if (!isReady) return <LoadingOverlay />;
  return <>{children}</>;
};

const useAuthSetup = () => {
  const { setup } = useAuthSetterCtx();

  // 1回だけ実行
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setup();
  }, []);
};

export const useAuthCtx = () => useContext(_StateCtx);
export const useAuthSetterCtx = () => useContext(_SetterCtx);

const createState = ({
  isReady,
  user,
}: {
  isReady: boolean;
  user?: AuthUser;
}): StateCtxType => ({
  isReady,
  ...((isReady && {
    isAuthenticated: user !== undefined,
    user,
  }) ||
    {}),
});

const createSetterState = ({
  setReady,
  setUser,
  toastSuccess,
  toastError,
}: {
  setReady: (isReady: boolean) => void;
  setUser: (user: AuthUser | undefined) => void;
  toastError: (message: string) => void;
  toastSuccess: (message: string) => void;
}): SetterCtxType => {
  const startSession = async (
    process: () => Promise<void>,
    {
      errorMessage,
      onError,
    }: { errorMessage?: string; onError?: () => void } = {}
  ) => {
    setReady(false);
    setUser(undefined);
    try {
      await process();
      setReady(true);
    } catch (e) {
      logger.error(e);
      await signOut({ errorMessage });
      onError?.();
      setReady(true);
    }
  };

  const setup = async () =>
    await startSession(
      async () => {
        const bearer = new LocalStore().bearer;
        if (!bearer) {
          setUser(undefined);
          return;
        }
        const apiCaller = createApiCaller({
          bearer,
          assetAccessInfo: null,
        });
        const me = await apiCaller.call(sessionApi.getMe());
        const newBearer = me.bearer;
        const user = new AuthUserImpl(
          newBearer,
          me.assetAccessInfo,
          me.isRegisterNeeded,
          me.trainer ?? null,
          me.capability
        );

        new LocalStore().onRefreshBearer(newBearer);
        setUser(user);
      },
      { errorMessage: 'ログインセッションが古いためログアウトしました。' }
    );

  const refresh = setup;

  const signIn: SetterCtxType['signIn'] = async (params: AuthSignInParams) =>
    await startSession(
      async () => {
        const apiCaller = createApiCaller({
          bearer: null,
          assetAccessInfo: null,
        });
        const { bearer } = await apiCaller.noAuthCall(
          sessionApi.signIn(params)
        );
        const authApiCaller = createApiCaller({
          bearer,
          assetAccessInfo: null,
        });
        const me = await authApiCaller.call(sessionApi.getMe());
        const user = new AuthUserImpl(
          bearer,
          me.assetAccessInfo,
          me.isRegisterNeeded,
          me.trainer ?? null,
          me.capability
        );

        new LocalStore().onSignIn(bearer);
        setUser(user);
        toastSuccess('ログインしました。');
      },
      {
        errorMessage:
          'ログインに失敗しました。ご登録のないメールアドレスの可能性がございます。メールアドレスが正しいものかご確認ください。',
      }
    );

  const signOut = async ({ errorMessage }: { errorMessage?: string } = {}) => {
    new LocalStore().onSignOut();
    setUser(undefined);
    await firebaseAuthSignOut();
    if (errorMessage) toastError(errorMessage);
    else toastSuccess('ログアウトしました。');
  };

  return {
    setup,
    refresh,
    signIn,
    signOut,
  };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __AuthCtxProviderMock: FC<
  PropsWithChildren<{ ctxValue: StateCtxType }>
> = ({ ctxValue, children }) => {
  return <_StateCtx.Provider value={ctxValue}>{children}</_StateCtx.Provider>;
};

import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

type Props = TypographyProps & {
  inline?: boolean;
  bold?: boolean;
  center?: boolean;
  field?: boolean;
};

const Base: FC<Props> = ({
  inline,
  bold,
  center,
  field,
  sx: sxOrigin = {},
  ...props
}) => {
  const display = inline ? 'inline' : undefined;
  const fontWeight = bold ? 'bold' : undefined;
  const align = center ? 'center' : undefined;
  const sx = { mb: field ? 1 : undefined, ...sxOrigin };
  const component = 'div'; // To avoid <xx> cannot appear as a descendant of <p>.
  return (
    <Typography {...{ display, fontWeight, align, sx, component }} {...props} />
  );
};

export const Title: FC<Props> = (props) => (
  <Base variant="h5" bold {...props} />
);

export const SubTitle: FC<Props> = (props) => (
  <Base variant="h6" bold {...props} />
);

export const LabelTitle: FC<Props> = (props) => (
  <Base variant="subtitle1" bold {...props} />
);

export const Label: FC<Props> = (props) => <LabelTitle inline {...props} />;

export const Caption: FC<Props> = (props) => (
  <Base variant="caption" {...props} />
);

export const Body: FC<Props> = (props) => <Base variant="body1" {...props} />;

export const BodySub: FC<Props> = (props) => (
  <Base variant="body2" {...props} />
);

export const Pre: FC<Props> = (props) => (
  <Base variant="body2" sx={{ whiteSpace: 'pre-wrap' }} {...props} />
);

export const ErrorBody: FC<Props> = (props) => (
  <Base variant="body1" color="error" {...props} />
);

export const CautionBody: FC<Props> = (props) => (
  <Base variant="body1" color="error" {...props} />
);

export const DisabledBody: FC<Props> = (props) => (
  <Base variant="body2" color="text.disabled" {...props} />
);

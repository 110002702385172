import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { Caption } from './Typographies';

type Props = BoxProps & {
  flexCenter?: boolean;
  column?: boolean;
  center?: boolean;
  alignRight?: boolean;
  row?: boolean;
  fullWidth?: boolean;
  caption?: string;
};
const Base: FC<Props> = ({
  center,
  alignRight,
  flexCenter,
  column,
  row,
  fullWidth,
  sx: sxOrigin = {},
  caption,
  children,
  ...props
}) => {
  const textAlign = center ? 'center' : alignRight ? 'right' : undefined;
  const flexProps: BoxProps = {
    ...(flexCenter ? FLEX_CENTER_PROPS : {}),
    ...(column ? FLEX_COLUMN_PROPS : {}),
    ...(row ? FLEX_PROPS : {}),
  };
  const sx: SxProps<Theme> = {
    width: fullWidth ? 1 : undefined,
    margin: center ? '0 auto' : undefined,
    ...sxOrigin,
  };
  const boxProps: BoxProps = { ...{ textAlign, sx }, ...flexProps, ...props };
  return (
    <Box {...boxProps}>
      <CaptionWrap {...{ caption, children }} />
    </Box>
  );
};

export type BoxesBaseProps = Props;
export const BoxesBase = Base;

const CaptionWrap: FC<PropsWithChildren & { caption?: string }> = ({
  caption,
  children,
}) => {
  if (!caption) return <>{children}</>;
  return (
    <>
      {
        <div>
          <Caption>{caption}</Caption>
        </div>
      }
      <div>{children}</div>
    </>
  );
};

const FLEX_CENTER_PROPS: BoxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const FLEX_COLUMN_PROPS: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
};

const FLEX_PROPS: BoxProps = {
  display: 'flex',
};

type LastProps = { last?: boolean };

export const Section: FC<Props & LastProps> = ({ last, ...props }) => (
  <Base marginBottom={last ? undefined : 2} {...props} />
);

export const SectionRow: FC<Props & LastProps> = ({ last, ...props }) => (
  <Base marginBottom={last ? undefined : 1} {...props} />
);

// export const Content: FC<Props> = (props) => <Base paddingY={1} {...props} />;

// export const Area: FC<Props> = (props) => <Base padding={1} {...props} />;

export const Row: FC<Props> = (props) => <Base {...props} />;

export const FlexCenter: FC<Props> = (props) => <Base flexCenter {...props} />;

export const FlexColumn: FC<Props> = (props) => <Base column {...props} />;

export const Flex: FC<Props> = (props) => <Base row {...props} />;

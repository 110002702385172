import logger from '../config/logger';

export type CheckError = {
  message: string;
  object: Error;
};
export type CheckResult =
  | {
      status: 'ok';
    }
  | {
      status: 'ng';
      errors: CheckError[];
    };

export type IRecorderApi = {
  check: () => Promise<CheckResult>;
  createRecorder: () => Promise<IRecorder>;
};

const DEFAULT_VIDEO_CONSTRAINTS = {
  // width: 640,
  // height: 480,
  frameRate: { ideal: 20, max: 24 },
};
const getFacingMode = (
  useRearCamera: boolean
): MediaTrackConstraints['facingMode'] =>
  useRearCamera ? { exact: 'environment' } : undefined;

// facingModeには最終的に以下のいずれかの値を入れる
//   facingMode: "user"                    // フロントカメラを利用する
//   facingMode: { exact: "environment" }  // リアカメラを利用する

// eslint-disable-next-line @typescript-eslint/naming-convention
let __singleRecorder: IRecorder | undefined;
export const cleanUpSingleRecorder = () => {
  __singleRecorder?.clean();
  __singleRecorder = undefined;
};

export default class RecorderApi implements IRecorderApi {
  // 1. 撮影録音できるか確認する
  check = async (): Promise<CheckResult> => {
    const errors: CheckError[] = [];
    try {
      const stream = await this._getUsableMediaStream();
      stream.getTracks().forEach((track) => track.stop());
    } catch (e) {
      errors.push({
        message: '撮影・録音の権限がオンになっていないようです。',
        object: e as Error,
      });
    }
    if (errors.length > 0) return { status: 'ng', errors };
    return { status: 'ok' };
  };

  // 2. Recorder を生成
  createRecorder = async (): Promise<IRecorder> => {
    cleanUpSingleRecorder();
    const stream = await this._getUsableMediaStream();
    const mediaReorder = new MediaRecorder(stream);
    return (__singleRecorder = new Recorder(mediaReorder));
  };

  _getUsableMediaStream = async (): Promise<MediaStream> => {
    try {
      return await this._getMediaStream(true); // スマホの場合はこれでリアカメラが取れる
    } catch (e) {}
    return await this._getMediaStream(false); // PCの場合はこちらになる。これで無理ならもうだめ
  };

  _getMediaStream = async (useRearCamera: boolean): Promise<MediaStream> => {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        ...DEFAULT_VIDEO_CONSTRAINTS,
        facingMode: getFacingMode(useRearCamera),
      },
    });
    return stream;
  };
}

export class __MockRecorderApi implements IRecorderApi {
  private index: number = 0;
  constructor(private readonly results: Array<'ok' | 'ng'>) {}
  check = async (): Promise<CheckResult> => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    const result = this.results[this.index++ % this.results.length];
    if (result === 'ok') return { status: 'ok' };
    return {
      status: 'ng',
      errors: [
        {
          message:
            '撮影・録音の権限がオンになっていないようです（モックメッセージです）。',
          object: new Error('mock error'),
        },
      ],
    };
  };

  createRecorder = async (): Promise<IRecorder> => {
    throw new Error('Not implemented yet');
  };
}

type RecorderState = 'new' | 'recording' | 'stopped' | 'done';
export type IRecorder = {
  state: RecorderState;
  stream: MediaStream;
  getData: () => Blob;
  start: () => void;
  stop: () => void;
  addListener: (listener: Listener) => void;
  clean: () => void;
};

type Listener = (thisObject: Recorder) => void;
class Recorder implements IRecorder {
  private _state: RecorderState = 'new';
  private _listener: Listener | undefined = undefined;
  private _data: Blob | undefined = undefined;

  constructor(private readonly _mediaRecorder: MediaRecorder) {}

  get state() {
    return this._state;
  }

  get stream() {
    return this._mediaRecorder.stream;
  }

  getData = () => {
    if (!this._data) throw new Error('getData not available');
    return this._data;
  };

  start = () => {
    logger.log('Recorder.start');
    if (this._state !== 'new') throw new Error('start not available');
    this._state = 'recording';
    this._mediaRecorder.start();
  };

  stop = (callback = () => {}) => {
    logger.log('Recorder.stop');
    if (this._state !== 'recording') throw new Error('stop not available');
    this._state = 'stopped';
    // const listener = this._listener
    const onDataAvailable = (event: BlobEvent) => {
      logger.log('Recorder.onDataAvailable');
      this._mediaRecorder.ondataavailable = null;
      this._state = 'done';
      this._data = event.data;
      this._listener?.(this);
      callback();
    };
    this._mediaRecorder.ondataavailable = onDataAvailable;
    this._mediaRecorder.stop();
    this._cleanTracks();
  };

  addListener = (listener: Listener) => {
    this._listener = listener;
  };

  clean = () => {
    logger.log('Recorder.clean');
    this._listener = undefined;
    this._data = undefined;
    if (this._state === 'recording')
      this.stop(() => {
        this._data = undefined;
      });
    else this._cleanTracks();
  };

  _cleanTracks = () => {
    this._mediaRecorder.stream.getTracks().forEach((track) => track.stop());
  };
}

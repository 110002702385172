import VIDEO_HELP_COMMON_1 from '../assets/images/helpVideo/common_1.png';
import LOGO_IMAGE from '../assets/images/logo.png';
import ONE_TIME_CODE_SAMPLE_IMAGE from '../assets/images/onetimecode_sample.png';
import PET_NO_IMAGE from '../assets/images/pet-noimage.png';

export {
  LOGO_IMAGE,
  ONE_TIME_CODE_SAMPLE_IMAGE,
  PET_NO_IMAGE,
  VIDEO_HELP_COMMON_1,
};

const ANDROID_VIDEO_HELP_IMAGES: {
  [k in 'chrome' | 'site']: string[];
} = {
  chrome: [1, 2, 3, 4, 5, 6, 7].map((i) =>
    require(`../assets/images/helpVideo/android_a${i}.png`)
  ),
  site: [1, 2, 3, 4, 5, 6].map((i) =>
    require(`../assets/images/helpVideo/android_b${i}.png`)
  ),
};
// index begins with 1
export const getAndroidHelpImage = (
  type: keyof typeof ANDROID_VIDEO_HELP_IMAGES,
  index: number
) => ANDROID_VIDEO_HELP_IMAGES[type][index - 1];

const IOS_VIDEO_HELP_IMAGES = [1, 2, 3, 4, 5].map((i) =>
  require(`../assets/images/helpVideo/ios_${i}.png`)
);
// index begins with 1
export const getIosHelpImage = (index: number) =>
  IOS_VIDEO_HELP_IMAGES[index - 1];

import { Card, CardContent } from '@mui/material';
import { FC } from 'react';

import Owner from '../../types/Owner';
import Pet from '../../types/Pet';
import { Section, Flex } from '../shared/Boxes';
import Field from '../shared/Field';
import Spacing from '../shared/Spacing';
import { Body, Caption } from '../shared/Typographies';
import PetIcon from './PetIcon';

type Props = {
  pet: Pet;
  owner: Owner | null;
};
export const PetCard: FC<Props> = (props) => {
  return (
    <Card>
      <CardContent>
        <PetCardContent {...props} />
      </CardContent>
    </Card>
  );
};

export const PetCardContent: FC<Props> = ({ pet, owner }) => {
  return (
    <>
      <Section>
        <Flex>
          <PetIcon pet={pet} size={75} />
          <Spacing>
            <Caption>お名前</Caption>
            <Body>{pet.chanName}</Body>
          </Spacing>
        </Flex>
      </Section>
      <Field caption="犬種">{pet.bloodLabel}</Field>
      <Field caption="生年月日" last={!owner}>
        {pet.birthday}
      </Field>
      {owner && (
        <Field caption="飼い主様のニックネーム" last>
          {owner.nickname}
        </Field>
      )}
    </>
  );
};

import { useCallback } from 'react';

import { CertificateCard } from '../../components/domain/CertificateCard';
import { Section } from '../../components/shared/Boxes';
import {
  TransitButton,
  SecondActionButton,
} from '../../components/shared/Buttons';
import Spacing from '../../components/shared/Spacing';
import { Body, Title } from '../../components/shared/Typographies';
import routes from '../../config/routes';
import { useCertifyCtx } from '../../contexts/CertifyCtx';
import { useSetupLayout } from '../../hooks/layoutHooks';

const CompleteStep = () => {
  useSetupLayout({});

  const {
    step,
    values: { info, pet },
    reset,
  } = useCertifyCtx('complete');
  if (step.type !== 'complete') throw new Error('Invalid step');
  const item = step.item;
  const { certificate } = item;

  if (!info || !pet || !certificate) throw new Error('Invalid state');

  const handleGoBackWithPet = useCallback(() => reset({ keepsPet: true }), []);

  return (
    <>
      <Section>
        <Title center>認定試験を登録しました</Title>
        <Spacing m={2}>
          <Body center>認定試験は完了です。</Body>
          <Body center>お疲れさまでした。</Body>
        </Spacing>
      </Section>
      <Section>
        <CertificateCard info={info} pet={pet} certificate={certificate} />
      </Section>
      <Spacing>
        <TransitButton fullWidth onClick={handleGoBackWithPet}>
          同じワンちゃんで認定を続ける
        </TransitButton>
      </Spacing>
      <Spacing>
        <SecondActionButton fullWidth href={routes.home}>
          HOMEに戻る
        </SecondActionButton>
      </Spacing>
    </>
  );
};
export default CompleteStep;

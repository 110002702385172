import { FC } from 'react';

import TrainingInfo from '../../types/TrainingInfo';
import { Row, Section } from '../shared/Boxes';
import Manuscript from '../shared/Manuscript';
import OpenTrigger from '../shared/OpenTrriger';
import { Title } from '../shared/Typographies';
import TrainingGenreTags from './TrainingInfoGenreTags';
import Youtube from './Youtube';

type Props = { info: TrainingInfo };
export const TrainingInfoDetail: FC<Props> = ({ info }) => {
  return (
    <>
      <Section caption="認定試験名">
        <Title>{info.title}</Title>
        <Row>
          <TrainingGenreTags genres={info.genres} />
        </Row>
      </Section>

      <Describe title="認定内容" content={info.content} />
      <Describe title="用意するもの" content={info.preparation} />
      {info.caution && <Describe title="注意事項" content={info.caution} />}

      <Section center>
        <Youtube youtubeId={info.youtubeId} />
      </Section>
    </>
  );
};

const Describe: FC<{ title: string; content: string }> = ({
  title,
  content,
}) => {
  return (
    <Section>
      <Manuscript title={title} pre>
        {content}
      </Manuscript>
    </Section>
  );
};

export const TrainingInfoDetailTrigger: FC<Props> = ({ ...props }) => {
  const { info } = props;
  return (
    <OpenTrigger label={`認定試験「${info.title}」の詳細`}>
      <TrainingInfoDetail {...props} />
    </OpenTrigger>
  );
};

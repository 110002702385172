import { Box, Icon } from '@mui/material';
import {
  useContext,
  createContext,
  FC,
  PropsWithChildren,
  useMemo,
} from 'react';
import styled from 'styled-components';

import { Flex } from './Boxes';
import Colors from './Colors';
import { Caption } from './Typographies';

type ContextValue = {
  gap: number;
};
const Context = createContext<ContextValue>({ gap: 1 });

type Props = PropsWithChildren & {
  scrollAreaWidth?: number;
  gap?: number;
  p?: number;
};
const HorizontalPanel: FC<Props> = ({
  children,
  scrollAreaWidth,
  gap = 1,
  p = gap,
}) => {
  const ctxValue = useMemo<ContextValue>(
    () => ({
      gap,
    }),
    [gap]
  );

  return (
    <Context.Provider value={ctxValue}>
      <ScrollView sx={{ p, pb: 0, backgroundColor: Colors.bgSubtle }}>
        <ScrollArea width={scrollAreaWidth}>
          {children}
          <Box sx={{ mt: gap }}>
            <Flex alignItems="center">
              <Icon sx={{ mr: 1, color: 'gray' }}>swipe</Icon>
              <Caption>このエリアは左右にスワイプできます</Caption>
            </Flex>
          </Box>
        </ScrollArea>
      </ScrollView>
    </Context.Provider>
  );
};
export default HorizontalPanel;

export const HorizontalPanelItem: FC<PropsWithChildren> = ({ children }) => {
  const { gap } = useContext(Context);
  return (
    <Box display="inline-block" sx={{ p: gap / 2 }}>
      {children}
    </Box>
  );
};

const ScrollView = styled(Box)`
  overflow-x: auto;
  word-break: keep-all;
`;

const ScrollArea = styled(Box)`
  width: ${(props: React.HTMLProps<HTMLDivElement>) =>
    props.width ? `${props.width}px` : 'auto'};
`;

import { Container } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;

const MobileFirstWidth: FC<Props> = ({ children }) => {
  return <Container maxWidth="sm">{children}</Container>;
};

export default MobileFirstWidth;

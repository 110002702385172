import env from './env';

export const MOVIE_ATTACH_FILE_MAX_MB = 600; // 600MB

const _entry = (path: string) => `${env.ENTRY_URL_BASE}${path}`;

export const TERMS_URL = _entry('/trainer/term');
export const PRIVACY_URL = _entry('/trainer/privacy');
export const RECORD_APP_URL = _entry('/app/trainer'); // アプリの撮影画面
export const APP_TRAINER_URL = 'https://app.wanpass.me/trainer'; // アプリの認定士画面ホーム（または認定士ログイン画面）

import { useCallback } from 'react';

import logApi, { INSERT_LOG_REQUEST } from '../api/logApi';
import logger from '../config/logger';
import { useApiCaller } from './apiHooks';

export const useInsertLogCallback = () => {
  const apiCaller = useApiCaller();
  const insertLog = useCallback(
    (req: INSERT_LOG_REQUEST) => {
      apiCaller
        .call(logApi.insertLog(req))
        .then(() => logger.log('insertLog done', req))
        .catch(logger.error);
    },
    [apiCaller]
  );
  return insertLog;
};

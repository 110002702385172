import { Box, Chip, Dialog, DialogActions, DialogContent } from '@mui/material';
import {
  CSSProperties,
  FC,
  ImgHTMLAttributes,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';

import { BoxesBase, BoxesBaseProps, FlexCenter } from './Boxes';
import { CancelButton } from './Buttons';
import InlineBlockWrapper from './InlineBlockWrapper';
import { Label } from './Typographies';

type Props = BoxesBaseProps &
  Pick<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
    canOpenDialog?: boolean;
    dialogTitle?: string;
    center?: boolean;
    imgStyle?: CSSProperties;
  };

const ImageBox: FC<Props> = ({
  src,
  canOpenDialog,
  dialogTitle,
  center,
  border,
  imgStyle,
  ...boxProps
}) => {
  const WrapperCmp = canOpenDialog ? DialogModeView : PlainModeView;

  return (
    <WrapperCmp src={src} title={dialogTitle} center={center}>
      <BoxesBase {...{ ...boxProps }} display="inline-block">
        <ImgView src={src} style={imgStyle} />
      </BoxesBase>
    </WrapperCmp>
  );
};
export default ImageBox;

const ImgView = styled.img`
  width: inherit;
  min-width: inherit;
  max-width: inherit;
  height: inherit;
  min-height: inherit;
  max-height: inherit;
`;

const PlainModeView: FC<PropsWithChildren & Pick<Props, 'center'>> = ({
  children,
  center,
}) => {
  return (
    <>
      <Centerable center={center}>{children}</Centerable>
    </>
  );
};

const DialogModeView: FC<
  PropsWithChildren & Pick<Props, 'src' | 'title' | 'center'>
> = ({ src, title, children, center }) => {
  const [open, setOpen] = useState(false);
  const toggle = useCallback(
    (e: SyntheticEvent) => {
      setOpen(!open);
      e.preventDefault();
    },
    [open]
  );

  return (
    <>
      <Dialog open={open} onClose={toggle} fullWidth>
        <DialogContent sx={{ p: 0, pt: 1 }}>
          {title && (
            <FlexCenter>
              <Label>{title}</Label>
            </FlexCenter>
          )}
          <Box sx={{ width: '100%' }}>
            <ImgView src={src} />
          </Box>
        </DialogContent>
        <DialogActions>
          <CancelButton onClick={toggle}>閉じる</CancelButton>
        </DialogActions>
      </Dialog>
      <Centerable center={center}>
        <LinkTag href="#" onClick={toggle}>
          <ZoomRelative>
            <ZoomAbsolute>
              <Chip clickable label="🔍拡大" />
            </ZoomAbsolute>
            {children}
          </ZoomRelative>
        </LinkTag>
      </Centerable>
    </>
  );
};

const Centerable = InlineBlockWrapper;

const ZoomRelative = styled.div`
  display: inline-block;
  position: relative;
  overflow: hidden; // to avoid design collapse when image is loading
`;

const ZoomAbsolute = styled.div`
  position: absolute;

  bottom: 4px;
  right: 4px;
`;

const LinkTag = styled.a`
  display: inline-block;
  text-decoration: none;
`;

import { FC, PropsWithChildren } from 'react';

import AppLayout from './AppLayout';

type Props = PropsWithChildren<{}>;

const NoAuthLayout: FC<Props> = ({ children }) => {
  return <AppLayout>{children}</AppLayout>;
};

export default NoAuthLayout;

import { FC } from 'react';

import { VIDEO_HELP_COMMON_1 } from '../../config/assets';
import { Row } from '../shared/Boxes';
import OpenTrigger from '../shared/OpenTrriger';
import Separator from '../shared/Separator';
import { Title } from '../shared/Typographies';
import VideoAuthorityHelpAndroid from './VideoAuthorityHelpAndroid';
import VideoAuthorityHelpImageItem from './VideoAuthorityHelpImageItem';
import VideoAuthorityHelpIos from './VideoAuthorityHelpIos';

const VideoAuthorityHelp: FC = () => {
  return (
    <>
      <Title field>撮影権限の設定方法</Title>
      <Row>
        <OpenTrigger label="Android の設定方法">
          <VideoAuthorityHelpAndroid />
          <CommonFooter />
        </OpenTrigger>
      </Row>
      <Row>
        <OpenTrigger label="iPhone の設定方法">
          <VideoAuthorityHelpIos />
          <CommonFooter />
        </OpenTrigger>
      </Row>
    </>
  );
};

export default VideoAuthorityHelp;

const CommonFooter = () => {
  return (
    <>
      <Separator />
      以上の設定が完了した後、元の画面で「もう一度権限を確認する」を押すと、以下のようなポップアップが表示されます。「許可する」を押すとカメラ・マイク撮影が可能となります。
      <VideoAuthorityHelpImageItem src={VIDEO_HELP_COMMON_1} />
    </>
  );
};

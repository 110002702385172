import { FC } from 'react';

import { getIosHelpImage } from '../../config/assets';
import { SectionRow } from '../shared/Boxes';
import { Title } from '../shared/Typographies';
import VideoAuthorityHelpImageItem from './VideoAuthorityHelpImageItem';

const VideoAuthorityHelpIos = () => {
  return (
    <>
      <Title>iPhone の設定方法</Title>
      <SectionRow>
        「設定」→「Safari」から、カメラとマイクの権限設定が必要です。
      </SectionRow>
      👇 iPhone の「設定」アプリを開きます。
      <IosImg i={1} noDialog />
      👇 「Safari」で検索して、Safariの設定を開きます
      <IosImg i={2} />
      👇 「カメラ」と「マイク」をそれぞれ選択します。
      <IosImg i={3} />
      👇 「カメラ」の権限を「確認」にします。
      <IosImg i={4} />
      👇 「マイク」の権限を「確認」にします。
      <IosImg i={5} />
    </>
  );
};
export default VideoAuthorityHelpIos;

const IosImg: FC<{ i: number; noDialog?: boolean }> = ({ i, noDialog }) => (
  <VideoAuthorityHelpImageItem src={getIosHelpImage(i)} noDialog={noDialog} />
);

import { Chip, Icon } from '@mui/material';
import { FC } from 'react';

import { CERTIFY_ICON, DOG_ICON } from '../../config/icons';
import Pet from '../../types/Pet';
import TrainingInfo from '../../types/TrainingInfo';
import { Flex } from '../shared/Boxes';
import PetIcon from './PetIcon';

type Props = {
  pet: Pet;
  info?: TrainingInfo;
};
const CertifyProgressBar: FC<Props> = ({ pet, info }) => {
  return (
    <>
      <Flex alignItems="center" columnGap={1}>
        <PetIcon pet={pet} size={28} />
        <Chip
          variant="outlined"
          size="small"
          label={`${pet.chanName}`}
          icon={<Icon>{DOG_ICON}</Icon>}
        />
        {info && (
          <Chip
            variant="outlined"
            size="small"
            label={info?.title}
            icon={<Icon>{CERTIFY_ICON}</Icon>}
          />
        )}
      </Flex>
    </>
  );
};
export default CertifyProgressBar;

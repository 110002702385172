import { Button, ButtonProps, Icon } from '@mui/material';
import { FC } from 'react';

import { useSoundSingle } from '../../contexts/SoundCtx';
import ShakingAnimation from './ShakingAnimation';

type Props = {
  src: string;
  label: string;
  priority?: Priority;
};
type Priority = 'normal' | 'high';

const BUTTON_COLOR_MAP: { [k in Priority]: ButtonProps['color'] } = {
  normal: 'secondary',
  high: 'info',
};

const SoundButton: FC<Props> = ({ src, label, priority = 'normal' }) => {
  const { isPlaying, onSoundTriggered } = useSoundSingle(src);

  const iconColor = isPlaying ? 'primary' : undefined;
  const icon = (
    <Icon color={iconColor}>
      <ShakingAnimation enabled={isPlaying}>music_note</ShakingAnimation>
    </Icon>
  );
  const color = BUTTON_COLOR_MAP[priority];

  return (
    <Button
      onClick={onSoundTriggered}
      startIcon={icon}
      variant="contained"
      color={color}
    >
      {label}
    </Button>
  );
};

export default SoundButton;

const NODE_ENV: 'production' | 'development' | 'test' = process.env.NODE_ENV;

// See also: `.env.*` file
const env = {
  NODE_ENV,
  API_BASE: process.env.REACT_APP_API_BASE ?? 'http://localhost:8004/trainer',
  ENABLE_DEBUG_LOGIN:
    !!process.env.REACT_APP_ENABLE_DEBUG_LOGIN || NODE_ENV === 'development',
  ENABLE_DEBUG_MOVIE_SKIP: NODE_ENV === 'development',
  STAGING_PASSWORDS:
    process.env.REACT_APP_STAGING_PASSWORDS?.split(',') ?? undefined,
  ENABLE_APP_RECORD: !!process.env.REACT_APP_ENABLE_APP_RECORD,
  ENTRY_URL_BASE:
    process.env.REACT_APP_ENTRY_URL_BASE ?? 'https://dev.entry.wanpass.me',
};

export default env;

import { Card, CardContent } from '@mui/material';

import routes from '../../config/routes';
import Certificate from '../../types/Certificate';
import Pet from '../../types/Pet';
import TrainingInfo from '../../types/TrainingInfo';
import { toCalendarFormat } from '../../utils/dateFormat';
import { Flex, Row } from '../shared/Boxes';
import { ActionButton } from '../shared/Buttons';
import Spacing from '../shared/Spacing';
import { Label } from '../shared/Typographies';
import PetIcon from './PetIcon';
import ResultLabel from './ResultLabel';

export const CertificateCard = ({
  info,
  pet,
  certificate,
}: {
  certificate: Certificate;
  pet: Pet;
  info: TrainingInfo;
}) => {
  return (
    <Card>
      <CardContent>
        <Flex>
          <Spacing>
            <PetIcon pet={pet} size={75} />
          </Spacing>
          <Row fullWidth>
            <Label>{info.title}</Label>
            <Row>{pet.chanName}</Row>
            <Row>受験日： {toCalendarFormat(certificate.certifiedAt)}</Row>
            <Row>
              結果： <ResultLabel type={certificate.resultType} />
            </Row>
            <Spacing />
            <Row>
              <ActionButton
                href={routes.historySingle(certificate.id)}
                fullWidth
              >
                詳細を見る
              </ActionButton>
            </Row>
          </Row>
        </Flex>
      </CardContent>
    </Card>
  );
};

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, PropsWithChildren, useCallback, useState } from 'react';

import { Section } from '../shared/Boxes';
import { ActionButton } from '../shared/Buttons';
import { NoiseSoundPanel } from './NoiseSoundPanel';

type Props = PropsWithChildren & {
  enabled?: boolean;
};

const SoundVolumeCheckGuard: FC<Props> = ({ enabled = true, children }) => {
  const [done, setDone] = useState(false);
  const onDone = useCallback(() => setDone(true), []);

  if (!enabled || done) return <>{children}</>;

  return <SoundVolumeCheckView onClose={onDone} />;
};

export default SoundVolumeCheckGuard;

const SoundVolumeCheckView: FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>音量の調整</DialogTitle>
      <DialogContent>
        <Section>
          しつけ認定では環境音を利用します。以下のボタンを押して適切な音量になっているかご確認ください。音が小さい場合、スマートフォンで音量を調整してください。
        </Section>
        <NoiseSoundPanel />
      </DialogContent>
      <DialogActions>
        <ActionButton onClick={onClose}>音量調整が完了しました</ActionButton>
      </DialogActions>
    </Dialog>
  );
};

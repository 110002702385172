import env from './env';

const debugLogger = {
  log: console.log,
  error: console.error,
};

const nopLogger: typeof debugLogger = {
  log: () => {},
  error: () => {},
};

const logger = env.NODE_ENV === 'development' ? debugLogger : nopLogger;

export default logger;

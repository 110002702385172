import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

import AnyFragment from '../AnyFragment';
import UrlLink from '../UrlLink';
import {
  FormField,
  FormFieldExtendProps,
  useFormContainerCtx,
} from './FormContainer';
import { wrapByFormFieldRow } from './FormFieldRow';
import SimpleViewMode from './SimpleViewMode';

type Props = FormFieldExtendProps &
  TextFieldProps & {
    isUrl?: boolean;
  };

/**
 *
 * @params: name, label are required.
 */
const FormText: FC<Props> = ({
  name,
  label,
  validations,
  helperText,
  isUrl = false,
  ...rest
}) => {
  const { register, getState } = useFormContainerCtx();

  const { value, error } = getState<string>(name);
  const LinkOrFragment = isUrl && value ? UrlLink : AnyFragment;
  const viewMode = (
    <SimpleViewMode label={label}>
      {value && (
        <LinkOrFragment href={value} targetBlank>
          {value}
        </LinkOrFragment>
      )}
    </SimpleViewMode>
  );

  const inputMode = (
    <TextField
      label={label}
      {...register(name, validations)}
      error={!!error}
      helperText={error ?? helperText}
      autoComplete="off"
      {...rest}
    />
  );
  return <FormField viewMode={viewMode} inputMode={inputMode} />;
};

export const FormTextRow: FC<Props> = wrapByFormFieldRow(FormText);

export default FormText;

import { ComponentProps, FC, Fragment } from 'react';

type Props = ComponentProps<typeof Fragment>;

/**
 * This is alternative for `react.Fragment`
 *
 * AnyFragment behaves like `react.Fragment` but ignores all props except children.
 *
 * This is needed to avoid warning error:
 * ```
 *  Warning: Invalid prop `target` supplied to `React.Fragment`. React.Fragment can only have `key` and `children` props.
 * ```
 *
 * Usage example:
 * ```
 *  const Wrapper = url ? Link : AnyFragment // url defined then put link else put text.
 *  return <Wrapper href={url}>{message}</Wrapper>
 * ```
 */
const AnyFragment: FC<Props> = ({ children }) => {
  // ignores props except children
  return <Fragment {...{ children }} />;
};

export default AnyFragment;

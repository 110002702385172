import { Typography } from '@mui/material';
import { useCallback } from 'react';

import sessionApi from '../api/sessionApi';
import trainerApi from '../api/trainerApi';
import UserFormField, {
  UserFormFieldValues,
} from '../components/domain/UserFormFields';
import { ActionButton } from '../components/shared/Buttons';
import Spacing from '../components/shared/Spacing';
import FormContainer, {
  useFormContainer,
} from '../components/shared/forms/FormContainer';
import { useCallbackApiLoading, useEffectApiLoading } from '../hooks/apiHooks';
import { useRefreshAuthUser } from '../hooks/authHooks';
import { useSetupLayout } from '../hooks/layoutHooks';

const ProfilePage = () => {
  useSetupLayout({
    title: 'プロフィール情報',
    description: '飼い主様のアプリに表示する情報です。',
  });
  const { form, mode, onEditClick, onSaveClick } = useService();

  return (
    <FormContainer value={form}>
      <Typography align="right">
        {mode === 'view' && (
          <ActionButton onClick={onEditClick}>Edit</ActionButton>
        )}
      </Typography>
      <Spacing />
      <UserFormField />
      <Spacing />
      {mode === 'input' && (
        <ActionButton onClick={onSaveClick}>Save</ActionButton>
      )}
    </FormContainer>
  );
};

export default ProfilePage;

const useService = () => {
  const form = useFormContainer<UserFormFieldValues>({
    defaultMode: 'view',
  });
  const { mode, setMode, setValues, handleSubmit } = form;
  const onEditClick = useCallback(() => setMode('input'), [setMode]);

  useEffectApiLoading(async ({ apiCaller }) => {
    const me = await apiCaller.call(sessionApi.getMe());
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const trainer = me.trainer!;

    setValues({
      fullname: trainer.fullname,
      schoolName: trainer.schoolName ?? '',
      schoolUrl: trainer.schoolUrl ?? '',
      image: trainer.image,
    });
  }, []);

  const refreshAuthUser = useRefreshAuthUser();

  const saveToApi = useCallbackApiLoading(
    async (data: UserFormFieldValues, { apiCaller }) => {
      await apiCaller.call(
        trainerApi.updateTrainer({
          fullname: data.fullname,
          schoolName: data.schoolName,
          schoolUrl: data.schoolUrl,
          image: data.image,
        })
      );
      await refreshAuthUser();
      setMode('view');
    },
    [refreshAuthUser]
  );

  const onSaveClick = handleSubmit(async (data) => {
    await saveToApi(data);
  });

  return {
    form,
    mode,
    onEditClick,
    onSaveClick,
  };
};

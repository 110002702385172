import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';

import infosApi from '../../api/infosApi';
import { Section } from '../../components/shared/Boxes';
import { ActionButton } from '../../components/shared/Buttons';
import { SubTitle } from '../../components/shared/Typographies';
import { useEffectApiLoading } from '../../hooks/apiHooks';
import { Venue, VenuesInfo } from '../../types/VenuesInfo';

const VenuesPage = () => {
  const [info, setInfo] = useState<VenuesInfo | undefined>(undefined);
  useEffectApiLoading(async ({ apiCaller }) => {
    const info = await apiCaller.call(infosApi.getVenues());
    setInfo(info);
  }, []);

  return (
    <>
      <Section>
        <h1>Wan!Pass認定会場 協力施設</h1>
      </Section>
      <Section>
        Wan!Pass認定士に場所を提供しても良いという施設様の一覧です。
        <br />
        認定士の方から直接、施設にご連絡をお願いいたします。
        <br />※ 内容は随時更新されます。
      </Section>
      <Section>{info && <VenuesList info={info} />}</Section>
    </>
  );
};

export default VenuesPage;

const VenuesList = ({ info }: { info: VenuesInfo }) => {
  const { titles, venues } = info;
  return (
    <ListWrapper>
      <div style={{ textAlign: 'center' }}>
        {venues.length === 0 ? (
          <div>
            申し訳ございません。
            <br />
            現在、協力施設がありません。
            <br />
            しばらく期間をおいてからご確認ください。
          </div>
        ) : (
          <div>現在、{venues.length}件の協力施設があります。</div>
        )}
      </div>
      {venues.map((venue) => (
        <VenueRowMemo key={venue[0]} titles={titles} venue={venue} />
      ))}
    </ListWrapper>
  );
};

// 施設名、都道府県、施設種別、施設のURL、利用料 のインデックス
const INDICES = [0, 2, 4, 5, 10];

const VenueRow = ({
  titles,
  venue,
}: {
  titles: VenuesInfo['titles'];
  venue: Venue;
}) => {
  const [open, setOpen] = useState(false);
  const [name, pref, kinds, url, fee] = INDICES.map((i) => venue[i]);

  const urls = parseUrls(url);

  return (
    <div style={{ margin: '1em' }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{name}</DialogTitle>
        <DialogContent>
          <VenueDetail venue={venue} titles={titles} />
          <Divider />
          <div style={{ textAlign: 'right', marginTop: 8 }}>
            <ActionButton onClick={() => setOpen(false)} color="primary">
              閉じる
            </ActionButton>
          </div>
        </DialogContent>
      </Dialog>
      <Card>
        <CardContent>
          <SubTitle>{name}</SubTitle>
          都道府県： <b>{pref}</b>
          <br />
          施設種別： {kinds}
          <br />
          ウェブサイト：
          {urls?.map((url) => (
            <a
              key={url}
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: '4px' }}
            >
              URL
            </a>
          )) ?? '-'}
          <br />
          利用料： {fee}
          <CardActions>
            <ActionButton onClick={() => setOpen(true)}>
              さらに詳細を見る
            </ActionButton>
          </CardActions>
        </CardContent>
      </Card>
    </div>
  );
};
const VenueRowMemo = React.memo(VenueRow);

const parseUrls = (urlText: string): string[] | null => {
  const urls = urlText
    .split('\n')
    .map((s) => s.trim())
    .filter((s) => s);
  if (urls.length === 0) return null;
  return urls;
};

const VenueDetail = ({
  titles,
  venue,
}: {
  titles: VenuesInfo['titles'];
  venue: Venue;
}) => {
  return (
    <div>
      {titles.map((title, i) => (
        <div key={title} style={{ marginBottom: 8 }}>
          <div style={{ color: '#333' }}>
            <span style={{ fontSize: 14 }}>{title}</span>
          </div>
          <div style={{ fontSize: 15, marginLeft: 8 }}>
            {title.match(/施設のURL/) ? (
              parseUrls(venue[i])?.map((url) => (
                <div key={url}>
                  <a href={url} target="_blank" rel="noreferrer">
                    {url}
                  </a>
                </div>
              )) ?? <>-</>
            ) : (
              <b>{venue[i] || '-'}</b>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const ListWrapper = styled.div`
  font-size: 14px;
`;

/**
 サンプル：
{
  titles: [
    '施設名',
    '施設所在地の郵便番号(ハイフンあり）',
    '施設所在地の都道府県',
    '施設所在地の市区町村以降の住所',
    '施設種別',
    '施設のURL（公式HP、SNS、google map, など）',
    '認定会利用不可な日があれば記入',
    '認定会場として利用可能なスペースの大まかな広さ(㎡)',
    '屋外or屋内',
    '認定会場として利用可能なスペースに関する補足（任意）',
    '利用料（記入例：無料、1000円/1時間）',
    'Wan!Pass認定会場として自施設を貸し出すことに対しての温度感',
    '認定士からの連絡手段',
    '連絡先',
  ],
  venues: [
    [
      'ペッツオーライ新川センター',
      '104-0033',
      '東京都',
      '東京都中央区新川一丁目11番10号',
      'しつけ教室, ペットホテル, ペットと泊まれる宿, 本社',
      'https://about.petsallright.net/',
      '土, 日, 祝日',
      '100',
      '屋内, 屋外（屋根なし）',
      '特に無し',
      '1000円/1時間',
      'ぜひ貸し出したい！',
      '電話, メール, 電話',
      '070-4230-3101 y.yamamoto@petsallright.net',
    ],
  ],
}
 */

import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const ShakingAnimation: FC<PropsWithChildren & { enabled: boolean }> = ({
  children,
  enabled,
}) => {
  if (!enabled) return <NoAnimation>{children}</NoAnimation>;
  return <Animation>{children}</Animation>;
};

export default ShakingAnimation;

const NoAnimation = styled.div`
  display: inherit;
`;

const Animation = styled.div`
  display: inherit;
  animation: hurueru 0.5s infinite;

  @keyframes hurueru {
    0% {
      transform: translate(0px, 0px) rotateZ(0deg);
    }
    25% {
      transform: translate(2px, 2px) rotateZ(1deg);
    }
    50% {
      transform: translate(0px, 2px) rotateZ(0deg);
    }
    75% {
      transform: translate(2px, 0px) rotateZ(-1deg);
    }
    100% {
      transform: translate(0px, 0px) rotateZ(0deg);
    }
  }
`;

import firebase from 'firebase/compat/app'; // See: https://zenn.dev/hiro__dev/articles/605161cd5a7875
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

// package の firebaseui は型情報を取得するためだけに import しており、
// 実際のオブジェクトは firebase-ui-auth__ja.js によって window に挿入された firebaseui を使います。
export const getFirebaseuiModule = () =>
  (window as any).firebaseui as typeof firebaseui;

export { firebase, firebaseui };

export const beforeLoadFirebaseUiJaScript = () => {
  (window as any).firebase = firebase;
};
export const FIREBASEUI_JA_SCRIPT_URL =
  'https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth__ja.js';

export const MOCK_ASSET_API_URL_BASE = 'https://ASSET-API-MOCK.example.com';

export type AssetId = string;

export type AssetImageScale =
  | 'small' // 320px
  | 'medium' // 750px
  | 'large'; // 1500px

export type AssetImage = {
  id: AssetId;
  url: string;
  thumbnailUrl: string;
  originalFileUrl: string;
};

export type AssetMovie = {
  id: string;
  url: string;
};

// -------------------------
// Image version spec
// -------------------------

export enum ImageVersion {
  V1 = 'v1',
}
interface IImageVersionSpec {
  getOriginalFilePath: (id: string) => string;
  createNewImageId: (uuidString: string) => string;
  getImagePath: (id: string) => string;
  getThumbImagePath: (id: string) => string;
}
// AssetServiceClient.sdk.v1.ts と仕様を合わせること。
class ImageV1Spec implements IImageVersionSpec {
  createNewImageId = (uuidString: string) => 'v1-' + uuidString.slice(3);
  getOriginalFilePath = (id: string) => this._imageDirPath(id) + 'originalfile';
  getImagePath = (id: string) => this._imageDirPath(id) + 'image.jpg';
  getThumbImagePath = (id: string) => this._imageDirPath(id) + 'thumb.jpg';

  _imageDirPath = (id: string): string => `/v1/images/${id}/`;
}

export const ImageVersionSpecs: { [k in ImageVersion]: IImageVersionSpec } = {
  v1: new ImageV1Spec(),
};

export const toAssetImagebySpec = (
  id: string,
  { assetUrlBase, version }: { assetUrlBase: string; version: ImageVersion }
): AssetImage => {
  const spec = ImageVersionSpecs[version];
  return {
    id,
    url: assetUrlBase + spec.getImagePath(id),
    thumbnailUrl: assetUrlBase + spec.getThumbImagePath(id),
    originalFileUrl: assetUrlBase + spec.getOriginalFilePath(id),
  };
};

// -------------------------
// Movie version spec
// -------------------------

export enum MovieVersion {
  V1 = 'v1',
}

interface IMovieVersionSpec {
  createNewMovieId: (uuidString: string) => string;
  getOriginalFilePath: (id: string) => string;
}

// AssetServiceClient.sdk.v1.ts と仕様を合わせること。
class MovieV1Spec implements IMovieVersionSpec {
  createNewMovieId = (uuidString: string) => 'v1-' + uuidString.slice(3);
  getOriginalFilePath = (id: string) => this._movieDirPath(id) + 'originalfile';
  _movieDirPath = (id: string): string => `/v1/movies/${id}/`;
}

export const MovieVersionSpecs: { [k in MovieVersion]: IMovieVersionSpec } = {
  v1: new MovieV1Spec(),
};

export const toAssetMovieBySpec = (
  id: string,
  {
    assetUrlBase,
    version,
  }: {
    assetUrlBase: string;
    version: MovieVersion;
  }
): AssetMovie => {
  const spec = MovieVersionSpecs[version];
  return {
    id,
    url: assetUrlBase + spec.getOriginalFilePath(id),
  };
};

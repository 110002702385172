import { Grid, Icon } from '@mui/material';
import { useState, useCallback, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import DebugView from '../../components/domain/DebugView';
import FirebaseSignInScreen from '../../components/domain/FirebaseSignInScreen';
import { ActionButton, LinkButton } from '../../components/shared/Buttons';
import FormContainer, {
  useFormContainer,
} from '../../components/shared/forms/FormContainer';
import { FormTextRow } from '../../components/shared/forms/FormText';
import validations from '../../components/shared/forms/validations';
import env from '../../config/env';
import routes from '../../config/routes';
import {
  getDevDummyFirebaseIdToken,
  useIsAuthenticated,
  useLoginCallback,
} from '../../hooks/authHooks';
import { useEffectOnce, useUrlPathFromGetParams } from '../../hooks/utilHooks';

const LoginPage = () => {
  const redirectTo = useUrlPathFromGetParams('from');
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffectOnce(() => {
    if (isAuthenticated) {
      navigate(redirectTo ?? routes.redirects.afterSignInTo);
    }
  });

  return (
    <>
      <h1>
        ログイン <Icon>login</Icon>
      </h1>
      <FirebaseSignInScreen redirectPath={redirectTo} />
      <DebugView enabled={env.ENABLE_DEBUG_LOGIN}>
        {<DebugLogin redirectPath={redirectTo} />}
      </DebugView>
    </>
  );
};

export default LoginPage;

const DebugLogin: FC<{ redirectPath?: string }> = ({ redirectPath }) => {
  if (!env.ENABLE_DEBUG_LOGIN) throw new Error();
  const form = useFormContainer<{ email: string }>({
    defaultMode: 'input',
    defaultValues: { email: 'trainer+1@example.com' },
  });

  const doLogin = useLoginCallback(redirectPath);
  const onLoginClick = form.handleSubmit((data) => {
    const { email } = data;
    const firebaseIdToken = getDevDummyFirebaseIdToken(email);
    doLogin({ firebaseIdToken });
  });
  const [isHide, setHide] = useState(false);
  const onHideClick = useCallback(() => setHide(true), []);
  if (isHide) return null;

  return (
    <FormContainer value={form}>
      <hr />
      <Grid container justifyContent="flex-end">
        <LinkButton onClick={onHideClick}>✗ Hide Debug View</LinkButton>
      </Grid>
      <FormTextRow
        name="email"
        label="Debug Email"
        validations={{ ...validations.required }}
      />
      <ActionButton onClick={onLoginClick}>Debug Login</ActionButton>
      <ul>
        <li>
          Debug Notion: If you sign-in by emails like trainer+XXX@example.com,
          backend creates TrainerCandidate automatically.(so you can sign-in and
          register.) But other emails are not able to sign-in until
          TrainerCandidate is created in backend.
        </li>
      </ul>
    </FormContainer>
  );
};

const help = {
  record: '/help/record',
} as const;

const paths = {
  help,
  login: '/login',
  loginVerify: '/login/verify',
  home: '/home',
  register: '/register',
  profile: '/profile',
  history: '/history',
  historySingle: (id: string) => `/history/${id}`,
  certify: '/certify',
  infoVenus: '/info/venues',
} as const;

const redirects = {
  noAuthTo: paths.login,
  afterSignInTo: paths.home,
  afterSignOutTo: paths.login,
} as const;

const routes = {
  ...paths,
  redirects,
} as const;

export default routes;

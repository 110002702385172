import AssetImage from '../types/AssetImage';
import AssetMovie from '../types/AssetMovie';
import { AuthApiDefinition } from './ApiCaller';

// If you can use asset in each xxxxApi, You'd better to use asset in it.
// Using uploadImage, uploadMovie directly are only for special cases
// (cases that should upload data separately with other apis.)

const uploadImage: AuthApiDefinition<{ file: File }, AssetImage> =
  ({ file }) =>
  async ({ asset }) => {
    const res = await asset.uploadImage(file);
    return res;
  };

const uploadMovie: AuthApiDefinition<{ file: File | Blob }, AssetMovie> =
  ({ file }) =>
  async ({ asset }) => {
    const res = await asset.uploadMovie(file);
    return res;
  };

const assetApi = {
  uploadImage,
  uploadMovie,
};

export default assetApi;

import {
  createContext,
  DependencyList,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import petApi, { CreateCertificateRequest } from '../api/petApi';
import logger from '../config/logger';
import { useCallbackApiLoading } from '../hooks/apiHooks';
import { useAuthUser } from '../hooks/authHooks';
import { useInsertLogCallback } from '../hooks/logHooks';
import AssetMovie from '../types/AssetMovie';
import { ResultType } from '../types/Certificate';
import Pet from '../types/Pet';
import PetTrainingItem from '../types/PetTrainingItem';
import TrainingInfo from '../types/TrainingInfo';

type CtxType = {
  step: Step;
  values: Values;
  updateValues: (partial: Partial<Values>) => Values;
  startUpload: (lastUpdateValues: Values) => Promise<void>;
  reset: (options?: { keepsPet?: boolean }) => void;
};
export type CertifyCtxType = CtxType;

type Values = {
  oneTimeCode?: string;
  pet?: Pet;
  info?: TrainingInfo;
  movie?: AssetMovie | Blob | File;
  resultType?: ResultType;
  trainerComment?: string;
};
type CompleteItem = PetTrainingItem;
export type Step =
  | { type: 'pet' }
  | { type: 'info' }
  | { type: 'movie' }
  | { type: 'result' }
  | { type: 'uploading' }
  | {
      type: 'complete';
      item: CompleteItem;
    };
export type StepType = Step['type'];

const Ctx = createContext<CtxType>(null as any);
const CertifyCtx = Ctx;
export default CertifyCtx;

const assertStep = (
  expected: StepType | StepType[] | undefined,
  actual: StepType
): void => {
  if (!expected) return;
  if (!Array.isArray(expected)) return assertStep([expected], actual);
  if (!expected.includes(actual))
    logger.error(`Invalid step type. expected=`, expected, 'actual=', actual);
};

export const useCertifyCtx = (stepAssertion?: StepType | StepType[]) => {
  const ctx = useContext(Ctx);
  assertStep(stepAssertion, ctx.step.type);
  return ctx;
};
export const useCertifyState = (): CtxType => {
  const [values, setValues] = useState<Values>({});
  const [step, setStep] = useState<Step>({ type: 'pet' });

  const refreshStep = useCallback((newValues: Values) => {
    const newStep = getNextStepByValues(newValues);
    setStep(newStep);
  }, []);

  useEffect(() => {
    refreshStep(values);
  }, [values]);

  const setNewValues = useCallback((newValues: Values) => {
    setValues(newValues);
    refreshStep(newValues);
    return newValues;
  }, []);

  const updateValues = useCallback(
    (partial: Partial<Values>) => {
      return setNewValues({ ...values, ...partial });
    },
    [values]
  );

  const reset = useCallback(
    ({ keepsPet }: { keepsPet?: boolean } = {}) => {
      setNewValues(
        keepsPet
          ? {
              pet: values.pet,
              oneTimeCode: values.oneTimeCode,
            }
          : {}
      );
    },
    [values]
  );

  const uploadToApi = useUploadToApi(
    {
      before: () => setStep({ type: 'uploading' }),
      success: (item) => setStep({ type: 'complete', item }),
      fail: () => setStep({ type: 'result' }),
    },
    [setStep]
  );
  const startUpload = useCallback(
    async (lastUpdateValues: Values) => {
      const finalValues = updateValues(lastUpdateValues);
      await uploadToApi(finalValues);
    },
    [uploadToApi, updateValues]
  );

  useCertifyLogs(step, values);

  return {
    step,
    values,
    updateValues,
    startUpload,
    reset,
  };
};

export type OnMovieCompleted = (movie: Blob | AssetMovie) => void;
export type OnMovieCompletedProps = { onCompleted: OnMovieCompleted };

const getNextStepByValues = (values: Values): Step => {
  if (!values.pet) return { type: 'pet' };
  if (!values.info) return { type: 'info' };
  if (!values.movie) return { type: 'movie' };
  if (!values.resultType || !values.trainerComment) return { type: 'result' };
  return { type: 'result' };
};

const valuesToCreateRequest = ({
  oneTimeCode,
  info,
  resultType,
  trainerComment,
  movie,
}: Values): CreateCertificateRequest => {
  if (!info || !oneTimeCode || !resultType || !trainerComment || !movie)
    throw new Error('Can not valuesToCreateRequest');

  const movieRequest = movieToRequest(movie);
  return {
    oneTimeCode,
    type: info.type,
    resultType,
    comment: trainerComment,
    movie: movieRequest,
  };
};

const movieToRequest = (movie: Values['movie']): File | Blob | string => {
  if (!movie) throw new Error('Can not movieToRequest');
  if (movie instanceof File) return movie;
  if (movie instanceof Blob) return movie;
  return movie.id;
};

const useUploadToApi = (
  {
    before,
    success,
    fail,
  }: {
    before: () => void;
    success: (item: CompleteItem) => void;
    fail: (e: Error) => void;
  },
  deps: DependencyList
) =>
  useCallbackApiLoading(
    async (finalValues: Values, { apiCaller }) => {
      before();
      try {
        const request = valuesToCreateRequest(finalValues);
        logger.log('CertifyCtx create request:', request);
        const item = await apiCaller.call<CompleteItem>(
          petApi.createCertificate(request)
        );

        success(item);
      } catch (e) {
        fail(e as Error);
        throw e;
      }
    },
    deps,
    {
      errorMsg: '認定情報の送信に失敗しました。再度お試しください。',
      loadingLabel:
        '認定情報を送信中です。\n動画のアップロードに\n時間がかかります。\nしばらくお待ち下さい。',
    }
  );

const useCertifyLogs = (step: Step, values: Values) => {
  const [hasStartLogSent, setStartLogSent] = useState(false);
  const { trainer } = useAuthUser();
  const insertLog = useInsertLogCallback();

  const CERTIFY_START_EVENT_NAME = 'certify_start';
  useEffect(() => {
    if (step.type === 'info') {
      setStartLogSent(false);
      return;
    }
    if (step.type !== 'movie') return;
    if (hasStartLogSent) return;

    const { oneTimeCode, pet, info } = values;
    const data = [
      oneTimeCode,
      pet?.id,
      pet?.chanName,
      pet?.bloodLabel,
      info?.type,
      info?.title,
      trainer.id,
      trainer.fullname,
    ];
    insertLog({ event: CERTIFY_START_EVENT_NAME, data });
    setStartLogSent(true);
  }, [step, values, trainer, hasStartLogSent]);
};

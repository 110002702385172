import logger from '../config/logger';
import AssetApiAccessInfo from '../types/AssetApiAccessInfo';
import Trainer from '../types/Trainer';
import TrainerCapability from '../types/TrainerCapability';
import { AuthApiDefinition, NoAuthApiDefinition } from './ApiCaller';
import { responseToTrainer } from './convert';

type SignInReq = {
  firebaseIdToken: string;
};
const signIn: NoAuthApiDefinition<SignInReq, { bearer: string }> =
  ({ firebaseIdToken }) =>
  async ({ backend }) => {
    const res = await backend.post<{ bearer: string }>('/sign_in', {
      firebase_id_token: firebaseIdToken,
    });
    const bearer = res.data.bearer;
    if (!bearer) throw new Error('Invalid bearer received from api.');
    return { bearer };
  };

const BEARER_RESPONSE_HEADER_KEY = 'bearer';

const getMe: AuthApiDefinition<
  void,
  {
    bearer: string;
    assetAccessInfo: AssetApiAccessInfo;
    isRegisterNeeded: boolean;
    trainer?: Trainer;
    capability: TrainerCapability;
  }
> =
  () =>
  async ({ backend }) => {
    const res = await backend.get<{
      assetAccessInfo: AssetApiAccessInfo;
      isRegisterNeeded: boolean;
      trainer?: Trainer;
      capability: TrainerCapability;
    }>('/trainers/me');
    const {
      isRegisterNeeded,
      trainer: trianerData,
      assetAccessInfo,
      capability,
    } = res.data;
    const bearer = res.headers[BEARER_RESPONSE_HEADER_KEY];
    if (
      typeof isRegisterNeeded !== 'boolean' ||
      !bearer ||
      !assetAccessInfo?.token
    ) {
      logger.error(res);
      throw new Error('unexpected me response');
    }
    const trainer = trianerData ? responseToTrainer(trianerData) : undefined;
    return {
      bearer,
      isRegisterNeeded,
      trainer,
      assetAccessInfo,
      capability,
    };
  };

const sessionApi = {
  signIn,
  getMe,
};
export default sessionApi;

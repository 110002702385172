import { useEffect, useState } from 'react';
import useScript from 'react-use-scripts';

import { PRIVACY_URL, TERMS_URL } from '../config/constants';
import { firebaseConfig } from '../config/firebaseConfig';
import logger from '../config/logger';
import {
  firebaseui,
  firebase,
  getFirebaseuiModule,
  beforeLoadFirebaseUiJaScript,
  FIREBASEUI_JA_SCRIPT_URL,
} from '../libs/firebaseModules';

let _firebaseApp: firebase.app.App | undefined;
const getFirebaseApp = () =>
  (_firebaseApp ||= firebase.initializeApp(firebaseConfig));

export const firebaseAuthSignOut = async () => {
  await getFirebaseApp().auth().signOut();
};

const _catch = (e: any) => {
  console.error(e);
  window.alert(`予期せぬエラーが発生しました。\n${e.toString() as string}`);
};

const UI_CONFIG_BASE: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },
  ],
  callbacks: {
    // This return-false is needed to avoid `Redirect path not defined` error in firebase
    signInSuccessWithAuthResult: () => false,
  },
  tosUrl: TERMS_URL,
  privacyPolicyUrl: PRIVACY_URL,
};

type FireUser = firebase.User;

export type FireAuthState =
  | {
      type: 'notSignIn';
    }
  | {
      type: 'emailSending';
    }
  | {
      type: 'emailSent';
      control: FireAuthControl;
    }
  | {
      type: 'verified';
      firebaseIdToken: string;
    };

export type FireAuthControl = {
  abort: () => void;
};

export const useMountFirebaseAuthUi = (
  element: string,
  onStateChange: (state: FireAuthState) => void
) => {
  beforeLoadFirebaseUiJaScript();
  const { ready: scriptReady } = useScript({ src: FIREBASEUI_JA_SCRIPT_URL });
  const [signOutReady, setSignOutReady] = useState(false);
  const [isMountReady, setMountReady] = useState(false);

  // First reset, in order to keep browser state idempotent
  useEffect(() => {
    getFirebaseApp()
      .auth()
      .signOut()
      .then(() => setSignOutReady(true))
      .catch(_catch);
  }, []);

  useEffect(() => {
    if (!scriptReady || !signOutReady) return;
    const { unmount } = mountFirebaseAuthUi({
      element,
      onStateChange,
    });
    setMountReady(true);
    return () => unmount();
  }, [scriptReady, signOutReady]);

  return {
    isMountReady,
  };
};

const mountFirebaseAuthUi = ({
  element,
  onStateChange: onStateChangeOrigin,
}: {
  element: Element | string;
  onStateChange: (result: FireAuthState) => void;
}): { unmount: () => void } => {
  logger.log('mountFirebaseAuthUi mount');
  const app = getFirebaseApp();
  let onStateChangeRef = onStateChangeOrigin;
  const onAuthStateChanged = (user: FireUser | null) => {
    if (!user) {
      onStateChangeRef({ type: 'notSignIn' });
      return;
    }
    if (!user.emailVerified) {
      onStateChangeRef({ type: 'emailSending' });
      logger.log('firebase.sendEmailVerification');
      user
        .sendEmailVerification()
        .then(() => {
          const control = { abort };
          onStateChangeRef({ type: 'emailSent', control });
        })
        .catch(_catch);
      return;
    }
    user
      .getIdToken()
      .then((firebaseIdToken) => {
        onStateChangeRef({ type: 'verified', firebaseIdToken });
      })
      .catch(_catch);
  };
  const removeListener = app.auth().onAuthStateChanged(onAuthStateChanged);

  const unmount = () => {
    logger.log('mountFirebaseAuthUi unmount');
    onStateChangeRef = () => {};
    removeListener();
  };

  const firebaseuiMod = getFirebaseuiModule();
  const auth = getFirebaseApp().auth();
  auth.languageCode = 'ja';
  const ui =
    firebaseuiMod.auth.AuthUI.getInstance() ??
    new firebaseuiMod.auth.AuthUI(auth);
  ui.start(element, UI_CONFIG_BASE);

  const abort = () => {
    void app.auth().signOut();
    ui.reset();
    onStateChangeRef({ type: 'notSignIn' });
  };

  return {
    unmount,
  };
};

export type EmailVerifyCheckResult =
  | { firebaseIdToken: string }
  | 'notVerified';
type EmailVerifyCheckCallback = (result: EmailVerifyCheckResult) => void;
export const useFirebaseAuthEmailVerifyCheck = (
  _callback: EmailVerifyCheckCallback
) => {
  useEffect(() => {
    const onComplete = (result: EmailVerifyCheckResult) => {
      cleanUp();
      _callback(result);
    };

    const onAuthStateChanged = (user: FireUser | null) => {
      if (!user) return;
      if (!user.emailVerified) {
        return onComplete('notVerified');
      }

      const REFRESH_ID_TOKEN = true;
      user
        .getIdToken(REFRESH_ID_TOKEN) // 古い idToken は emailVerified が false なので、 refresh します。
        .then((firebaseIdToken) => {
          onComplete({ firebaseIdToken });
        })
        .catch(_catch);
    };
    const removeListener = getFirebaseApp()
      .auth()
      .onAuthStateChanged(onAuthStateChanged);

    const WAIT_UNTIL = 8000;
    const timer = setTimeout(() => {
      onComplete('notVerified');
    }, WAIT_UNTIL);

    const cleanUp = () => {
      removeListener();
      clearTimeout(timer);
    };

    return () => {
      cleanUp();
    };
  }, [_callback]);
};

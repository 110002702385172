import { Navigate } from 'react-router-dom';

import trainerApi from '../api/trainerApi';
import RegisterFormFields, {
  RegisterFormFieldValues,
} from '../components/domain/RegisterFormFields';
import { PrivacyLink, TermsLink } from '../components/domain/TermsAndPrivacy';
import { Row } from '../components/shared/Boxes';
import { ActionButton } from '../components/shared/Buttons';
import Spacing from '../components/shared/Spacing';
import { Caption } from '../components/shared/Typographies';
import FormContainer, {
  useFormContainer,
} from '../components/shared/forms/FormContainer';
import routes from '../config/routes';
import { useCallbackApiLoading } from '../hooks/apiHooks';
import { useAuthUser, useRefreshAuthUser } from '../hooks/authHooks';
import { useSetupLayout } from '../hooks/layoutHooks';

const RegisterPage = () => {
  useSetupLayout({
    title: 'プロフィール情報の登録',
    description: '飼い主様のアプリに表示する情報をご登録ください。',
  });
  const user = useAuthUser();
  if (!user.isRegisterNeeded) {
    return <Navigate to={routes.home} />;
  }

  const { form, onRegisterClick } = useService();

  return (
    <FormContainer value={form}>
      <Spacing />
      <RegisterFormFields />
      <Spacing />
      <ActionButton onClick={onRegisterClick} fullWidth>
        トレーナー情報を登録する
      </ActionButton>

      <Spacing m={2} />
      <Row>
        <Caption>
          ※ 続行すると、
          <TermsLink />
          と
          <PrivacyLink />
          に同意したことになります。
        </Caption>
      </Row>
    </FormContainer>
  );
};

export default RegisterPage;

const useService = () => {
  const form = useFormContainer<RegisterFormFieldValues>({
    defaultMode: 'input',
    defaultValues: {
      fullname: '',
      schoolName: '',
      schoolUrl: '',
      image: undefined,
    },
  });

  const { handleSubmit } = form;

  const refreshAuthUser = useRefreshAuthUser();
  const registerTrainer = useCallbackApiLoading(
    async (data: RegisterFormFieldValues, { apiCaller }) => {
      await apiCaller.call(
        trainerApi.registerTrainer({
          fullname: data.fullname,
          schoolName: data.schoolName,
          schoolUrl: data.schoolUrl,
          image: data.image,
        })
      );
      await refreshAuthUser();
    },
    [refreshAuthUser]
  );

  const onRegisterClick = handleSubmit(async (data) => {
    await registerTrainer(data);
  });

  return { form, onRegisterClick };
};

import { FC } from 'react';

import { Section } from '../../../components/shared/Boxes';
import { ActionButton } from '../../../components/shared/Buttons';
import Separator from '../../../components/shared/Separator';
import FormContainer, {
  useFormContainer,
} from '../../../components/shared/forms/FormContainer';
import FormVideo from '../../../components/shared/forms/FormVideo';
import validations from '../../../components/shared/forms/validations';
import { OnMovieCompletedProps } from '../../../contexts/CertifyCtx';
import InfoHint from '../InfoHint';

type Props = OnMovieCompletedProps;

type FormValues = {
  video?: File | Blob;
};

const MovieStepAttach: FC<Props> = ({ onCompleted }) => {
  const { form } = useService();
  const { handleSubmit } = form;

  const onSaveClick = handleSubmit(async (data) => {
    if (!data.video) return;
    onCompleted(data.video);
  });

  return (
    <>
      <Section>
        <FormContainer value={form}>
          <FormVideo
            name="video"
            label="認定動画"
            minHeight={145}
            validations={{ ...validations.required }}
          />
        </FormContainer>
      </Section>
      <ActionButton fullWidth onClick={onSaveClick}>
        次のステップ
      </ActionButton>
      <Separator />
      <InfoHint />
    </>
  );
};

export default MovieStepAttach;

const useService = () => {
  const form = useFormContainer<FormValues>({
    defaultMode: 'input',
  });

  return {
    form,
  };
};

import {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
  PropsWithChildren,
  FC,
} from 'react';
import useSound from 'use-sound';

export type SoundCtxType = {
  playingSrc: string | null;
  onStarted: (src: string) => void;
  onStopped: (src: string) => void;
  forceStop: () => void;
};

const Ctx = createContext<SoundCtxType>({
  playingSrc: null,
  onStarted: () => {},
  onStopped: () => {},
  forceStop: () => {},
});
export const SoundCtx = Ctx;
export const useSoundContext = () => useContext(Ctx);

export const useSoundCtxProviderValue = (): SoundCtxType => {
  const [playingSrc, setPlayingSrc] = useState<string | null>(null);

  const onStarted = useCallback((src: string) => {
    // logger.log('SoundCtx.onStarted', src);
    setPlayingSrc(src);
  }, []);
  const onStopped = useCallback(
    (src: string) => {
      // logger.log('SoundCtx.onStopped', src);
      if (src === playingSrc) setPlayingSrc(null);
    },
    [playingSrc]
  );
  const forceStop = useCallback(() => {
    // logger.log('SoundCtx.forceStop');
    setPlayingSrc(null);
  }, []);

  return {
    playingSrc,
    onStarted,
    onStopped,
    forceStop,
  };
};

export const useSoundSingle = (src: string) => {
  const { playingSrc, onStarted, onStopped } = useSoundContext();
  const [isPlaying, setPlaying] = useState(false);

  const onSoundStreamEnd = useCallback(() => {
    setPlaying(false);
    onStopped(src);
  }, [onStopped]);

  const [playSound, { stop: stopSound }] = useSound(src, {
    onend: onSoundStreamEnd,
  });

  const play = useCallback(() => {
    playSound();
    setPlaying(true);
    onStarted(src);
  }, [playSound, onStarted]);

  const stop = useCallback(() => {
    stopSound();
    setPlaying(false);
    onStopped(src);
  }, [playSound, onStopped]);

  const playAndStop = useCallback(() => {
    if (isPlaying) {
      stop();
    } else {
      play();
    }
  }, [src, stop, play, isPlaying]);

  useEffect(() => {
    if (isPlaying && playingSrc !== src) {
      stop();
    }
  }, [playingSrc, isPlaying]);

  // force stop on component unmounted
  useEffect(() => {
    return () => stopSound();
  }, [stopSound]);

  return {
    isPlaying,
    onSoundTriggered: playAndStop,
  };
};

export const SoundContainer: FC<PropsWithChildren> = ({ children }) => {
  const value = useSoundCtxProviderValue();
  return <SoundCtx.Provider value={value}>{children}</SoundCtx.Provider>;
};

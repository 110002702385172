/* eslint-disable @typescript-eslint/naming-convention */
import env from '../config/env';
import AssetMovie from '../types/AssetMovie';

export const __getDebugMockMovie = (): AssetMovie => {
  if (!env.ENABLE_DEBUG_MOVIE_SKIP) throw new Error('');
  return {
    id: 'mockAssetId',
    url: 'https://pets-upload-dev.s3.ap-northeast-1.amazonaws.com/movies/movie_tmp/movie/2447/encoded/20e5e50c-070e-48d1-856d-46783bf0332e_1280x720.mp4',
  };
};

import { formatInTimeZone } from 'date-fns-tz';

export const toDateTimeFormat = (date: Date | string | undefined): string => {
  if (!date) return '~';

  if (typeof date === 'string') {
    return toDateTimeFormat(new Date(date));
  }
  try {
    return formatInTimeZone(date, 'JST', 'yyyy年MM月dd日 HH:mm');
  } catch (err) {
    console.error(err);
    return '~';
  }
};

export const toDateTimeSigunatureFormat = (date: Date): string =>
  formatInTimeZone(date, 'JST', 'yyyyMMddHHmm');

export const toCalendarFormat = (date: Date | string | undefined): string => {
  if (!date) return '~';

  if (typeof date === 'string') {
    return toCalendarFormat(new Date(date));
  }
  try {
    return formatInTimeZone(date, 'JST', 'yyyy年MM月dd日');
  } catch (err) {
    console.error(err);
    return '~';
  }
};

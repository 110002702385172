import env from '../config/env';
import AssetApiAccessInfo from '../types/AssetApiAccessInfo';
import ApiServer from './ApiServer';
import AssetServer from './AssetServer';

type ApiCallCtxCommon = {
  backend: ApiServer;
};
type NoAuthApiCallCtx = ApiCallCtxCommon & {};
type AuthApiCallCtx = ApiCallCtxCommon & {
  asset: AssetServer; // getMe 以降で defined です（ログイン時や getMe 時は null）。
};

type ApiCallee<Ctx, Res> = (ctx: Ctx) => Promise<Res>;
type ApiDefinition<Ctx, Req, Res> = (req: Req) => ApiCallee<Ctx, Res>;

export type NoAuthApiDefinition<Req = void, Res = void> = ApiDefinition<
  NoAuthApiCallCtx,
  Req,
  Res
>;
export type AuthApiDefinition<Req = void, Res = void> = ApiDefinition<
  AuthApiCallCtx,
  Req,
  Res
>;

export type ApiCaller = {
  noAuthCall: <Res>(
    callee: (ctx: NoAuthApiCallCtx) => Promise<Res>
  ) => Promise<Res>;
  call: <Res>(callee: (ctx: AuthApiCallCtx) => Promise<Res>) => Promise<Res>;
};

export const createApiCaller = ({
  bearer,
  assetAccessInfo,
}: {
  bearer: string | null;
  assetAccessInfo: AssetApiAccessInfo | null;
}): ApiCaller => {
  const call: ApiCaller['call'] = async <Res>(
    callee: ApiCallee<AuthApiCallCtx, Res>
  ) => {
    if (!bearer)
      throw new Error(
        'Can not call AuthApiCall because you are not authenticated.'
      );
    const ctx = createAuthApiCtx({ bearer, assetAccessInfo });
    const res = await callee(ctx);
    // TODO: unauthenticated エラーで、 LocalStore.onSignOut() して、ページ reload 。
    return res;
  };

  const noAuthCall: ApiCaller['noAuthCall'] = async <Res>(
    callee: ApiCallee<NoAuthApiCallCtx, Res>
  ) => {
    const ctx = createNoAuthApiCtx();
    const res = await callee(ctx);
    return res;
  };

  return {
    call,
    noAuthCall,
  };
};

const createAuthApiCtx = ({
  bearer,
  assetAccessInfo,
}: {
  bearer: string;
  assetAccessInfo: AssetApiAccessInfo | null;
}): AuthApiCallCtx => {
  const backend = new ApiServer({
    urlBase: env.API_BASE,
    headers: {
      Authorization: bearer,
    },
  });
  const asset = assetAccessInfo
    ? new AssetServer(assetAccessInfo)
    : (null as any as AssetServer);

  return {
    backend,
    asset,
  };
};

const createNoAuthApiCtx = (): NoAuthApiCallCtx => {
  const backend = new ApiServer({
    urlBase: env.API_BASE,
  });
  return {
    backend,
  };
};

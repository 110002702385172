import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type CtxType = {
  title?: string | undefined;
  setTitle: (title?: string) => void;
  description?: string | undefined;
  setDescription: (description?: string) => void;
};

const Ctx = createContext<CtxType>({
  setTitle: () => {},
  setDescription: () => {},
});
export const LayoutCtx = Ctx;

export const useLayoutCtx = () => useContext(Ctx);

export const useLayoutCtxProviderValue = (): CtxType => {
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const location = useLocation();
  useEffect(() => {
    // reset when page unmounted
    return () => {
      setTitle(undefined);
      setDescription(undefined);
    };
  }, [location.pathname]);
  return {
    title,
    setTitle,
    description,
    setDescription,
  };
};

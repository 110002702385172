import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { getDisplayMessageFromAxiosError } from '../api/apiParser';
import LoadingOverlay from '../components/shared/LoadingOverlay';
import Toast, { ToastProps } from '../components/shared/Toast';
import logger from '../config/logger';

type CtxToastProps = ToastProps & {
  createdAt: Date;
};

type StateCtxType = {
  isLoading: boolean | string; // string はローディング表示のラベル
  toasts: CtxToastProps[];
};

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type StartLoading<R = void> = (
  process: () => Promise<R>,
  options?: StartLoadingOptions<R>
) => Promise<R | undefined>;

type MessageOption<T> = ((arg: T) => string | null) | string | null;
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type StartLoadingOptions<R = void> = {
  loadingLabel?: string;
  errorMsg?: MessageOption<R>;
  successMsg?: MessageOption<any>;
};

export type AppCtxToast = (message: string) => void;
const TOAST_ERROR_HIDE_DURATION = 60 * 1000;

type SetterCtxType = {
  setLoading: (isLoading: boolean | string) => void;
  toastSuccess: AppCtxToast;
  toastError: AppCtxToast;
  startLoading: StartLoading;
};

const _StateCtx = createContext<StateCtxType>({
  isLoading: false,
  toasts: [],
});

const _SetterCtx = createContext<SetterCtxType>({
  setLoading: () => {},
  toastSuccess: () => {},
  toastError: () => {},
  startLoading: async <R,>(process: () => Promise<R>) => await process(),
});

export const AppCtxProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean | string>(false);
  const [toasts, setToasts] = useState<CtxToastProps[]>([]);

  const stateValue = useMemo(
    () => ({
      isLoading,
      toasts,
    }),
    [isLoading, toasts]
  );
  const setterValue = useMemo<SetterCtxType>(
    () => createSetter(setLoading, setToasts),
    []
  );

  return (
    <_StateCtx.Provider value={stateValue}>
      <_SetterCtx.Provider value={setterValue}>{children}</_SetterCtx.Provider>
    </_StateCtx.Provider>
  );
};

const useAppCtx = () => useContext(_StateCtx);
export const useAppSetterCtx = () => useContext(_SetterCtx);

export const AppCtxView: FC = () => {
  const { isLoading, toasts } = useAppCtx();
  return (
    <>
      {toasts.map(({ createdAt, ...props }) => (
        <Toast key={createdAt.getTime()} {...props} />
      ))}
      {isLoading && (
        <LoadingOverlay
          label={typeof isLoading === 'string' ? isLoading : undefined}
        />
      )}
    </>
  );
};

const SUCCESS_TOAST_HIDE_DURATION = 5000;

const createSetter = (
  setLoading: (isLoading: boolean | string) => void,
  setToasts: (toasts: CtxToastProps[]) => void
): SetterCtxType => {
  const toastSuccess: SetterCtxType['toastSuccess'] = (message) =>
    setToasts([
      {
        type: 'success',
        message,
        autoHideDuration: SUCCESS_TOAST_HIDE_DURATION,
        createdAt: new Date(),
      },
    ]);

  const toastError: SetterCtxType['toastError'] = (message) =>
    setToasts([
      {
        type: 'error',
        message,
        autoHideDuration: TOAST_ERROR_HIDE_DURATION,
        createdAt: new Date(),
      },
    ]);

  const startLoading: SetterCtxType['startLoading'] = async (
    process,
    opts = {}
  ) => {
    setLoading(opts.loadingLabel ?? true);
    setToasts([]);
    try {
      const result = await process();
      const message = getMessageByOption(
        opts.successMsg,
        result,
        '成功しました。'
      );
      if (message) toastSuccess(message);
      return result;
    } catch (error) {
      logger.error(error);
      const message = getMessageByOption(
        opts.errorMsg,
        error as any,
        getDisplayMessageFromAxiosError(error) ?? '失敗しました。'
      );
      if (message) toastError(message);
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  return {
    setLoading,
    toastSuccess,
    toastError,
    startLoading,
  };
};

const getMessageByOption = <T = any,>(
  option: MessageOption<T> | undefined,
  data: T,
  defaultMessage: string
): string | null => {
  if (option === null) return null;
  if (option === undefined) return defaultMessage;
  if (typeof option === 'string') return option;
  const message = option(data);
  if (message === null) return null;
  return message;
};

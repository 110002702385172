import { Pagination } from '@mui/material';
import { useState, ChangeEvent, useCallback } from 'react';

import trainerApi from '../api/trainerApi';
import { CertificateCard } from '../components/domain/CertificateCard';
import { Section } from '../components/shared/Boxes';
import { Title } from '../components/shared/Typographies';
import { useApiCaller, useEffectApiLoading } from '../hooks/apiHooks';
import { useSetupLayout } from '../hooks/layoutHooks';
import TrainerHistoryItem from '../types/TrainerHistoryItem';

const HistoryListPage = () => {
  useSetupLayout({ title: '認定履歴' });
  const [histories, setHistories] = useState<TrainerHistoryItem[]>();
  const [pageNumber, setPageNumber] = useState<number>(0); // starts with 0
  const [count, setCount] = useState<number>();
  const apiCaller = useApiCaller();

  const onPageChange = useCallback(
    (event: ChangeEvent<unknown>, value: number) => {
      setPageNumber(value - 1);
    },
    []
  );

  useEffectApiLoading(async () => {
    const {
      trainerHistories,
      paginate: { page, count, limit },
    } = await apiCaller.call(
      trainerApi.getTrainerHistories({ page: pageNumber })
    );
    setHistories(trainerHistories);
    setPageNumber(page);
    const pageCount = Math.ceil(count / limit);
    setCount(pageCount);
  }, [pageNumber]);

  if (histories === undefined) return <></>;
  if (histories.length === 0) return <Title center>No List Items</Title>;

  return (
    <>
      {histories.map(({ info, pet, certificate }) => (
        <Section key={certificate.id}>
          <CertificateCard info={info} pet={pet} certificate={certificate} />
        </Section>
      ))}
      <Section flexCenter>
        <Pagination
          count={count}
          page={pageNumber + 1} // starts with 1
          onChange={onPageChange}
        />
      </Section>
    </>
  );
};

export default HistoryListPage;

import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  Icon,
} from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type CustomOnClick = (...args: any[]) => Promise<void>;

type Props = PropsWithChildren &
  Omit<MUIButtonProps, 'onClick'> & {
    icon?: string; // startIcon name e.g. "login"
    onClick?: MUIButtonProps['onClick'] | CustomOnClick;
    target?: string; // In order to complement MUIButtonProps
  };

const Base: FC<Props> = ({ icon, href, target, ...props }) => {
  const startIcon = icon ? <Icon>{icon}</Icon> : undefined;
  return <MUIButton {...{ href, target, startIcon }} {...props} />;
};

const MainButton: FC<Props> = ({ children, ...rest }) => {
  return (
    <Base variant="contained" color="primary" {...rest}>
      {children}
    </Base>
  );
};

const SubButton: FC<Props> = ({ children, ...rest }) => {
  return (
    <Base variant="contained" color="secondary" {...rest}>
      {children}
    </Base>
  );
};

// skelton
const SkelButton: FC<Props> = ({ children, ...rest }) => {
  return (
    <Base variant="outlined" color="secondary" {...rest}>
      {children}
    </Base>
  );
};

const TextButton: FC<Props> = ({ children, ...rest }) => {
  return (
    <Base variant="text" color="secondary" {...rest}>
      {children}
    </Base>
  );
};

export const TextLink: FC<Props> = ({ children, sx = {}, ...rest }) => {
  return (
    <TextButton sx={{ p: 0, ...sx }} {...rest}>
      {children}
    </TextButton>
  );
};

export const TransitButton = MainButton;
export const ActionButton = SubButton;
export const SecondActionButton = SkelButton;
export const CancelButton = TextButton;
export const LinkButton = TextButton;

import { FC } from 'react';

type Props = {
  youtubeId: string;
};
const Youtube: FC<Props> = ({ youtubeId }) => {
  const url = `https://www.youtube.com/embed/${youtubeId}`;
  return (
    <>
      <iframe
        width="100%"
        style={{ maxWidth: 500, aspectRatio: '16 / 9' }}
        frameBorder={0}
        src={url}
      />
    </>
  );
};

export default Youtube;

import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

const FormFieldRow: FC<PropsWithChildren> = ({ children }) => (
  <Box sx={{ mt: 2, mb: 2 }}>{children}</Box>
);

export default FormFieldRow;

export const wrapByFormFieldRow = <Props extends {}>(
  Component: FC<Props>
): FC<Props> =>
  function wrapByFormFieldRow(props: Props) {
    return (
      <FormFieldRow>
        <Component {...props} />
      </FormFieldRow>
    );
  };

import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useState } from 'react';

import { ActionButton, TextLink } from '../../components/shared/Buttons';
import {
  BodySub,
  CautionBody,
  ErrorBody,
} from '../../components/shared/Typographies';
import { APP_TRAINER_URL } from '../../config/constants';

const BeforeCertifyAlert = () => {
  const [open, setOpen] = useState(true);

  const handleOpenAppli = () => {
    window.open(APP_TRAINER_URL, 'wanpass-app-trainer');
  };
  const handleClose = () => setOpen(false);

  if (!open) return null;

  return (
    <Dialog open onAbort={handleClose}>
      <DialogTitle>
        <ErrorBody>
          <b>⚠️アラート⚠️</b>
        </ErrorBody>
      </DialogTitle>
      <DialogContent>
        <Stack>
          現在開いている画面は、Webブラウザ版の認定画面です。
          Webブラウザ版での認定は推奨されません。
        </Stack>
        <Stack sx={{ marginTop: 2 }}>
          <CautionBody>
            認定はWan!Passアプリの認定士の画面から行ってください。
          </CautionBody>
        </Stack>
        <Stack sx={{ marginTop: 2 }}>
          <ActionButton onClick={handleOpenAppli} fullWidth>
            Wan!Passアプリから認定する
          </ActionButton>
        </Stack>
        <Stack sx={{ marginTop: 2 }}>
          <BodySub>
            どうしてもWebブラウザ版で認定する場合は
            <TextLink
              onClick={handleClose}
              sx={{ textDecoration: 'underline' }}
            >
              こちらをタップ
            </TextLink>
          </BodySub>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default BeforeCertifyAlert;

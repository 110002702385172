import { Box, BoxProps, Icon } from '@mui/material';
import {
  useRef,
  FC,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
  SyntheticEvent,
} from 'react';

import { dataResourceToUrl } from '../../../types/shared/DataResource';
import { Row } from '../Boxes';
import { Caption, ErrorBody } from '../Typographies';
import { FormFieldExtendProps, useFormContainerCtx } from './FormContainer';

type Props = FormFieldExtendProps &
  BoxProps & {
    width: number | string;
    height: number | string;
    round: boolean;
    useCover: boolean;
  };

const IMAGE_MIN_HEIGHT = 145;
const FormImageEditMode: FC<Props> = ({
  name,
  label,
  validations,
  width,
  height,
  round,
  useCover,
  ...rest
}) => {
  const { getState, setValues, register } = useFormContainerCtx();
  const { value, error } = getState(name);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // saved image
    if (typeof value?.url === 'string' && value.url.length > 0) {
      setImageUrl(value.url);
    }
  }, [value]);

  const onFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;

      const item = e.target.files.item(0);
      if (item) {
        setValues({ [name]: item });
        // upload file
        const url = dataResourceToUrl(item);
        setImageUrl(url);
        if (e.target.value) {
          e.target.value = '';
        }
      }
    },
    [name]
  );

  const openFileSelector = useCallback(() => {
    inputFileRef?.current?.click();
  }, []);

  const onRemoveClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setValues({ [name]: null });
      setImageUrl(null);
    },
    [name]
  );

  return (
    <>
      <Row>
        <Caption color={error && 'error'}>{label}</Caption>
      </Row>
      <Box position="relative" {...rest}>
        <Box
          border={1}
          sx={{
            border: 1,
            width,
            height,
            minHeight: IMAGE_MIN_HEIGHT,
            borderRadius: round ? '50%' : '0',
            cursor: 'pointer',
            ...(imageUrl
              ? {
                  backgroundSize: useCover ? 'cover' : 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundImage: `url(${imageUrl})`,
                }
              : {}),
          }}
          onClick={openFileSelector}
        >
          {imageUrl && (
            <Box position="relative">
              <Icon
                sx={{ cursor: 'pointer', position: 'absolute' }}
                onClick={onRemoveClick}
              >
                clear
              </Icon>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            {!imageUrl && (
              <Row sx={{ p: round ? 4 : 0 }}>
                ここをタップして{label ?? '画像'}を選択してください。
              </Row>
            )}
          </Box>
        </Box>
      </Box>
      {error && (
        <Box>
          <ErrorBody>{error}</ErrorBody>
        </Box>
      )}
      <input
        type="file"
        hidden
        accept="image/*"
        {...register(name, validations)}
        ref={inputFileRef}
        onChange={onFileChange}
      />
    </>
  );
};

export default FormImageEditMode;

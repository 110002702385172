import { useCallback, useRef, useState } from 'react';

import { AuthApiDefinition } from '../api/ApiCaller';
import assetApi from '../api/assetApi';
import { Row, Section } from '../components/shared/Boxes';
import DebugMessage from '../components/shared/DebugMessage';
import { Caption, Label, Title } from '../components/shared/Typographies';
import { useCallbackApiLoading } from '../hooks/apiHooks';
import { useAuthUser } from '../hooks/authHooks';
import { useSetupLayout } from '../hooks/layoutHooks';
import { useCallbackAsync } from '../hooks/utilHooks';
import AssetImage from '../types/AssetImage';
import AssetMovie from '../types/AssetMovie';

const DebugPage = () => {
  useSetupLayout({ title: 'Debug Page' });
  const { assetAccessInfo } = useAuthUser();

  return (
    <>
      <Section>
        <Title>Asset Access Info</Title>
        <DebugMessage data={assetAccessInfo} />
      </Section>
      <Section>
        <Title>Image Upload Test</Title>
        <ImageUploadTest />
      </Section>
      <Section>
        <Title>Movie Upload Test</Title>
        <MovieUploadTest />
      </Section>
    </>
  );
};

export default DebugPage;

const FileUploadTest = <AssetType,>({
  accept,
  uploadApi,
}: {
  accept: string;
  uploadApi: (file: File) => ReturnType<AuthApiDefinition<any, AssetType>>;
}) => {
  const [asset, setAsset] = useState<AssetType | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadFile = useCallbackApiLoading(
    async (file: File, { apiCaller }) => {
      const newAsset = await apiCaller.call(uploadApi(file));
      console.log(newAsset);
      setAsset(newAsset);
    },
    []
  );
  const onFileInputChange = useCallbackAsync(async (event: any) => {
    const newFile = event.target.files[0];
    await uploadFile(newFile);
  }, []);

  return (
    <>
      <Row>
        <input
          ref={inputRef}
          accept={accept}
          type="file"
          onChange={onFileInputChange}
        />
      </Row>
      <Row>
        <Caption>
          * Select file then upload starts(Select another file to re-upload).
        </Caption>
      </Row>
      {asset && (
        <Row>
          <Label>Result</Label>
          <DebugMessage data={asset} />
        </Row>
      )}
    </>
  );
};

const ImageUploadTest = () => (
  <FileUploadTest<AssetImage>
    accept="image/*"
    uploadApi={useCallback((file: File) => assetApi.uploadImage({ file }), [])}
  />
);

const MovieUploadTest = () => (
  <FileUploadTest<AssetMovie>
    accept="video/*"
    uploadApi={useCallback((file: File) => assetApi.uploadMovie({ file }), [])}
  />
);

import { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './AppRoutes';
import StagingFirewall from './components/shared/StagingFirewall';
import env from './config/env';
import { AppCtxProvider, AppCtxView } from './contexts/AppCtx';
import {
  AuthCtxProvider,
  AuthCtxReadyWaitView,
  AuthCtxSetupView,
} from './contexts/AuthCtx';
import { SoundContainer } from './contexts/SoundCtx';
import AppTheme from './layouts/AppTheme';
import Head from './layouts/Head';

const App: FC = () => {
  return (
    <AppGuard>
      <Head />
      <AppTheme>
        <Providers>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </Providers>
      </AppTheme>
    </AppGuard>
  );
};

export default App;

const Providers: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <AppCtxProvider>
      <SoundContainer>
        <AppCtxView />
        <AuthCtxProvider>
          <AuthCtxSetupView />
          <AuthCtxReadyWaitView>{children}</AuthCtxReadyWaitView>
        </AuthCtxProvider>
      </SoundContainer>
    </AppCtxProvider>
  );
};

const AppGuard: FC<PropsWithChildren> = ({ children }) => {
  if (env.STAGING_PASSWORDS)
    return <StagingFirewall>{children}</StagingFirewall>;

  return <>{children}</>;
};

import { Chip, Icon } from '@mui/material';

import { CERTIFY_ICON } from '../../config/icons';
import { ResultType } from '../../types/Certificate';

/**
 * Certificate's ResultType view.
 * PASS OR FAIL
 */
const ResultLabel = ({ type }: { type: ResultType | null }) => {
  const [label, iconType] = type ? FLAVORS[type] : NULL_FLAVOR;

  const iconView = iconType ? <Icon>{iconType}</Icon> : undefined;

  return <Chip icon={iconView} label={label} />;
};
export default ResultLabel;

type Flavor = [string, string | null];

const FLAVORS: { [k in ResultType]: Flavor } = {
  pass: ['合格', CERTIFY_ICON],
  fail: ['不合格', null],
};

const NULL_FLAVOR: Flavor = ['未受験', null];

import { FC } from 'react';

import { TrainingInfoDetailTrigger } from '../../components/domain/TrainingInfoDetail';
import { useCertifyCtx } from '../../contexts/CertifyCtx';

const InfoHint: FC = () => {
  const {
    values: { info },
  } = useCertifyCtx();
  if (!info) return null;

  return <TrainingInfoDetailTrigger info={info} />;
};

export default InfoHint;

import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import RecorderApi, {
  CheckError,
  CheckResult,
  IRecorderApi,
} from '../../../services/RecorderApi';
import { Row } from '../../shared/Boxes';
import { ActionButton, CancelButton } from '../../shared/Buttons';
import { DebugMessageTrigger } from '../../shared/DebugMessage';
import Field from '../../shared/Field';
import FullScreenDialog from '../../shared/FullScreenDialog';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { Caption, ErrorBody } from '../../shared/Typographies';
import VideoAuthorityHelp from '../VideoAuthorityHelp';

type Props = PropsWithChildren & {
  onCancel: () => void;
  _mockRecorderApi?: IRecorderApi;
};

const VideoRecorderAuthorityGuard: FC<Props> = ({
  children,
  onCancel,
  _mockRecorderApi,
}) => {
  const { checkResult, onCheckAgainClick } = useService({
    _mockRecorderApi,
  });

  if (checkResult === undefined)
    return <LoadingOverlay label="撮影権限を確認中です" />;
  if (checkResult.status === 'ok') return <>{children}</>;

  return (
    <FullScreenDialog>
      <DialogTitle>動画撮影の権限に問題があります</DialogTitle>
      <DialogContent>
        <NgContent errors={checkResult.errors} />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <ActionButton onClick={onCheckAgainClick}>
          もう一度権限を確認する
        </ActionButton>
      </DialogActions>
    </FullScreenDialog>
  );
};

export default VideoRecorderAuthorityGuard;

const useService = ({
  _mockRecorderApi,
}: {
  _mockRecorderApi?: IRecorderApi;
}) => {
  const recorderApi = useMemo<IRecorderApi>(
    () => _mockRecorderApi ?? new RecorderApi(),
    [_mockRecorderApi]
  );
  const [checkAt, setCheckAt] = useState(new Date());
  const [checkResult, setCheckResult] = useState<CheckResult | undefined>(
    undefined
  );
  useEffect(() => {
    let waiting = true;
    setCheckResult(undefined);
    recorderApi
      .check()
      .then((newCheckResult) => {
        if (!waiting) return;
        setCheckResult(newCheckResult);
      })
      .catch(console.error);
    return () => {
      waiting = false;
    };
  }, [recorderApi, checkAt.getTime()]);
  const onCheckAgainClick = useCallback(() => setCheckAt(new Date()), []);

  return {
    checkResult,
    onCheckAgainClick,
  };
};

const NgContent = ({ errors }: { errors: CheckError[] }) => {
  return (
    <>
      <Field>以下の理由によりブラウザから撮影ができない状態にあります。</Field>
      <Field>
        <ErrorBody>{errors.map((error) => error.message).join()}</ErrorBody>
      </Field>
      <Field>
        お使いのスマートフォンの設定を見直してから、再度権限を確認してください。
      </Field>
      <Field>
        <VideoAuthorityHelp />
        <Row>
          <DebugMessageTrigger
            data={errors
              .map((error) => errorToString(error.object))
              .join('\n\n')}
          />
        </Row>
      </Field>
      <Caption>
        ※
        上記の設定をしてもうまくいかない場合は、あらかじめカメラアプリで撮影をした動画を添付する方法もあります。添付をご利用する場合は「キャンセル」を押して前の画面にお戻りください。
      </Caption>
    </>
  );
};

const errorToString = (e: Error) => {
  return `${e.message}\n${e.stack ?? ''}`;
};

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useCallback, useState } from 'react';

import Certificate from '../../types/Certificate';
import Pet from '../../types/Pet';
import TrainingInfo from '../../types/TrainingInfo';
import { toDateTimeFormat } from '../../utils/dateFormat';
import { Section } from '../shared/Boxes';
import { ActionButton } from '../shared/Buttons';
import Field from '../shared/Field';
import Manuscript from '../shared/Manuscript';
import { Caption, Label } from '../shared/Typographies';
import VideoPreviewer from '../shared/VideoPreviewer';
import { PetCard } from './PetCard';
import ResultLabel from './ResultLabel';
import { TrainingInfoDetailTrigger } from './TrainingInfoDetail';

export const CertificateDetail = ({
  info,
  certificate,
  pet,
}: {
  info: TrainingInfo;
  certificate: Certificate;
  pet: Pet;
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const togglePreviewOpen = useCallback(
    () => setPreviewOpen((wasOpen) => !wasOpen),
    []
  );

  return (
    <>
      <Field caption="認定時刻">
        {toDateTimeFormat(certificate.certifiedAt)}
      </Field>
      <Field>
        <Caption>ワンちゃんの情報</Caption>
        <PetCard pet={pet} owner={null} />
      </Field>
      <Field caption="認定試験名">
        <Label>{info.title}</Label>
        <Caption>{info.digest}</Caption>
      </Field>
      <Field caption="結果">
        <ResultLabel type={certificate.resultType} />
      </Field>
      <Field caption="コメント">
        <Manuscript pre>{certificate.comment}</Manuscript>
      </Field>
      <Section>
        <ActionButton onClick={togglePreviewOpen} fullWidth icon="date_range">
          撮影動画を確認
        </ActionButton>
      </Section>
      <Section last>
        <TrainingInfoDetailTrigger info={info} />
      </Section>
      <Dialog open={previewOpen} onClose={togglePreviewOpen}>
        <DialogTitle>撮影動画を確認</DialogTitle>
        <DialogContent>
          <VideoPreviewer src={certificate.movie.url} />
        </DialogContent>
        <DialogActions>
          <ActionButton onClick={togglePreviewOpen} color="primary">
            閉じる
          </ActionButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { Card, CardContent } from '@mui/material';

import { ResultType } from '../../types/Certificate';
import TrainingInfo from '../../types/TrainingInfo';
import { SectionRow } from '../shared/Boxes';
import { TransitButton } from '../shared/Buttons';
import { Space } from '../shared/Spacing';
import { Label, Pre, Title } from '../shared/Typographies';
import ResultLabel from './ResultLabel';
import TrainingGenreTags from './TrainingInfoGenreTags';

export const TrainingInfoCard = ({
  info,
  resultType,
  actionLabel,
  onActionClick,
}: {
  info: TrainingInfo;
  resultType?: ResultType | null;
  actionLabel: string;
  onActionClick: (info: TrainingInfo) => void;
}) => {
  return (
    <Card>
      <CardContent>
        <SectionRow>
          <Title>
            {info.title}
            {resultType !== undefined ? (
              <Space>
                <ResultLabel type={resultType} />
              </Space>
            ) : undefined}
          </Title>
        </SectionRow>
        <SectionRow>
          <TrainingGenreTags genres={info.genres} />
        </SectionRow>
        <SectionRow>
          <Label>認定内容</Label>
        </SectionRow>
        <SectionRow>
          <Pre variant="caption">{info.digest}</Pre>
        </SectionRow>
        <SectionRow alignRight last>
          <TransitButton onClick={() => onActionClick(info)}>
            {actionLabel}
          </TransitButton>
        </SectionRow>
      </CardContent>
    </Card>
  );
};

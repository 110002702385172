import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

type BranchCallbacks<T extends string> = {
  [k in T]: () => void;
};
type BranchProps<T> = PropsWithChildren & { name: T };

export const useBranches = <T extends string>(names: T[]) => {
  const [branch, setBranch] = useState<T | undefined>(undefined);
  const toggleBranch: BranchCallbacks<T> = useMemo(
    () =>
      names.reduce<any>((obj, branch) => {
        obj[branch] = () => setBranch(branch);
        return obj;
      }, {}),
    [...names]
  );
  const Branch: FC<BranchProps<T>> = useMemo(
    () =>
      function Branch({ name, children }: BranchProps<T>) {
        if (name !== branch) return null;
        return <>{children}</>;
      },
    [branch]
  );
  const BranchDefault: FC<PropsWithChildren> = useMemo(
    () =>
      function BranchDefault({ children }: PropsWithChildren) {
        if (branch !== undefined) return null;
        return <>{children}</>;
      },
    [branch]
  );
  const resetBranch = useCallback(() => setBranch(undefined), []);

  return {
    Branch,
    BranchDefault,
    toggleBranch,
    resetBranch,
    branch,
  };
};

import { FC } from 'react';

import { getAndroidHelpImage } from '../../config/assets';
import Spacing from '../shared/Spacing';
import { LabelTitle, Title } from '../shared/Typographies';
import VideoAuthorityHelpImageItem from './VideoAuthorityHelpImageItem';

const VideoAuthorityHelpAndroid = () => {
  return (
    <>
      <Title>Android の設定方法</Title>
      「Chrome の権限設定」と、「サイトごとの設定」の二つの権限設定が必要です。
      <LabelTitle>Chromeの権限設定</LabelTitle>
      👇 Androidの「設定」を開きます。
      <ChromeImg i={1} />
      👇 「アプリ」を選択します。
      <ChromeImg i={2} />
      👇 「Chrome」を選択します。
      <ChromeImg i={3} />
      👇 「権限」を選択します。
      <ChromeImg i={4} />
      👇
      「カメラ」「マイク」を順にひらいて、「アプリの使用中のみ許可」に設定します。
      <ChromeImg i={5} />
      <ChromeImg i={6} />
      <ChromeImg i={7} />
      「Chromeの権限設定」は以上で終了です。
      <Spacing />
      <LabelTitle>サイトごとの設定</LabelTitle>
      👇 Chrome を起動し、右上の三点マーク「<b>︙</b>」をタップします。
      <SiteImg i={1} />
      👇 「設定」を選択します。
      <SiteImg i={2} />
      👇 「サイトの設定」を選択します。
      <SiteImg i={3} />
      👇 「カメラ」「マイク」をそれぞれ開き、以下を確認してください。
      <SiteImg i={4} />
      👇 この画面に https://trainer.wanpass.me があるかどうかご確認ください。
      <SiteImg i={5} />
      この画面に https://trainer.wanpass.me がない場合は、設定は不要です。
      もし「ブロック」の場所にある場合、カメラ・マイクが許可されていません。
      タップして、「許可する」に権限変更してください（カメラ・マイクでそれぞれ許可します）。
      <SiteImg i={6} />
      以上で、「サイトごとの設定」は完了です。
    </>
  );
};
export default VideoAuthorityHelpAndroid;

const ChromeImg: FC<{ i: number }> = ({ i }) => (
  <VideoAuthorityHelpImageItem src={getAndroidHelpImage('chrome', i)} />
);

const SiteImg: FC<{ i: number }> = ({ i }) => (
  <VideoAuthorityHelpImageItem src={getAndroidHelpImage('site', i)} />
);

import { FC, PropsWithChildren } from 'react';

import { Row } from '../Boxes';
import { Caption, DisabledBody } from '../Typographies';

const SimpleViewMode: FC<PropsWithChildren<{ label?: string }>> = ({
  label,
  children,
}) => {
  const isChildrenBlank = !children;
  return (
    <Row>
      {label && (
        <Row>
          <Caption>{label}</Caption>
        </Row>
      )}
      <Row>{isChildrenBlank ? <Blank /> : children}</Row>
    </Row>
  );
};

export default SimpleViewMode;

const Blank = () => <DisabledBody>（なし）</DisabledBody>;

import { Dialog } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import MobileFirstWidth from '../../layouts/MobileFirstWidth';

type Props = PropsWithChildren;
const FullScreenDialog: FC<Props> = ({ children }) => {
  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
    >
      <MobileFirstWidth>{children}</MobileFirstWidth>
    </Dialog>
  );
};

export default FullScreenDialog;

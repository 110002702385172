import { Card, CardContent, Autocomplete, TextField } from '@mui/material';
import { useState, useCallback, useEffect, SyntheticEvent } from 'react';

import petApi from '../../api/petApi';
import { PetCard } from '../../components/domain/PetCard';
import { TrainingInfoCard } from '../../components/domain/TrainingInfoCard';
import { TrainingInfoDetail } from '../../components/domain/TrainingInfoDetail';
import { FlexColumn, Section } from '../../components/shared/Boxes';
import {
  SecondActionButton,
  TransitButton,
} from '../../components/shared/Buttons';
import Spacing from '../../components/shared/Spacing';
import {
  Title,
  SubTitle,
  Caption,
  Body,
  CautionBody,
} from '../../components/shared/Typographies';
import { useCertifyCtx } from '../../contexts/CertifyCtx';
import { useEffectApiLoading } from '../../hooks/apiHooks';
import { useResetScrollEffect } from '../../hooks/utilHooks';
import Pet from '../../types/Pet';
import PetTrainingItem from '../../types/PetTrainingItem';
import { TrainingGenre } from '../../types/TrainingGenre';
import TrainingInfo, {
  collectGenresFromTrainingInfos,
} from '../../types/TrainingInfo';
import { CertifyDescription, CertifyTitle } from './CertifyLayout';

const InfoStep = () => {
  const {
    onSelected,
    info,
    petItems,
    trainingGenres,
    onBackClick,
    onNextClick,
    pet,
  } = useService();
  useResetScrollEffect([info]);

  return (
    <>
      <CertifyTitle>試験の選択</CertifyTitle>
      {info === undefined ? (
        <SelectView
          trainingGenres={trainingGenres}
          petItems={petItems}
          onSelected={onSelected}
        />
      ) : (
        <ConfirmView
          pet={pet}
          info={info}
          onBackClick={onBackClick}
          onNextClick={onNextClick}
        />
      )}
    </>
  );
};
export default InfoStep;

const useService = () => {
  const [info, setInfo] = useState<TrainingInfo | undefined>(undefined);

  const onSelected = useCallback((info: TrainingInfo) => setInfo(info), []);
  const onBackClick = useCallback(() => setInfo(undefined), []);

  const {
    values: { pet, oneTimeCode },
    updateValues,
  } = useCertifyCtx('info');
  if (!pet) throw new Error();

  const [petItems, setPetItems] = useState<PetTrainingItem[]>([]);
  const [trainingGenres, setTrainingGenres] = useState<TrainingGenre[]>([]);

  useEffectApiLoading(async ({ apiCaller }) => {
    if (oneTimeCode) {
      const petItems = await apiCaller.call(petApi.getItems({ oneTimeCode }));
      const petTrainingInfos = petItems.map((item) => item.info);
      const genres = collectGenresFromTrainingInfos(petTrainingInfos);
      setPetItems(petItems);
      setTrainingGenres(genres);
    }
  }, []);

  const onNextClick = useCallback(() => {
    updateValues({ info });
  }, [info, updateValues]);

  return {
    pet,
    info,
    petItems,
    trainingGenres,
    onSelected,
    onBackClick,
    onNextClick,
  };
};

const SelectView = ({
  petItems,
  onSelected,
  trainingGenres,
}: {
  trainingGenres: TrainingGenre[];
  petItems: PetTrainingItem[];
  onSelected: (info: TrainingInfo) => void;
}) => {
  const [matchedPetItems, setMatchedPetItems] = useState<PetTrainingItem[]>([]);

  const onClick = useCallback((info: TrainingInfo) => {
    onSelected(info);
  }, []);

  const onFilterChange = useCallback(
    (event: SyntheticEvent, selectedGenres: TrainingGenre[]) => {
      if (!selectedGenres.length) {
        setMatchedPetItems(petItems);
        return;
      }
      const filteredItems = petItems.filter((petItem) =>
        selectedGenres.every((genre) => petItem.info.genres.includes(genre))
      );
      setMatchedPetItems(filteredItems);
    },
    [petItems, matchedPetItems]
  );

  useEffect(() => {
    setMatchedPetItems(petItems);
  }, [petItems]);

  return (
    <>
      <CertifyDescription>認定する試験を選択してください。</CertifyDescription>
      <Section>
        <Autocomplete
          multiple
          options={trainingGenres}
          onChange={onFilterChange}
          renderInput={(params) => (
            <TextField label="ジャンルで絞り込む" {...params} size="small" />
          )}
        />
        {matchedPetItems.length !== petItems.length && (
          <Caption>
            {petItems.length} 件中 {matchedPetItems.length} 件がヒット
          </Caption>
        )}
      </Section>
      {matchedPetItems.map(({ info, certificate }, index) => (
        <Section key={index}>
          <TrainingInfoCard
            info={info}
            resultType={certificate?.resultType ?? null}
            actionLabel="認定内容を確認"
            onActionClick={onClick}
          />
        </Section>
      ))}
    </>
  );
};

const ConfirmView = ({
  onBackClick,
  onNextClick,
  info,
  pet,
}: {
  onBackClick: () => void;
  onNextClick: () => void;
  info: TrainingInfo;
  pet: Pet;
}) => {
  return (
    <>
      <CertifyDescription>認定内容をご確認ください。</CertifyDescription>
      <TrainingInfoDetail info={info} />
      <Section>
        <Card>
          <CardContent>
            <Body center>
              <SubTitle>【認定料金】</SubTitle>
              <Spacing>
                キャンペーン中につき
                <CautionBody>
                  <Title>０円</Title>
                </CautionBody>
              </Spacing>
              <Caption>
                ※キャンペーン終了後は合格の場合、 認定料金が発生します。
              </Caption>
            </Body>
          </CardContent>
        </Card>
      </Section>
      <Section>
        <PetCard pet={pet} owner={null} />
        <FlexColumn center>
          <Spacing m={2}>
            <Spacing>こちらでお間違えないですか？</Spacing>
            <Section>
              <TransitButton onClick={onNextClick}>はい！</TransitButton>
            </Section>
            <Section>
              <SecondActionButton onClick={onBackClick}>
                戻る
              </SecondActionButton>
            </Section>
          </Spacing>
        </FlexColumn>
      </Section>
    </>
  );
};

import {
  RESULT_TYPE_ICON,
  RESULT_TYPE_LABEL,
  ResultType,
} from '../../types/Certificate';
import { FormRadioOption, FormRadioRow } from '../shared/forms/FormRadio';
import { FormSelectOption, FormSelectRow } from '../shared/forms/FormSelect';
import FormTextArea from '../shared/forms/FormTextArea';
import validations from '../shared/forms/validations';

export type ResultFormFieldValues = {
  resultType: ResultType;
  situationType: SituationType;
  comment: string;
};

const RESULT_TYPE_OPTIONS: Array<FormSelectOption<ResultType>> = [
  {
    value: 'pass',
    label: RESULT_TYPE_ICON.pass + ' ' + RESULT_TYPE_LABEL.pass,
  },
  {
    value: 'fail',
    label: RESULT_TYPE_ICON.fail + ' ' + RESULT_TYPE_LABEL.fail,
  },
];

export type SituationType = 'normal' | 'event';

const SITUATION_TYPE_OPTIONS: Array<FormRadioOption<SituationType>> = [
  { value: 'normal', label: 'しつけ教室等' },
  { value: 'event', label: 'Wan!Passイベント会場' },
];

const ResultFormField = () => {
  return (
    <>
      <FormSelectRow
        name="resultType"
        label="認定結果"
        options={RESULT_TYPE_OPTIONS}
        validations={{ ...validations.required }}
        fullWidth
      />

      <FormRadioRow
        name="situationType"
        label="認定場所"
        options={SITUATION_TYPE_OPTIONS}
        radioSize="small"
      />

      <FormTextArea
        name="comment"
        label="飼い主様へのコメント"
        placeholder="良かった点、ダメだった点を必ず入力して下さい"
        validations={{ ...validations.required }}
        helperText="このコメントは飼い主様のアプリに表示されます。"
        rows={8}
        fullWidth
        InputLabelProps={{ shrink: true }}
      />
    </>
  );
};

export default ResultFormField;

import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useMemo, FC } from 'react';

import AnyFragment from '../AnyFragment';
import { Row } from '../Boxes';
import { BodySub, Caption } from '../Typographies';
import {
  FormField,
  FormFieldExtendProps,
  useFormContainerCtx,
} from './FormContainer';
import { wrapByFormFieldRow } from './FormFieldRow';
import SimpleViewMode from './SimpleViewMode';

export type FormRadioOption<T = string | number> = {
  value: T;
  label: string;
};
type Props = FormFieldExtendProps & {
  options: readonly FormRadioOption[];
  align?: 'row' | 'column';
  radioSize?: 'medium' | 'small';
};

/**
 *
 * @params: name, label are required.
 */
const FormRadio: FC<Props> = ({
  name,
  label,
  options,
  validations,
  radioSize = 'medium',
  align = 'row',
}) => {
  const { register, getState } = useFormContainerCtx();

  const { value, error } = getState<FormRadioOption['value']>(name);
  const defaultValue = useMemo(() => value, []);
  const currentLabel =
    options.find((option) => option.value === value)?.label ?? undefined;
  const viewMode = (
    <SimpleViewMode label={label}>{currentLabel}</SimpleViewMode>
  );

  const RadioLabel = radioSize === 'small' ? BodySub : AnyFragment;

  const inputMode = (
    <Box>
      <FormControl error={!!error}>
        {label && (
          <Row>
            <Caption>{label}</Caption>
          </Row>
        )}
        <Row>
          <RadioGroup row={align === 'row'} defaultValue={defaultValue}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio size={radioSize} />}
                label={<RadioLabel>{option.label}</RadioLabel>}
                {...register(name, validations)}
              />
            ))}
          </RadioGroup>
        </Row>
        {error && (
          <Row>
            <FormHelperText>{error}</FormHelperText>
          </Row>
        )}
      </FormControl>
    </Box>
  );

  return <FormField viewMode={viewMode} inputMode={inputMode} />;
};

export default FormRadio;

export const FormRadioRow: FC<Props> = wrapByFormFieldRow(FormRadio);

import logger from '../config/logger';
import Trainer from '../types/Trainer';

export const responseToTrainer = (res: any): Trainer => {
  if (!res || !res.id || !res.fullname) {
    logger.error(res);
    throw new Error('Can not parse Trainer');
  }
  return res as Trainer;
};

import { Box } from '@mui/material';
import { FC, useEffect, useRef } from 'react';

import { Row } from '../../shared/Boxes';

type Props = {
  stream: MediaStream;
  isRecording?: boolean;
  maxHeight?: string | number;
};

const RecordingFrame: FC<Props> = ({ stream, isRecording, maxHeight }) => {
  const ref = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (!ref.current) return;
    ref.current.srcObject = stream;
  }, [stream]);

  const borderProps = isRecording
    ? {
        borderStyle: 'solid',
        border: 2,
        borderColor: 'error.main',
        backgroundColor: 'black',
      }
    : {};

  return (
    <Row fullWidth>
      <Box sx={{ ...borderProps }}>
        <video
          autoPlay
          muted
          playsInline
          ref={ref}
          style={{
            width: '100%',
            maxHeight,
            verticalAlign: 'bottom',
          }}
        ></video>
      </Box>
    </Row>
  );
};

export default RecordingFrame;

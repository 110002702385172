import { AuthApiDefinition } from './ApiCaller';

type LogData = Array<string | number | undefined | null>;

export type INSERT_LOG_REQUEST = {
  event: string;
  data: LogData;
};

const insertLog: AuthApiDefinition<INSERT_LOG_REQUEST> =
  ({ event, data }) =>
  async ({ backend }) => {
    await backend.post(`/logs`, {
      event,
      data,
    });
  };

const logApi = {
  insertLog,
};

export default logApi;

import axios, {
  Axios,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';

import logger from '../config/logger';

type ApiResult<R> = AxiosResponse<R, any>;

export default class ApiServer {
  private readonly urlBase: string;
  private readonly headers: AxiosRequestHeaders;

  constructor({
    urlBase,
    headers,
  }: {
    urlBase: string;
    headers?: AxiosRequestHeaders;
  }) {
    if (urlBase.endsWith('/')) throw Error('Argument error');
    this.urlBase = urlBase;
    this.headers = headers ?? {};
  }

  get = async <R>(path: string, data?: any): Promise<ApiResult<R>> =>
    await this.requestWith<R>('get', path, data);

  post = async <R>(path: string, data?: any): Promise<ApiResult<R>> =>
    await this.requestWith<R>('post', path, data);

  delete = async <R>(path: string, data?: any): Promise<ApiResult<R>> =>
    await this.requestWith<R>('delete', path, data);

  put = async <R>(path: string, data?: any): Promise<ApiResult<R>> =>
    await this.requestWith<R>('put', path, data);

  patch = async <R>(path: string, data?: any): Promise<ApiResult<R>> =>
    await this.requestWith<R>('patch', path, data);

  private readonly requestWith = async <R>(
    method: Method,
    path: string,
    data?: any
  ): Promise<ApiResult<R>> => {
    const url = this.urlBase + path;
    logger.log('ApiServer.request: ', method, url, data);
    return await this.request<R>({
      method,
      url,
      data,
    });
  };

  private readonly request: Axios['request'] = async (config) => {
    return await axios.request({
      ...config,
      headers: {
        ...(this.headers ?? {}),
        ...(config.headers ?? {}),
      },
    });
  };
}

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { RECORD_ICON } from '../../../config/icons';
import logger from '../../../config/logger';
import { useSoundContext } from '../../../contexts/SoundCtx';
import { useEffectAsync } from '../../../hooks/utilHooks';
import RecorderApi, {
  cleanUpSingleRecorder,
  IRecorder,
  IRecorderApi,
} from '../../../services/RecorderApi';
import { SectionRow } from '../../shared/Boxes';
import { ActionButton, SecondActionButton } from '../../shared/Buttons';
import LoadingOverlay from '../../shared/LoadingOverlay';
import { Caption, CautionBody } from '../../shared/Typographies';
import VideoPreviewer from '../../shared/VideoPreviewer';
import RecordingFrame from './RecordingFrame';

export const VIDEO_RECORDER_CONFIRM_OK_LABEL = 'これでＯＫ';

type Props = {
  onCompleted: (data: Blob) => void;
  frameMaxHeight?: string | undefined;
  confirmFooter?: JSX.Element;
  _mockRecorderApi?: IRecorderApi; // mock 用
};

const VideoRecorder: FC<Props> = ({
  onCompleted,
  frameMaxHeight = '50vh',
  confirmFooter,
  _mockRecorderApi,
}) => {
  const {
    state,
    onStartClick,
    onStopClick,
    onConfirmRetryClick,
    onConfirmOkClick,
  } = useService({
    _mockRecorderApi,
    onCompleted,
  });

  if (state.type === 'initial') return <LoadingOverlay label="撮影準備中" />;
  if (state.type === 'dataWaiting')
    return <LoadingOverlay label="動画生成中" />;
  if (state.type === 'ready' || state.type === 'recording')
    return (
      <RecordingView
        {...{ state, onStartClick, onStopClick, frameMaxHeight }}
      />
    );

  return (
    <ConfirmView
      data={state.data}
      onRetryClick={onConfirmRetryClick}
      onOkClick={onConfirmOkClick}
      footer={confirmFooter}
    />
  );
};

export default VideoRecorder;

const RecordingView = ({
  state,
  onStartClick,
  onStopClick,
  frameMaxHeight,
}: Pick<Props, 'frameMaxHeight'> &
  Pick<Service, 'state' | 'onStartClick' | 'onStopClick'>) => {
  if (state.type !== 'ready' && state.type !== 'recording') throw new Error();
  const recorder = state.recorder;

  const footer = (
    <>
      {state.canStart && (
        <ActionButton onClick={onStartClick}>撮影開始</ActionButton>
      )}
      {state.canStop && (
        <>
          <CautionBody>
            <Icon sx={{ pt: 0.5 }}>{RECORD_ICON}</Icon>撮影中 &nbsp;
            <ActionButton onClick={onStopClick} color="error">
              停止
            </ActionButton>
          </CautionBody>
        </>
      )}
    </>
  );

  return (
    <>
      <SectionRow center>
        <Box>
          <RecordingFrame
            stream={recorder.stream}
            maxHeight={frameMaxHeight}
            isRecording={state.type === 'recording'}
          />
        </Box>
        <SectionRow>{footer}</SectionRow>
      </SectionRow>
    </>
  );
};

const ConfirmView: FC<{
  data: Blob;
  footer?: JSX.Element;
  onRetryClick: () => void;
  onOkClick: () => void;
}> = ({ data, footer, onRetryClick, onOkClick }) => {
  return (
    <Dialog open scroll="body">
      <DialogTitle>撮影された動画をご確認ください</DialogTitle>
      <DialogContent>
        <VideoPreviewer src={data} frameMaxHeight="50vh" />
        <Caption>
          ※上記の動画に問題がなければ「{VIDEO_RECORDER_CONFIRM_OK_LABEL}
          」を押してください。キャンセルして撮影し直す場合は「撮り直す」を押してください。
        </Caption>
        {footer}
      </DialogContent>
      <DialogActions>
        <SecondActionButton onClick={onRetryClick}>撮り直す</SecondActionButton>
        <ActionButton onClick={onOkClick}>
          {VIDEO_RECORDER_CONFIRM_OK_LABEL}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
};

type StateBase = {
  recorder?: IRecorder;
  canStart?: boolean;
  canStop?: boolean;
};

type State = StateBase &
  (
    | {
        type: 'initial';
      }
    | {
        type: 'ready';
        recorder: IRecorder;
        canStart: true;
      }
    | {
        type: 'recording';
        recorder: IRecorder;
        canStop: true;
      }
    | {
        type: 'dataWaiting';
      }
    | {
        type: 'confirm';
        data: Blob;
      }
  );

const useService = ({
  _mockRecorderApi,
  onCompleted,
}: {
  _mockRecorderApi?: IRecorderApi;
  onCompleted: Props['onCompleted'];
}) => {
  const recorderApi = useMemo(() => _mockRecorderApi ?? new RecorderApi(), []);
  const [state, setState] = useState<State>({ type: 'initial' });

  // initial => ready
  useEffectAsync(async () => {
    if (state.type !== 'initial') return;
    const isOk = await recorderApi.check();
    if (!isOk) {
      window.alert(
        '権限がなく撮影ができない状態です。ひとつ前の画面に戻って再度お試しください。'
      ); // ここに来る前に VideoRecorderAuthorityGuard で確認済みのはずです。レアケースなので alert で済ませます。
      return;
    }
    const recorder = await recorderApi.createRecorder();
    setState({ type: 'ready', recorder, canStart: true });
  }, [state]);

  const stopSound = useSoundContext().forceStop;

  // ready => recording
  const onStartClick = useCallback(async () => {
    if (state.type !== 'ready') return;
    state.recorder.clean();
    const recorder = await recorderApi.createRecorder();
    alertBeforeRecording();
    // 撮影開始の前までにスマホブラウザがバックグラウンドになっているとデータが取れないので、
    // 撮影開始と同時にレコーダーをリセットします。
    recorder.start();
    setState({ type: 'recording', recorder, canStop: true });
    stopSound();
  }, [state, recorderApi]);

  // recording => dataWaiting => confirm
  const onStopClick = useCallback(() => {
    if (state.type !== 'recording') return;
    state.recorder.addListener((recorder) => {
      const data = recorder.getData();
      state.recorder.clean();
      setState({ type: 'confirm', data });
    });
    state.recorder.stop();
    setState({ type: 'dataWaiting' });
    stopSound();
  }, [state]);

  const onConfirmRetryClick = useCallback(() => {
    if (state.type !== 'confirm') return;
    setState({ type: 'initial' });
  }, [state]);
  const onConfirmOkClick = useCallback(() => {
    if (state.type !== 'confirm') return;
    onCompleted(state.data);
  }, [state]);

  useEffect(() => {
    return () => {
      logger.log('VideoRecorder unmount');
      cleanUpSingleRecorder();
    };
  }, []);

  return {
    state,
    onStartClick,
    onStopClick,
    onConfirmRetryClick,
    onConfirmOkClick,
  };
};
type Service = ReturnType<typeof useService>;

const alertBeforeRecording = () => {
  const msg =
    '撮影中は以下にご注意ください。\n\n' +
    '・電源ボタンを押してスマートフォンの画面をオフにしないでください。\n' +
    '・ホームボタン等を押してブラウザをバックグラウンドにしないでください。\n\n' +
    '上記を行うと正常に動画が記録されません。\n\n' +
    '「OK」を押すと撮影開始します。';
  window.alert(msg);
};

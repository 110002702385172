import { FC } from 'react';

import ImageBox from '../shared/ImageBox';

const VideoAuthorityHelpImageItem: FC<{ src: string; noDialog?: boolean }> = ({
  src,
  noDialog,
}) => (
  <ImageBox
    sx={{ ml: 2 }}
    src={src}
    canOpenDialog={!noDialog}
    maxWidth={300}
    maxHeight={200}
    imgStyle={{
      border: 'solid 1px gray',
      borderRadius: '4px',
    }}
  />
);

export default VideoAuthorityHelpImageItem;

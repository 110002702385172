import { FC, useCallback } from 'react';

import VideoRecorderWithSoundPanel from '../../../components/domain/VideoRecorderWithSoundPanel';
import { OnMovieCompletedProps } from '../../../contexts/CertifyCtx';

type Props = OnMovieCompletedProps & {
  onCancel: () => void;
};

const MovieStepRecord: FC<Props> = ({ onCompleted, onCancel }) => {
  const onRecordCompleted = useCallback(
    (data: Blob) => {
      onCompleted(data);
    },
    [onCompleted]
  );
  return (
    <VideoRecorderWithSoundPanel
      onCancel={onCancel}
      onCompleted={onRecordCompleted}
    />
  );
};

export default MovieStepRecord;

import { FC, PropsWithChildren } from 'react';

import { Section } from './Boxes';
import { Caption } from './Typographies';

type Props = PropsWithChildren & { caption?: string; last?: boolean };

const Field: FC<Props> = ({ caption, children, last }) => {
  return (
    <Section last={last}>
      {caption && (
        <div>
          <Caption>{caption}</Caption>
        </div>
      )}
      <div>{children}</div>
    </Section>
  );
};
export default Field;

import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useCallback,
} from 'react';
import {
  DeepPartial,
  FieldValues,
  RegisterOptions,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormReturn,
  UseFormWatch,
} from 'react-hook-form';

export type FormCtxMode = 'view' | 'input';

type CtxType<TValues extends FieldValues = FieldValues> = {
  mode: FormCtxMode;
  setMode: (mode: FormCtxMode) => void;
  register: UseFormReturn<TValues>['register'];
  getState: <ExpectValueType = any>(
    key: keyof TValues
  ) => {
    value: ExpectValueType | undefined;
    error?: string;
  };
  handleSubmit: (
    onValid: SubmitHandler<TValues>,
    onInvalid?: SubmitErrorHandler<TValues>
  ) => (...eventArgs: any) => void;
  setValues: (values: Partial<TValues>) => void;
  watch: UseFormWatch<TValues>;
};

export type FormContainerCtxType<TValues extends FieldValues = FieldValues> =
  CtxType<TValues>;

const Ctx = createContext<CtxType>({
  mode: 'view',
  setMode: () => {},
  register: () => ({} as any),
  getState: () => ({ value: '' as any }),
  handleSubmit: () => () => {},
  setValues: () => {},
  watch: () => '' as any,
});

export const useFormContainer = <TValues extends FieldValues = FieldValues>({
  defaultMode = 'view',
  defaultValues = undefined,
}: {
  defaultMode?: FormCtxMode;
  defaultValues?: DeepPartial<TValues>;
} = {}): CtxType<TValues> => {
  const [mode, setMode] = useState<FormCtxMode>(defaultMode);
  const {
    register,
    formState,
    getValues,
    handleSubmit: handleSubmitOrigin,
    setValue,
    watch,
  } = useForm<TValues>({
    defaultValues,
  });
  const [revision, setRevision] = useState(1);

  const setValues = useCallback<CtxType<TValues>['setValues']>((values) => {
    Object.keys(values).forEach((name) => {
      const value = values[name];
      if (value === undefined) return;
      setValue(name as any, value);
    });
    setRevision(revision + 1);
  }, []);

  const getState = useCallback<CtxType<TValues>['getState']>(
    (key: keyof TValues) => {
      return {
        value: getValues()[key],
        error: (formState.errors[key]?.message as string) || undefined,
      };
    },
    [getValues, formState]
  );

  const handleSubmit = useCallback<CtxType<TValues>['handleSubmit']>(
    (...handlers) =>
      (...eventArgs: any) => {
        void handleSubmitOrigin(...handlers)(...eventArgs);
        setRevision(revision + 1);
      },
    [handleSubmitOrigin, revision]
  );

  const value = useMemo<CtxType<TValues>>(() => {
    return {
      mode,
      setMode,
      register,
      getState,
      handleSubmit,
      setValues,
      watch,
    };
  }, [mode, setMode, register, getState, handleSubmit, setValues, watch]);

  return value;
};
export const useFormContainerCtx = () => useContext(Ctx);

type Props<TValues extends FieldValues = FieldValues> = PropsWithChildren<{
  value: CtxType<TValues>;
}>;
const FormContainer = <TValues extends FieldValues = FieldValues>({
  value,
  children,
}: Props<TValues>) => {
  return (
    <Ctx.Provider value={value as CtxType<FieldValues>}>
      {children}
    </Ctx.Provider>
  );
};

export default FormContainer;

export type FormFieldProps = {
  viewMode: ReactNode; // mode = 'view' のときの表示
  inputMode: ReactNode; // mode = 'input' のときの要素
};

export const FormField: FC<FormFieldProps> = ({ viewMode, inputMode }) => {
  const { mode } = useFormContainerCtx();
  if (mode === 'view') return <>{viewMode}</>;
  return <>{inputMode}</>;
};

export type FormFieldValidations = RegisterOptions;

export type FormFieldExtendProps<P = unknown> = P & {
  name: string;
  label: string;
  validations?: FormFieldValidations;
};

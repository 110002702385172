import { Icon } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { CancelButton } from './Buttons';

const DEFAULT_LABEL = '戻る';

type Props = PropsWithChildren & {
  label?: string;
  noLabel?: boolean;
  onClick: () => void;
};
const BackButton: FC<Props> = ({
  label: _label,
  noLabel,
  children,
  onClick,
}) => {
  const label = noLabel ? null : _label ?? DEFAULT_LABEL;
  return (
    <CancelButton onClick={onClick} sx={{ pl: 0, ml: -0.5 }}>
      <Icon>arrow_back</Icon>
      {label}
      {children}
    </CancelButton>
  );
};

export default BackButton;

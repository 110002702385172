import { VenuesInfo } from '../types/VenuesInfo';
import { AuthApiDefinition } from './ApiCaller';

const getVenues: AuthApiDefinition<void, VenuesInfo> =
  () =>
  async ({ backend }) => {
    const { data } = await backend.get<VenuesInfo>(`/infos/venues`);
    return {
      ...data,
    };
  };

const infosApi = {
  getVenues,
};

export default infosApi;

import { AppBar, Toolbar, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { LOGO_IMAGE } from '../config/assets';
import { useIsAuthenticated, useLogoutCallback } from '../hooks/authHooks';

const Header = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/">
            <img src={LOGO_IMAGE} alt="Logo" height={32} />
          </Link>
        </Typography>
        {isAuthenticated && <AuthContent />}
      </Toolbar>
    </AppBar>
  );
};
export default Header;

const AuthContent = () => {
  const onLogoutClick = useLogoutCallback();

  return (
    <Button color="inherit" onClick={onLogoutClick}>
      ログアウト
    </Button>
  );
};

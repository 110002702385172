import { Box } from '@mui/material';
import { StandardCSSProperties } from '@mui/system';
import { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  m?: StandardCSSProperties['margin'];
};

/**
 * Block Spacing
 */
const Spacing: FC<Props> = ({ children, m = 1 }) => {
  return <Box sx={{ m }}>{children}</Box>;
};

export default Spacing;

/**
 * Inline Spacing
 */
export const Space: FC<Props> = ({ children, m = 1, ...sxProps }) => {
  return (
    <Box display="inline" sx={{ ml: m, mr: m, ...sxProps }}>
      {children}
    </Box>
  );
};

import { Link, LinkProps } from '@mui/material';
import { FC } from 'react';

type Props = LinkProps & {
  targetBlank?: boolean; // target="_blank"
  label?: string;
};

const UrlLink: FC<Props> = ({
  href,
  targetBlank,
  label,
  children,
  ...rest
}) => {
  // TODO: set style in Theme
  const target = targetBlank ? '_blank' : undefined;
  return (
    <Link
      href={href}
      target={target}
      style={{
        color: 'gray',
        textDecorationColor: 'lightgray',
        cursor: 'pointer',
      }}
      {...rest}
    >
      {label ?? children ?? href}
    </Link>
  );
};

export default UrlLink;

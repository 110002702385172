import { ComponentProps, FC } from 'react';

import AnyFragment from '../shared/AnyFragment';
import { Section } from '../shared/Boxes';
import { NoiseSoundPanel } from './NoiseSoundPanel';
import SoundVolumeCheckGuard from './SoundVolumeCheckGuard';
import VideoRecorder from './recorder/VideoRecorder';
import VideoRecorderAuthorityGuard from './recorder/VideoRecorderAuthorityGuard';

type Props = {
  onCancel: () => void;
  checkSoundVolume?: boolean;
} & ComponentProps<typeof VideoRecorder>;

const VideoRecorderWithSoundPanel: FC<Props> = ({
  onCancel,
  checkSoundVolume = false,
  ...props
}) => {
  const VolumeCheckWrapper = checkSoundVolume
    ? SoundVolumeCheckGuard
    : AnyFragment;

  return (
    <VolumeCheckWrapper>
      <VideoRecorderAuthorityGuard {...{ onCancel }}>
        <Section>
          <VideoRecorder {...props} />
        </Section>
        <Section last>
          <NoiseSoundPanel />
        </Section>
      </VideoRecorderAuthorityGuard>
    </VolumeCheckWrapper>
  );
};

export default VideoRecorderWithSoundPanel;

import { FC, useCallback } from 'react';

import DataResource, {
  dataResourceToUrl,
  downloadBlobResource,
  getFileExtensionFromUri,
} from '../../types/shared/DataResource';
import { toDateTimeSigunatureFormat } from '../../utils/dateFormat';
import UrlLink from './UrlLink';

const DEFAULT_EXTENSION = 'webm';

type Props = {
  src: DataResource;
};

const VideoDownloadLink: FC<Props> = ({ src }) => {
  const onClick = useCallback(() => {
    const basename = `しつけ認定_${toDateTimeSigunatureFormat(new Date())}`;
    const ext =
      getFileExtensionFromUri(dataResourceToUrl(src)) ?? DEFAULT_EXTENSION;
    downloadBlobResource(src, { basename, ext });
  }, [src]);

  return <UrlLink fontSize="small" label="ダウンロード" onClick={onClick} />;
};

export default VideoDownloadLink;

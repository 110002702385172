import { ResultType } from '../types/Certificate';
import PetOwnerPair from '../types/PetOwnerPair';
import PetTrainingItem from '../types/PetTrainingItem';
import { TrainingType } from '../types/TrainingType';
import { AuthApiDefinition } from './ApiCaller';

type IdentifyProps = {
  oneTimeCode: string;
};

const getPet: AuthApiDefinition<IdentifyProps, PetOwnerPair> =
  ({ oneTimeCode }) =>
  async ({ backend }) => {
    const { data } = await backend.get<PetOwnerPair>(`/pets/${oneTimeCode}`);
    return data;
  };

const getItems: AuthApiDefinition<IdentifyProps, PetTrainingItem[]> =
  ({ oneTimeCode }) =>
  async ({ backend }) => {
    const { data } = await backend.get<PetTrainingItem[]>(
      `/pets/${oneTimeCode}/certificates`
    );
    return data;
  };

export type CreateCertificateRequest = IdentifyProps & {
  type: TrainingType;
  resultType: ResultType;
  comment: string;
  movie: string | Blob | File; // movieId or Blob(recorded) or File(attached)
};

const createCertificate: AuthApiDefinition<
  CreateCertificateRequest,
  PetTrainingItem
> =
  ({ oneTimeCode, movie, ...rest }) =>
  async ({ backend, asset }) => {
    const movieId: string = await (async () => {
      if (typeof movie === 'string') return movie;
      const assetMovie = await asset.uploadMovie(movie);
      return assetMovie.id;
    })();

    const { type, resultType, comment } = rest;
    const { data } = await backend.post<PetTrainingItem>(
      `/pets/${oneTimeCode}/certificates`,
      {
        oneTimeCode,
        movieId,
        type,
        resultType,
        comment,
      }
    );
    return data;
  };

const petApi = {
  getPet,
  getItems,
  createCertificate,
};

export default petApi;

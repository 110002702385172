import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';

import logger from '../../config/logger';
import routes from '../../config/routes';
import { useLoginCallback } from '../../hooks/authHooks';
import {
  FireAuthState,
  useMountFirebaseAuthUi,
} from '../../services/firebaseAuth';
import { SectionRow } from '../shared/Boxes';
import { ActionButton } from '../shared/Buttons';
import LoadingOverlay from '../shared/LoadingOverlay';
import { Caption, CautionBody } from '../shared/Typographies';

const CONTAINER_ID = 'firebase-sign-in-container';

const FirebaseSignInScreen: FC<{ redirectPath?: string }> = ({
  redirectPath,
}) => {
  const [state, setState] = useState<FireAuthState>({ type: 'notSignIn' });
  const { isMountReady } = useMountFirebaseAuthUi(`#${CONTAINER_ID}`, setState);

  const doLogin = useLoginCallback(redirectPath);

  useEffect(() => {
    if (state.type !== 'verified') return;
    const { firebaseIdToken } = state;
    logger.log('FirebaseSignInScreen success', firebaseIdToken);
    doLogin({ firebaseIdToken });
  }, [state]);

  return (
    <>
      {!isMountReady && <LoadingOverlay />}
      <div id={CONTAINER_ID} />
      {state.type === 'emailSending' && <LoadingOverlay />}
      {state.type === 'emailSent' && <EmailSentDialog />}
    </>
  );
};

export default FirebaseSignInScreen;

const EmailSentDialog = () => {
  const onOkClick = useCallback(() => {
    window.location.href = routes.loginVerify;
  }, []);

  return (
    <Dialog open scroll="body">
      <DialogTitle>ご本人確認が必要です</DialogTitle>
      <DialogContent>
        <SectionRow>
          ご本人確認のため、お使いのメールアドレス宛にメールを送信しました。
          <br />
          メールに記載のURLを開くと、本人確認は完了です。
        </SectionRow>
        <SectionRow>
          <Caption>
            ※お使いのメールプロバイダーによってはメールの到着に５分程度の遅延が発生する場合がございます。
            <br />
            ※メールは noreply@wanpass.me
            から送信されます。メールが届かない場合、迷惑メールフォルダをご確認いただくか、
            noreply@wanpass.me を受信許可リストにご設定ください。
          </Caption>
        </SectionRow>
        <SectionRow last>
          <CautionBody>
            メールに記載のURLを開いてから、以下のボタンを押してください。
          </CautionBody>
        </SectionRow>
      </DialogContent>
      <DialogActions>
        <ActionButton onClick={onOkClick}>メールを確認しました</ActionButton>
      </DialogActions>
    </Dialog>
  );
};

import { Backdrop, Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

import Spacing from './Spacing';
import { Body, Pre } from './Typographies';

type Props = {
  label?: string;
};
const LoadingOverlay: FC<Props> = ({ label = undefined }) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <Box textAlign="center">
        {label && (
          <div>
            <Body>
              <Pre>{label}</Pre>
            </Body>
            <Spacing />
          </div>
        )}
        <div>
          <CircularProgress color="inherit" />
        </div>
      </Box>
    </Backdrop>
  );
};

export default LoadingOverlay;

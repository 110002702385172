import { Paper } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useState } from 'react';

import { LinkButton } from './Buttons';

type Props = PropsWithChildren & {
  label: string;
};
const OpenTrigger: FC<Props> = ({ label, children }) => {
  const [visible, setVisible] = useState(false);
  const onClick = useCallback(() => setVisible(!visible), [visible]);
  const icon = visible ? 'keyboard_arrow_up_icon' : 'keyboard_arrow_down_icon';
  return (
    <>
      <LinkButton onClick={onClick} icon={icon}>
        {label}
      </LinkButton>
      {visible && (
        <Paper variant="outlined" sx={{ p: 1, mb: 2 }}>
          {children}
        </Paper>
      )}
    </>
  );
};

export default OpenTrigger;

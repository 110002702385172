import { Divider, Avatar } from '@mui/material';

import { PageLinkButtonList } from '../components/domain/PageLinkButton';
import { PrivacyLink, TermsLink } from '../components/domain/TermsAndPrivacy';
import { Section, FlexCenter } from '../components/shared/Boxes';
import Separator from '../components/shared/Separator';
import StickyNote from '../components/shared/StickyNote';
import {
  Label,
  LabelTitle,
  Body,
  Caption,
} from '../components/shared/Typographies';
import { APP_TRAINER_URL } from '../config/constants';
import { useAuthUser } from '../hooks/authHooks';

const HomePage = () => {
  const trainer = useAuthUser().trainer;
  const capability = useAuthUser().capability;
  return (
    <>
      <Section>
        {trainer && (
          <FlexCenter column>
            <Avatar
              alt={trainer.fullname}
              src={trainer.image.url}
              sx={{ width: 125, height: 125 }}
            />
            <Label>{trainer.fullname}</Label>
          </FlexCenter>
        )}
      </Section>
      <Section>
        <StickyNote>
          <Body center>
            <LabelTitle>これまでの認定試験の回数</LabelTitle>
            <Label>{capability.numCertified} 回</Label>
          </Body>
        </StickyNote>
      </Section>
      <Section>
        <Label>お知らせ</Label>
        <Notices />
      </Section>

      <Section>
        <PageLinkButtonList
          pageKeys={[
            'app/home',
            'history',
            'profile',
            'info/venues',
            'certify',
            'help/record',
            'logout',
          ]}
        />
      </Section>

      <Separator />

      <Section>
        <Caption>
          <FlexCenter columnGap={1}>
            <TermsLink />
            <PrivacyLink />
          </FlexCenter>
        </Caption>
      </Section>
    </>
  );
};

export default HomePage;

const Notices = () => {
  return (
    <>
      <div>
        ここはWebブラウザ版の認定士ページです。しつけ認定を行う場合は、このページではなく、
        <a href={APP_TRAINER_URL} target="_blank" rel="noreferrer">
          Wan!Passアプリ
        </a>
        からお願いします。
      </div>
      <Divider />
    </>
  );
};

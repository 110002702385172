import { FC, PropsWithChildren, useEffect, useMemo } from 'react';

import PetIcon from '../../components/domain/PetIcon';
import ResultFormField, {
  ResultFormFieldValues,
} from '../../components/domain/ResultFormFields';
import { Flex, Section } from '../../components/shared/Boxes';
import { ActionButton } from '../../components/shared/Buttons';
import { Space } from '../../components/shared/Spacing';
import { Label } from '../../components/shared/Typographies';
import VideoPreviewer from '../../components/shared/VideoPreviewer';
import FormContainer, {
  useFormContainer,
} from '../../components/shared/forms/FormContainer';
import { useCertifyCtx } from '../../contexts/CertifyCtx';
import {
  RESULT_TYPE_ICON,
  RESULT_TYPE_LABEL,
  ResultType,
} from '../../types/Certificate';
import Pet from '../../types/Pet';
import TrainingInfo from '../../types/TrainingInfo';
import { toCalendarFormat } from '../../utils/dateFormat';
import { CertifyDescription, CertifyTitle } from './CertifyLayout';

const ResultStep = () => {
  const { form, onSaveClick, info, pet, movie } = useService();
  const certifiedDate = useMemo(() => toCalendarFormat(new Date()), []);
  if (!info || !pet || !movie) throw new Error('Invalid state');

  return (
    <>
      <CertifyTitle>認定結果の入力</CertifyTitle>
      <CertifyDescription>
        認定内容をご確認のうえ、認定結果を入力してください。
      </CertifyDescription>
      <Label>認定内容の確認</Label>
      <FixedField label="認定日">{certifiedDate}</FixedField>
      <FixedField label="認定試験名">{info.title}</FixedField>
      <FixedField label="ワンちゃん">
        <Flex>
          <PetIcon pet={pet} size={20} />
          <Space>{pet.chanName}</Space>
        </Flex>
      </FixedField>
      <FixedField label="認定動画">
        {movie && <VideoPreviewer src={movie} />}
      </FixedField>

      <FormContainer value={form}>
        <Label>認定結果の入力</Label>
        <Section>
          <ResultFormField />
        </Section>
        <Section>
          <ActionButton onClick={onSaveClick} fullWidth>
            認定結果を登録する
          </ActionButton>
        </Section>
      </FormContainer>
    </>
  );
};

export default ResultStep;

const useService = () => {
  const { startUpload, values } = useCertifyCtx(['result', 'uploading']);

  const form = useFormContainer<ResultFormFieldValues>({
    defaultMode: 'input',
    defaultValues: {
      resultType: undefined,
      situationType: 'normal',
      comment: undefined,
    },
  });

  const { handleSubmit, setValues, getState, watch } = form;

  const onSaveClick = handleSubmit(async (data) => {
    const { resultType, comment } = data;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!_confirmSend(values.pet!, values.info!, resultType)) return;

    await startUpload({ resultType, trainerComment: comment });
  });

  const situationType = watch('situationType');
  useEffect(() => {
    const curComment = getState<string>('comment').value ?? '';
    if (situationType !== 'event') {
      const backComment = curComment.replace(EVENT_TEMPLATE_COMMENT, '');
      setValues({ comment: backComment });
      return;
    }
    if (curComment && curComment !== EVENT_TEMPLATE_COMMENT) {
      const isOverrideOk = window.confirm(
        '飼い主様へのコメントをイベント用の定型文で上書きします。よろしいでしょうか？'
      );
      if (!isOverrideOk) return;
    }
    setValues({ comment: EVENT_TEMPLATE_COMMENT });
  }, [situationType]);

  return {
    form,
    onSaveClick,
    ...values,
  };
};

const FixedField: FC<PropsWithChildren & { label: string }> = ({
  children,
  label,
}) => <Section caption={label}>{children}</Section>;

const EVENT_TEMPLATE_COMMENT = `
この度はWan!Pass認定イベントにご参加いただきありがとうございます。
Wan!Pass（ワンパス）にはその他にも多数の認定項目をご用意しております。
今回の体験をきっかけに、「ワンパス認定士」がいるトレーニング教室へお問い合わせのうえ、他の認定もぜひトライしてみてください！
`.trim();

function _confirmSend(pet: Pet, info: TrainingInfo, resultType: ResultType) {
  const resultLabel = `${RESULT_TYPE_ICON[resultType]}【${RESULT_TYPE_LABEL[resultType]}】${RESULT_TYPE_ICON[resultType]}`;
  const isOk = window.confirm(
    `最終確認してください。\n\n- ${pet.chanName}\n- ${pet.bloodLabel}\n- ${info.title}\n\n${resultLabel}\nとして認定します。\nよろしいですか？`
  );
  return isOk;
}

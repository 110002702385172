import { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import logger from '../config/logger';
import routes from '../config/routes';
import { useAuthCtx } from '../contexts/AuthCtx';
import { useAuthUser } from '../hooks/authHooks';
import AppLayout from './AppLayout';

type Props = PropsWithChildren<{}>;

const AuthLayout: FC<Props> = ({ children }) => {
  return (
    <AuthGuard>
      <UserGuard>
        <AppLayout>{children}</AppLayout>
      </UserGuard>
    </AuthGuard>
  );
};

export default AuthLayout;

const AuthGuard: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuthCtx();
  if (!isAuthenticated) {
    return <NavigateToLogin />;
  }

  return <>{children}</>;
};

const NavigateToLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const from = `${location.pathname}${location.search}`;
    const params = new URLSearchParams({ from });
    const to = '/login?' + params.toString();
    logger.log('Navigate to login.', { from, to });
    navigate(to);
  }, []);
  return <>ログイン画面に移動します...</>;
};

const UserGuard: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const user = useAuthUser();
  if (user.isRegisterNeeded && pathname !== routes.register) {
    return <Navigate to={routes.register} />;
  }

  return <>{children}</>;
};

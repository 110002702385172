import audio1 from '../assets/sounds/noise/01.mp3';
import audio2 from '../assets/sounds/noise/02.mp3';
import audio3 from '../assets/sounds/noise/03.mp3';
import audio4 from '../assets/sounds/noise/04.mp3';
import audio5 from '../assets/sounds/noise/05.mp3';
import audio6 from '../assets/sounds/noise/06.mp3';
import audio7 from '../assets/sounds/noise/07.mp3';
import audio8 from '../assets/sounds/noise/08.mp3';
import audio9 from '../assets/sounds/noise/09.mp3';
import audio10 from '../assets/sounds/noise/10.mp3';
import SoundSource from '../types/shared/SoundSource';

export type NoiseSound = SoundSource & {
  priority: 'normal' | 'high';
};

export const NOISE_SOUNDS: NoiseSound[] = [
  { src: audio1, label: '犬の鳴き声' },
  { src: audio2, label: 'インターホン' },
  { src: audio3, label: '洗濯機・掃除機・電話' },
  { src: audio4, label: '物の落下音' },
  { src: audio5, label: '救急車・消防車' },
  { src: audio6, label: '車・工事現場' },
  { src: audio7, label: '電車・踏切' },
  { src: audio8, label: '子供の声' },
  { src: audio9, label: '花火' },
  { src: audio10, label: '雷・風' },
].map((audio: SoundSource, index): NoiseSound => {
  const numbering = index + 1;
  return {
    src: audio.src,
    label: audio.label,
    priority: numbering <= 2 ? 'high' : 'normal',
  };
});

import { List, ListItem } from '@mui/material';
import { useMemo } from 'react';

import {
  getPageConfigItems,
  PageConfigItem,
  PageKey,
} from '../../config/pageConfig';
import { useLogoutCallback } from '../../hooks/authHooks';
import {
  ActionButton,
  SecondActionButton,
  TransitButton,
} from '../shared/Buttons';

const PageLinkButton = ({
  item: { key, label, path, icon, secondary, black },
}: {
  item: PageConfigItem;
}) => {
  const onLogoutClick = useLogoutCallback();
  const ButtonCmp = secondary
    ? SecondActionButton
    : black
    ? ActionButton
    : TransitButton;

  if (key === 'logout')
    return (
      <ButtonCmp fullWidth onClick={onLogoutClick} icon={icon}>
        {label}
      </ButtonCmp>
    );

  return (
    <ButtonCmp fullWidth href={path} icon={icon}>
      {label}
    </ButtonCmp>
  );
};

export default PageLinkButton;

export const PageLinkButtonList = ({ pageKeys }: { pageKeys: PageKey[] }) => {
  const items = useMemo(() => getPageConfigItems(pageKeys), [...pageKeys]);

  return (
    <List>
      {items.map((item) => (
        <ListItem key={item.label}>
          <PageLinkButton {...{ item }} />
        </ListItem>
      ))}
    </List>
  );
};

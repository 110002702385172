import { FC } from 'react';

import { NOISE_SOUNDS } from '../../config/noiseSounds';
import HorizontalPanel, {
  HorizontalPanelItem,
} from '../shared/HorizontalPanel';
import SoundButton from '../shared/SoundButton';

export const NoiseSoundPanel: FC = () => {
  return (
    <HorizontalPanel scrollAreaWidth={840}>
      {NOISE_SOUNDS.map(({ src, label, priority }) => (
        <HorizontalPanelItem key={src}>
          <SoundButton {...{ src, label, priority }} />
        </HorizontalPanelItem>
      ))}
    </HorizontalPanel>
  );
};

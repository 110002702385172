import { AxiosError } from 'axios';

/**
 * Convert backend's ApiMsgError object to display the message from API.
 * If this is not ApiMsgError object, returns undefined.
 * This function is copied from wanpass-backend's ApiErrorMsg.ts
 */
export const getDisplayMessageFromAxiosError = (
  error: any
): string | undefined => {
  const axiosError = convertToAxiosError(error);
  if (!axiosError) return undefined;

  const data = axiosError.response?.data ?? undefined;
  if (!data || typeof data !== 'object') return undefined;
  const displayMessage = (data as any)?.displayMessage;
  if (typeof displayMessage !== 'string') return undefined;
  return displayMessage;
};

export const convertToAxiosError = (error: any): AxiosError | undefined => {
  if (!error || typeof error !== 'object') return undefined;
  if (error.name !== 'AxiosError') return undefined;
  return error as AxiosError;
};

import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = PropsWithChildren & {
  center?: boolean;
};

/**
 * Wrap children by inline-block.
 * `inline-block` adjusts the size of block to the inner children.
 * This component is for adjusting the content and centerize at the same time.
 *
 * If center=true, centerize the inline-block.
 */
const InlineBlockWrapper: FC<Props> = ({ children, center }) => {
  return (
    <Outer center={center}>
      <Inner>{children}</Inner>
    </Outer>
  );
};

export default InlineBlockWrapper;

const Outer = styled.div<{ center?: boolean }>`
  display: block;
  ${(p) =>
    p.center &&
    `
      text-align: center;
  `}
`;

const Inner = styled.div`
  display: inline-block;
`;

/**
 * @最終更新日 2023/03/01 GCS直アップロード対応
 *
 * ## 概要
 * フロントエンド向けの asset-server SDK です。
 * フロントエンドは、 backend から受け取ったtokenを使って、 asset-server に画像や動画を POST します。
 * アップロード主の情報はすべて token に含まれます。
 * バックエンドに隠されるべき secret を必要としません。
 *
 * ## インストール方法
 *
 * 1. 利用ライブラリのインストール
 *  ```
 *  $ yarn add axios jsonwebtoken
 * ```
 *
 * 2. sdk のコピー
 *  以下２ファイルを src/lib などにコピーして使います。
 *    - AssetServiceCommon.sdk.v1.ts
 *    - AssetServiceFrontendClient.sdk.v1.ts
 *  元のソースは https://github.com/petsallright/wanpass-asset-service にあります。
 */

import axios from 'axios';

import {
  AssetImage,
  AssetImageScale,
  AssetMovie,
  MOCK_ASSET_API_URL_BASE,
} from './AssetServiceCommon.sdk.v1';
export * from './AssetServiceCommon.sdk.v1';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
const NODE_ENV = process.env.NODE_ENV as 'test' | 'development' | 'production';

export type AssetApiAccessInfo = {
  token: string;
  // e.g. "https://dev.asset-api.wanpass.me" or "http://localhost:8005" or ""
  apiUrlBase: string;
};

type IClients = {
  imageClient: IAssetImageClient;
  movieClient: IAssetMovieClient;
};

export const createAssetServiceFrontendClients = ({
  token,
  apiUrlBase,
}: AssetApiAccessInfo): IClients => {
  if (
    NODE_ENV === 'test' ||
    (NODE_ENV === 'development' && apiUrlBase === MOCK_ASSET_API_URL_BASE)
  )
    return {
      imageClient: new _MockImageClient(),
      movieClient: new _MockMovieClient(),
    };

  if (apiUrlBase === MOCK_ASSET_API_URL_BASE)
    throw new Error('AccessInfo is not valid.');

  const imageClient = new ImageClient({ token, apiUrlBase });
  const movieClient = new MovieClient({ token, apiUrlBase });
  return { imageClient, movieClient };
};

export type AssetId = string;

type SignedUrlInfo = { id: string; url: string };

class ClientBase {
  readonly token: string;
  readonly apiUrlBase: string;
  constructor({ token, apiUrlBase }: AssetApiAccessInfo) {
    this.token = token;
    this.apiUrlBase = apiUrlBase;
  }

  async _getSignedUrlInfo({
    assetType,
    contentType,
  }: {
    assetType: 'movie' | 'image';
    contentType: string;
  }): Promise<SignedUrlInfo> {
    const apiUrl = `${this.apiUrlBase}${'/v1/signed/issue'}`;
    const data = {
      assetType,
      contentType,
    };
    const res = await axios.post<any>(apiUrl, data, {
      headers: {
        Accept: 'application/json',
        Authorization: this.token,
      },
    });
    const { id, url } = res.data;
    return { id, url };
  }

  async _uploadFileViaSignedUrl({
    file,
    assetType,
  }: {
    file: File;
    assetType: 'movie' | 'image';
  }): Promise<SignedUrlInfo> {
    const contentType =
      file.type ||
      {
        movie: 'video/webm',
        image: 'image/jpeg',
      }[assetType];

    // Cloud Storage の署名付きURLの取得
    const signedUrlInfo = await this._getSignedUrlInfo({
      assetType,
      contentType,
    });

    // Cloud Storage にアップロード
    await axios.put(signedUrlInfo.url, file, {
      headers: {
        'Content-Type': contentType,
      },
    });

    return signedUrlInfo;
  }
}

// ------------------------------------
// Image
// ------------------------------------

type ImageUploadOptions = {
  /**
   * 画像のスケールです。
   */
  scale?: AssetImageScale;
};

export type IAssetImageClient = {
  uploadImage: (file: File, options: ImageUploadOptions) => Promise<AssetImage>;
};

class ImageClient extends ClientBase implements IAssetImageClient {
  uploadImage = async (
    file: File,
    options: ImageUploadOptions
  ): Promise<AssetImage> => {
    // GCP Storage にアップロード
    const signedUrlInfo = await this._uploadFileViaSignedUrl({
      file,
      assetType: 'image',
    });
    // asset-server に記録
    const apiUrl = `${this.apiUrlBase}${'/v1/signed/images'}`;
    const data = {
      options,
      signedUrlInfo,
    };
    const res = await axios.post<any>(apiUrl, data, {
      headers: {
        Accept: 'application/json',
        Authorization: this.token,
      },
    });
    const { id, url, thumbnailUrl, originalFileUrl } = res.data;
    return { id, url, thumbnailUrl, originalFileUrl };
  };
}

class _MockImageClient implements IAssetImageClient {
  static _mockIdIncrement = 1;

  uploadImage = async (): Promise<AssetImage> => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const id = `v1-jpg-${_MockImageClient._mockIdIncrement++}-${new Date().toISOString()}`;
    const url = `https://dummyimage.com/999x999/666/ffffff.jpg&text=${encodeURI(
      id
    )}`;
    const thumbnailUrl = url.replace('999x999', '100x100');
    return {
      id,
      url,
      thumbnailUrl,
      originalFileUrl: url,
    };
  };
}

// ------------------------------------
// Movie
// ------------------------------------

export type MovieUploadOptions = {};

export type IAssetMovieClient = {
  uploadMovie: (file: File, options: MovieUploadOptions) => Promise<AssetMovie>;
};

export const MOVIE_CLIENT_FILE_NAME_KEY = 'fileName';

class MovieClient extends ClientBase implements IAssetMovieClient {
  uploadMovie = async (
    file: File,
    options: MovieUploadOptions
  ): Promise<AssetMovie> => {
    // GCP Storage にアップロード
    const signedUrlInfo = await this._uploadFileViaSignedUrl({
      file,
      assetType: 'movie',
    });
    // asset-server に記録
    const data = {
      options,
      signedUrlInfo,
      originalMimeType: file.type,
    };
    const apiUrl = `${this.apiUrlBase}${'/v1/signed/movies'}`;
    const res = await axios.post<any>(apiUrl, data, {
      headers: {
        Accept: 'application/json',
        Authorization: this.token,
      },
    });
    const { id, url } = res.data;
    return { id, url };
  };
}

class _MockMovieClient implements IAssetMovieClient {
  uploadMovie = async (): Promise<AssetMovie> => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const url =
      'https://storage.googleapis.com/asset-storage-local/mock/dummy.webm';
    return {
      id: 'mockDummyId',
      url,
    };
  };
}

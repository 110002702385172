import { FC, useMemo } from 'react';

import DataResource, {
  dataResourceToUrl,
} from '../../types/shared/DataResource';
import { Row } from './Boxes';
import VideoDownloadLink from './VideoDownloadLink';

type Props = {
  src: DataResource;
  frameMaxHeight?: string | number;
};
const VideoPreviewer: FC<Props> = ({ src: _src, frameMaxHeight }) => {
  const { videoSrc, sources } = useMemo(() => toVideoAttrs(_src), [_src]);

  return (
    <Row fullWidth>
      <Row>
        <video
          playsInline
          controls
          style={{ width: '100%', maxHeight: frameMaxHeight, display: 'block' }}
          src={videoSrc}
        >
          {sources?.map(({ src, type }) => (
            <source key={src} src={src} type={type} />
          ))}
          <p>動画を再生するにはvideoタグをサポートしたブラウザが必要です。</p>
        </video>
      </Row>
      <Row alignRight>
        <VideoDownloadLink src={_src} />
      </Row>
    </Row>
  );
};

export default VideoPreviewer;

type VideoSourceAttrs = {
  src: string;
  type?: string;
};
type VideoAttrs = {
  videoSrc?: string;
  sources?: VideoSourceAttrs[];
};
const toVideoAttrs = (source: DataResource): VideoAttrs => {
  if (typeof source === 'string')
    return {
      sources: [
        {
          src: source,
        },
      ],
    };

  return {
    videoSrc: dataResourceToUrl(source),
  };
};

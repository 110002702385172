import { DependencyList, useCallback, useEffect, useMemo } from 'react';

import { ApiCaller, createApiCaller } from '../api/ApiCaller';
import { StartLoadingOptions, useAppSetterCtx } from '../contexts/AppCtx';
import { useAuthCtx } from '../contexts/AuthCtx';

export const useApiCaller = (): ApiCaller => {
  const { user } = useAuthCtx();

  const apiCaller = useMemo(() => {
    const apiCaller = createApiCaller({
      bearer: user?.bearer ?? null,
      assetAccessInfo: user?.assetAccessInfo ?? null,
    });
    return apiCaller;
  }, [user?.bearer]);
  return apiCaller;
};

export const useCallbackApiLoading = <T>(
  callback: (args: T, props: { apiCaller: ApiCaller }) => Promise<void>,
  deps: DependencyList,
  options?: StartLoadingOptions
) => {
  const apiCaller = useApiCaller();
  const startLoading = useAppSetterCtx().startLoading;

  return useCallback(async (args: T) => {
    await startLoading(async () => {
      // eslint-disable-next-line n/no-callback-literal
      await callback(args, { apiCaller });
    }, options);
  }, deps);
};

export const useEffectApiLoading = (
  callback: (props: { apiCaller: ApiCaller }) => Promise<void>,
  deps: DependencyList,
  options: StartLoadingOptions = { successMsg: null }
) => {
  const wrappedCallback = useCallbackApiLoading(
    async (args, props) => await callback(props),
    deps,
    options
  );
  useEffect(() => {
    void wrappedCallback(null);
  }, [wrappedCallback]);
};

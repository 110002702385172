import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

import {
  FormField,
  FormFieldExtendProps,
  useFormContainerCtx,
} from './FormContainer';
import { wrapByFormFieldRow } from './FormFieldRow';
import SimpleViewMode from './SimpleViewMode';

type Props = FormFieldExtendProps & TextFieldProps;

/**
 *
 * @params: name, label are required.
 */
const FormTextArea: FC<Props> = ({
  name,
  label,
  validations,
  helperText,
  ...rest
}) => {
  const { register, getState } = useFormContainerCtx();

  const { value, error } = getState<string>(name);
  const viewMode = <SimpleViewMode label={label}>{value}</SimpleViewMode>;

  const inputMode = (
    <TextField
      label={label}
      {...register(name, validations)}
      error={!!error}
      helperText={error ?? helperText}
      multiline
      rows={3}
      {...rest}
    />
  );

  return <FormField viewMode={viewMode} inputMode={inputMode} />;
};

export const FormTextAreaRow: FC<Props> = wrapByFormFieldRow(FormTextArea);

export default FormTextArea;

import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { Title } from '../components/shared/Typographies';
import { LayoutCtx, useLayoutCtxProviderValue } from '../contexts/LayoutCtx';
import Header from './Header';
import MobileFirstWidth from './MobileFirstWidth';

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const layoutValue = useLayoutCtxProviderValue();
  const { title, description } = layoutValue;

  return (
    <LayoutCtx.Provider value={layoutValue}>
      <Box height="100vh">
        <Header />
        <MobileFirstWidth>
          <Box height="100vh" sx={{ bgcolor: 'white' }}>
            <Box component="main" sx={{ m: 1, mt: 2 }}>
              {(title ?? description) && (
                <Box sx={{ mb: 2 }}>
                  {title ? (
                    <Box>
                      <Title>{title}</Title>
                    </Box>
                  ) : undefined}
                  {description ? <Box>{description}</Box> : undefined}
                </Box>
              )}
              <Box>{children}</Box>
            </Box>
          </Box>
        </MobileFirstWidth>
      </Box>
    </LayoutCtx.Provider>
  );
};

export default AppLayout;

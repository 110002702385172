import { useEffect } from 'react';

import { useLayoutCtx } from '../contexts/LayoutCtx';

type LayoutSetupProps = {
  title?: string;
  description?: string;
};

export const useSetupLayout = ({ title, description }: LayoutSetupProps) => {
  const { setTitle, setDescription } = useLayoutCtx();
  useEffect(() => {
    setTitle(title);
    setDescription(description);
  }, [title, description]);
};

import AssetImage from './AssetImage';

type Pet = {
  id: string;
  name: string;
  chanName: string;
  sex: 'male' | 'female';
  birthday: string;
  bloodLabel: string; // e.g. "柴犬（血統種）", "ミックス（柴犬・チワワ）", "雑種"
  image?: AssetImage;
};

export default Pet;

export const PET_FRIENDLY_ID_VALIDATIONS = {
  pattern: {
    value: /^[a-zA-Z0-9]{6}$/,
    message: '6文字の英数字を入力してください。',
  },
};

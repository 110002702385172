import { Alert, Snackbar } from '@mui/material';
import { useCallback, useState } from 'react';

type ToastType = 'success' | 'error';
export type ToastProps = {
  type: ToastType;
  message: string;
  autoHideDuration?: number | null;
};

const Toast = ({ type, message, autoHideDuration }: ToastProps) => {
  const [open, setOpen] = useState(true);
  const handleClose = useCallback(() => setOpen(false), []);
  // See also: src/layouts/AppTheme.tsx
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;

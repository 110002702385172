import { Paper } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import Colors from './Colors';
import { BodySub, LabelTitle, Pre } from './Typographies';

type Props = PropsWithChildren & { title?: string; pre?: boolean };
const Manuscript: FC<Props> = ({ children, title, pre }) => {
  const Wrapper = pre ? Pre : BodySub;

  return (
    <>
      {title && <LabelTitle>{title}</LabelTitle>}
      <Paper
        variant="outlined"
        sx={{ padding: 1, backgroundColor: Colors.bgSubtle }}
      >
        <BodySub>
          <Wrapper>{children}</Wrapper>
        </BodySub>
      </Paper>
    </>
  );
};

export default Manuscript;
